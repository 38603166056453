import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import theme from 'theme';
import { useLocationTablesQuery } from 'graphql/graphql-types';
import { useCurrentLocation } from 'hooks/location';
import ScreenDialog from './AddLocationTableDialog';
import LocationTableItem from './LocationTableItem';

const useStyles = makeStyles(() => ({
  root: {
    padding: theme.spacing(6),
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  type: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableCards: {
    // display: 'flex',
    // flexWrap: 'wrap',
  },
  paperButton: {
    backgroundColor: 'transparent',
    borderStyle: 'dashed',
    display: 'flex',
    height: '100%',
    minHeight: '317px',
  },
  paperButtonContext: {
    marginBottom: theme.spacing(3),
    maxWidth: '12rem',
    textAlign: 'center',
  },
  buttonDefault: { backgroundColor: '#ffffff', textTransform: 'inherit' },
  tableCard: {
    textAlign: 'center',
    marginRight: '8px',
    borderRadius: '25px',
    backgroundColor: '#ffffff',
    padding: '20px',
    '& .code': {
      marginBottom: '16px',
    },
    '& .table-btn + .table-btn': {
      marginLeft: '8px',
    },
  },
  tableCardTitle: {},
  tableButtons: {
    display: 'flex',
  },
}));

const LocationTables = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const classes = useStyles();
  const { currentLocation } = useCurrentLocation();
  const { data } = useLocationTablesQuery({
    variables: {
      locationId: currentLocation?.id,
    },
    skip: !currentLocation?.id,
  });

  const locationTables = data?.allLocationTables?.nodes ?? [];
  return (
    <Box
      display="flex"
      flexGrow={1}
      flexDirection="column"
      className={classes.root}
    >
      <Grid container spacing={3} className={classes.tableCards}>
        <Grid item xs={12} sm={6} md={4}>
          <Paper variant="outlined" className={classes.paperButton}>
            <Box
              display="flex"
              flexGrow={1}
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Typography
                variant="body2"
                className={classes.paperButtonContext}
              >
                Create table number, code, and QR code.
              </Typography>
              <Button
                variant="outlined"
                color="default"
                onClick={() => setDialogOpen(!dialogOpen)}
                startIcon={<AddIcon />}
                className={classes.buttonDefault}
              >
                Add Table
              </Button>
            </Box>
          </Paper>
        </Grid>
        {locationTables &&
          locationTables.map((locationTable) => (
            <Grid key={locationTable.id} item xs={12} sm={6} md={4}>
              <LocationTableItem
                key={locationTable.id}
                locationTable={locationTable}
                locationId={currentLocation?.id}
              />
            </Grid>
          ))}
      </Grid>
      {currentLocation?.id && (
        <ScreenDialog open={dialogOpen} handleClose={setDialogOpen} />
      )}
    </Box>
  );
};
export default LocationTables;
