import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useCloverAuthorizeUrl } from 'hooks/clover';
import AddIcon from '@material-ui/icons/Add';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import theme from 'theme';
import brandLogo from '../../assets/brandLogo.svg';
import cloverLogo from '../../assets/cloverLogo.svg';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100vh',
    flexGrow: 1,
    padding: theme.spacing(6),
  },
  merchantEmptyState: {
    maxWidth: '30rem',
    textAlign: 'center',
  },
  imageSync: {
    marginBottom: theme.spacing(4),
  },
  iconSync: {
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  bodyHeader: { marginBottom: theme.spacing(1) },
  bodyText: {
    color: theme.palette.grey[700],
    marginBottom: theme.spacing(3),
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
  },
  type: {
    display: 'flex',
    flexDirection: 'row',
  },
  merchants: {
    padding: '20px 0',
  },
  buttonDefault: { backgroundColor: '#ffffff', textTransform: 'inherit' },
}));

const ConnectNewMerchant = () => {
  const classes = useStyles();
  const getUrl = useCloverAuthorizeUrl();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Box
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        className={classes.merchantEmptyState}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          className={classes.imageSync}
        >
          <Box>
            <img src={brandLogo} alt="Roux" width="100" />
          </Box>
          <Box>
            <SyncAltIcon className={classes.iconSync} />
          </Box>
          <Box>
            <img src={cloverLogo} alt="Roux" width="100" />
          </Box>
        </Box>
        <Typography variant="h5" className={classes.bodyHeader}>
          Create a Location
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          Connect with Clover to sync a merchant location, including all items,
          item modifiers, order types, and payment key.
        </Typography>
        <Button
          variant="outlined"
          color="default"
          href={getUrl()}
          className={classes.buttonDefault}
          startIcon={<AddIcon />}
        >
          Connect Merchant
        </Button>
      </Box>
    </Box>
  );
};

export default ConnectNewMerchant;
