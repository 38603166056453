import { useEffect } from 'react';
import {
  GetCurrentLocationIdDocument,
  GetCurrentLocationIdQuery,
  LocationUpdatedDocument,
  useGetCurrentLocationIdQuery,
  useGetCurrentLocationQuery,
} from 'graphql/graphql-types';
import { RootCache } from 'config/ApolloClient';
import config from 'config';

export const useCurrentLocation = () => {
  const { data: local } = useGetCurrentLocationIdQuery();
  const currentLocationId = local?.currentLocationId;

  const { data, loading, subscribeToMore } = useGetCurrentLocationQuery({
    fetchPolicy: 'network-only',
    // @ts-ignore
    variables: { id: currentLocationId ? Number(currentLocationId) : null },
  });
  const currentLocation = data?.currentLocation;

  useEffect(() => {
    if (!loading && currentLocationId !== currentLocation?.id) {
      localStorage.setItem('currentLocationId', String(currentLocation?.id));
      RootCache.writeQuery<GetCurrentLocationIdQuery>({
        query: GetCurrentLocationIdDocument,
        data: {
          currentLocationId: currentLocation?.id ?? null,
        },
      });
    }
    if (currentLocation?.id && subscribeToMore) {
      subscribeToMore({
        document: LocationUpdatedDocument,
        variables: { locationId: currentLocation?.id },
        onError: (error) => {
          if (config.isDev) {
            console.log(error);
          }
          // TODO log error
        },
      });
    }
  }, [currentLocationId, currentLocation]);

  return { currentLocation, loading };
};

export const setCurrentLocation = (id: number) => {
  localStorage.setItem('currentLocationId', String(id));
  RootCache.writeQuery<GetCurrentLocationIdQuery>({
    query: GetCurrentLocationIdDocument,
    data: {
      currentLocationId: id,
    },
  });
};
