import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  split,
  fromPromise,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import config from 'config/config';
import {
  GetAuthDocument,
  GetAuthQuery,
  GetCurrentLocationIdQuery,
  GetCurrentLocationIdDocument,
  LoginByRefreshTokenDocument,
} from 'graphql/graphql-types';
import WebSocketLink from './WebSocketLink';

let isRefreshing = false;
let pendingRequests: any = [];

export const RootCache = new InMemoryCache();

const resolvePendingRequests = () => {
  pendingRequests.map((callback: any) => callback());
  pendingRequests = [];
};

const setAuth = (accessToken = '', refreshToken = '', isInitialized = true) =>
  RootCache.writeQuery<GetAuthQuery>({
    query: GetAuthDocument,
    data: {
      auth: {
        isInitialized,
        accessToken,
        refreshToken,
      },
    },
  });

const getRefreshToken = async () => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const authResult = RootCache.readQuery<GetAuthQuery>({
    query: GetAuthDocument,
  });
  const refreshToken = authResult?.auth?.refreshToken ?? null;
  if (!refreshToken) {
    throw new Error('Refresh token not found');
  }
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return client.mutate({
    mutation: LoginByRefreshTokenDocument,
    variables: {
      refreshToken,
    },
  });
};

const resetToken = onError(({ graphQLErrors, operation, forward }) => {
  const isJwtExpiredError = graphQLErrors?.some(
    ({ message }) => message === 'jwt expired',
  );

  if (isJwtExpiredError) {
    let forward$;

    if (!isRefreshing) {
      isRefreshing = true;
      forward$ = fromPromise(
        getRefreshToken()
          .then(({ data }) => {
            const {
              loginByRefreshToken: {
                credentials: { accessToken, refreshToken },
              },
            } = data;
            setAuth(accessToken, refreshToken);
            resolvePendingRequests();
            return accessToken;
          })
          .catch((error) => {
            pendingRequests = [];
            setAuth('', '');
            return undefined;
          })
          .finally(() => {
            isRefreshing = false;
          }),
      ).filter((value) => Boolean(value));
    } else {
      forward$ = fromPromise(
        new Promise((resolve) => {
          pendingRequests.push(() => resolve(true));
        }),
      );
    }

    return forward$.flatMap(() => forward(operation));
  }
  return undefined;
});

const httpLink = new HttpLink({
  uri: config.graphqlServerUrl,
});

const wsLink = new WebSocketLink({
  url: config.graphqlServerUrlWS,
  lazy: true,
  connectionParams: () => {
    const { auth } = RootCache.readQuery<any>({
      query: GetAuthDocument,
    });
    const accessToken = auth?.accessToken ?? null;
    return accessToken
      ? {
          authorization: `Bearer ${accessToken}`,
        }
      : {};
  },
});

const setInitialStore = (isInitialized = false) => {
  setAuth('', '', isInitialized);

  const currentLocationId = localStorage.getItem('currentLocationId');
  RootCache.writeQuery<GetCurrentLocationIdQuery>({
    query: GetCurrentLocationIdDocument,
    data: {
      currentLocationId: currentLocationId ? Number(currentLocationId) : null,
    },
  });
};

const withToken = setContext(async (_, { headers, cache }) => {
  const { auth } = cache.readQuery({
    query: GetAuthDocument,
  });
  const accessToken = auth?.accessToken ?? null;
  return accessToken && !isRefreshing
    ? {
        headers: {
          ...headers,
          authorization: `Bearer ${accessToken}`,
        },
      }
    : {};
});

const authLink = resetToken.concat(withToken).concat(httpLink);

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink,
);

setInitialStore();

const client = new ApolloClient({
  cache: RootCache,
  link,
});

client.onResetStore(async () => {
  setInitialStore(true);
});

export default client;
