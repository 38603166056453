import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBarMaterial from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Link from '@material-ui/core/Link';
import AccountCircle from '@material-ui/icons/AccountCircle';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import CheckIcon from '@material-ui/icons/Check';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useLogout } from 'hooks/auth';
import { useCurrentLocation, setCurrentLocation } from 'hooks/location';
import { useAvailableLocationsQuery } from 'graphql/graphql-types';
import { useCurrentMerchant } from 'hooks/merchant';
import { useCloverAuthorizeUrl } from 'hooks/clover';
import brandLogo from '../../assets/brandLogo.svg';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    backgroundColor: '#091f35',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  buttonProfile: {
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#fff',
    },
    borderRadius: '4px',
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1.5),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
  },
  buttonRestarauntLocation: {
    backgroundColor: '#ffffff',
    borderRadius: '50%',
    height: '40px',
    width: '40px',
  },
  iconLocation: { color: theme.palette.grey[600], fontSize: '20px' },
  buttonBrand: { marginRight: theme.spacing(1) },
  iconEnd: {
    justifyContent: 'flex-end',
  },
  divider: {
    padding: '8px 0',
  },
  account: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '16px',
  },
  email: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}));

interface Props {
  handleDrawerOpen(): void;
  isOpenDrawer: boolean;
}

const AppBar = ({ handleDrawerOpen, isOpenDrawer }: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpenMenu = Boolean(anchorEl);
  const [anchorLocation, setAnchorLocation] = React.useState<null | Element>(
    null,
  );
  const isOpenMenuLocation = Boolean(anchorLocation);

  const getCloverUrl = useCloverAuthorizeUrl();
  const logout = useLogout();
  const { currentLocation } = useCurrentLocation();
  const { data } = useAvailableLocationsQuery();
  const locations = data?.allLocations?.nodes ?? [];

  const { currentUser } = useCurrentMerchant();
  const userEmail = currentUser?.userEmailsByUserId?.nodes[0]?.email ?? '';

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuLocation = (event: React.MouseEvent<Element>) => {
    if (event) {
      setAnchorLocation(event.currentTarget as Element);
    }
  };

  const handleCloseLocation = () => {
    setAnchorLocation(null);
  };

  const handleSelectLocation = (id: number) => {
    setCurrentLocation(id);
    setAnchorLocation(null);
  };

  const handleSignOut = () => {
    logout();
    handleClose();
  };
  // @ts-ignore
  return (
    <AppBarMaterial
      position="absolute"
      className={clsx(classes.appBar, isOpenDrawer && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          className={clsx(
            classes.menuButton,
            isOpenDrawer && classes.menuButtonHidden,
          )}
        >
          <MenuIcon />
        </IconButton>
        <Box display="flex" flexGrow={1}>
          <Typography>{currentLocation?.name}</Typography>
          {/* <img src={brandLogo} alt="Roux" width="70" /> */}
        </Box>
        {!!locations.length && (
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenuLocation}
              color="inherit"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className={classes.buttonRestarauntLocation}
              >
                <RestaurantIcon className={classes.iconLocation} />
              </Box>
            </IconButton>
            {/* <Button
              color="inherit"
              aria-controls="location-menu"
              aria-haspopup="true"
              endIcon={<KeyboardArrowDown />}
              onClick={handleMenuLocation}
            >
              {currentLocation?.name}
            </Button> */}
            <Menu
              id="location-menu"
              anchorEl={anchorLocation}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={isOpenMenuLocation}
              onClose={handleCloseLocation}
            >
              {locations.map((location) => (
                <MenuItem
                  key={String(location?.id)}
                  selected={location?.id === currentLocation?.id}
                  onClick={() => handleSelectLocation(location?.id)}
                >
                  <ListItemText primary={location?.name} />
                  {location?.id === currentLocation?.id && (
                    <ListItemIcon className={classes.iconEnd}>
                      <CheckIcon />
                    </ListItemIcon>
                  )}
                </MenuItem>
              ))}
              <div className={classes.divider}>
                <Divider />
              </div>
              <MenuItem key="connect">
                <Link href={getCloverUrl()}>Connect New Merchant</Link>
              </MenuItem>
            </Menu>
          </div>
        )}

        <div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="default"
            className={classes.buttonProfile}
          >
            <img
              src={brandLogo}
              alt="Roux"
              width="60"
              className={classes.buttonBrand}
            />
            <AccountCircle color="inherit" />
          </IconButton>
          <Menu
            id="menu-appbar"
            getContentAnchorEl={null}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            keepMounted
            open={isOpenMenu}
            onClose={handleClose}
          >
            <div className={classes.email}>
              <Typography>{userEmail}</Typography>
            </div>
            <div className={classes.divider}>
              <Divider />
            </div>
            <MenuItem onClick={handleSignOut}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBarMaterial>
  );
};

export default AppBar;
