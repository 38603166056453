import React, { ReactNode, ElementType } from 'react';
import { Link, useLocation, LinkProps } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PinDropOutlinedIcon from '@material-ui/icons/PinDropOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

interface ListLinkProps {
  to: string;
  icon?: ElementType;
  text: string;
  exact?: boolean;
}

export const ListLink = ({
  to,
  icon: IconComponent,
  text,
  exact = false,
  ...props
}: ListLinkProps & LinkProps) => {
  const { pathname } = useLocation();
  return (
    <ListItem
      button
      component={Link}
      selected={exact ? pathname === to : pathname.includes(to)}
      to={to}
      {...props}
    >
      <ListItemIcon>{IconComponent && <IconComponent />}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
};

interface CollapsedListProps {
  text: string;
  icon: React.ElementType;
  children: ReactNode;
}

export const CollapsedList: React.FunctionComponent<CollapsedListProps> = ({
  text,
  icon: IconComponent,
  children,
}) => {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <IconComponent />
        </ListItemIcon>
        <ListItemText primary={text} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
};

export const MainListItems = () => (
  <div>
    <ListLink text="Merchant" to="/" icon={StorefrontOutlinedIcon} exact />
    <ListLink text="Tables" to="/tables" icon={ListAltIcon} exact />
  </div>
);

export const MainListItemsNoLocation = () => (
  <div>
    <ListLink text="Merchant" to="/" icon={StorefrontOutlinedIcon} exact />
  </div>
);

export const SecondaryListItems = () => <div />;

//     <ListSubheader inset>Settings</ListSubheader>
