import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: any;
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: any;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};



/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  /** Reads and enables pagination through a set of `CloverMerchant`. */
  allCloverMerchants?: Maybe<CloverMerchantsConnection>;
  /** Reads and enables pagination through a set of `Item`. */
  allItems?: Maybe<ItemsConnection>;
  /** Reads and enables pagination through a set of `LocationTable`. */
  allLocationTables?: Maybe<LocationTablesConnection>;
  /** Reads and enables pagination through a set of `Location`. */
  allLocations?: Maybe<LocationsConnection>;
  /** Reads and enables pagination through a set of `LocationsSession`. */
  allLocationsSessions?: Maybe<LocationsSessionsConnection>;
  /** Reads and enables pagination through a set of `MenuSection`. */
  allMenuSections?: Maybe<MenuSectionsConnection>;
  /** Reads and enables pagination through a set of `Menu`. */
  allMenus?: Maybe<MenusConnection>;
  /** Reads and enables pagination through a set of `Modifier`. */
  allModifiers?: Maybe<ModifiersConnection>;
  /** Reads and enables pagination through a set of `ModifiersGroup`. */
  allModifiersGroups?: Maybe<ModifiersGroupsConnection>;
  /** Reads and enables pagination through a set of `ModifiersItem`. */
  allModifiersItems?: Maybe<ModifiersItemsConnection>;
  /** Reads and enables pagination through a set of `SectionsItem`. */
  allSectionsItems?: Maybe<SectionsItemsConnection>;
  /** Reads and enables pagination through a set of `SelectedModifiersGroup`. */
  allSelectedModifiersGroups?: Maybe<SelectedModifiersGroupsConnection>;
  /** Reads and enables pagination through a set of `SelectedModifiersItem`. */
  allSelectedModifiersItems?: Maybe<SelectedModifiersItemsConnection>;
  /** Reads and enables pagination through a set of `SessionsItem`. */
  allSessionsItems?: Maybe<SessionsItemsConnection>;
  /** Reads and enables pagination through a set of `SessionsUser`. */
  allSessionsUsers?: Maybe<SessionsUsersConnection>;
  /** Reads and enables pagination through a set of `UserEmail`. */
  allUserEmails?: Maybe<UserEmailsConnection>;
  /** Reads and enables pagination through a set of `User`. */
  allUsers?: Maybe<UsersConnection>;
  auth?: Maybe<Auth>;
  /** Reads and enables pagination through a set of `MenuSection`. */
  availableMenuSections?: Maybe<MenuSectionsConnection>;
  /** Reads a single `CloverMerchant` using its globally unique `ID`. */
  cloverMerchant?: Maybe<CloverMerchant>;
  cloverMerchantById?: Maybe<CloverMerchant>;
  currentLocation?: Maybe<Location>;
  currentLocationId?: Maybe<Scalars['Int']>;
  currentOrderTotal?: Maybe<Scalars['Int']>;
  currentSession?: Maybe<SessionsUser>;
  /** The currently logged in user (or null if not logged in). */
  currentUser?: Maybe<User>;
  /** Reads a single `Item` using its globally unique `ID`. */
  item?: Maybe<Item>;
  itemById?: Maybe<Item>;
  /** Reads a single `Location` using its globally unique `ID`. */
  location?: Maybe<Location>;
  locationById?: Maybe<Location>;
  /** Reads a single `LocationTable` using its globally unique `ID`. */
  locationTable?: Maybe<LocationTable>;
  locationTableById?: Maybe<LocationTable>;
  /** Reads a single `LocationsSession` using its globally unique `ID`. */
  locationsSession?: Maybe<LocationsSession>;
  locationsSessionById?: Maybe<LocationsSession>;
  /** Reads a single `Menu` using its globally unique `ID`. */
  menu?: Maybe<Menu>;
  menuById?: Maybe<Menu>;
  /** Reads a single `MenuSection` using its globally unique `ID`. */
  menuSection?: Maybe<MenuSection>;
  menuSectionById?: Maybe<MenuSection>;
  /** Reads a single `Modifier` using its globally unique `ID`. */
  modifier?: Maybe<Modifier>;
  modifierByItemIdAndModifiersGroupId?: Maybe<Modifier>;
  /** Reads a single `ModifiersGroup` using its globally unique `ID`. */
  modifiersGroup?: Maybe<ModifiersGroup>;
  modifiersGroupById?: Maybe<ModifiersGroup>;
  /** Reads a single `ModifiersItem` using its globally unique `ID`. */
  modifiersItem?: Maybe<ModifiersItem>;
  modifiersItemById?: Maybe<ModifiersItem>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID'];
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  /** Reads a single `SectionsItem` using its globally unique `ID`. */
  sectionsItem?: Maybe<SectionsItem>;
  sectionsItemByLocationIdAndItemIdAndMenuSectionId?: Maybe<SectionsItem>;
  /** Reads a single `SelectedModifiersGroup` using its globally unique `ID`. */
  selectedModifiersGroup?: Maybe<SelectedModifiersGroup>;
  selectedModifiersGroupById?: Maybe<SelectedModifiersGroup>;
  /** Reads a single `SelectedModifiersItem` using its globally unique `ID`. */
  selectedModifiersItem?: Maybe<SelectedModifiersItem>;
  selectedModifiersItemById?: Maybe<SelectedModifiersItem>;
  /** Reads a single `SessionsItem` using its globally unique `ID`. */
  sessionsItem?: Maybe<SessionsItem>;
  sessionsItemById?: Maybe<SessionsItem>;
  /** Reads a single `SessionsUser` using its globally unique `ID`. */
  sessionsUser?: Maybe<SessionsUser>;
  sessionsUserById?: Maybe<SessionsUser>;
  sessionsUserBySessionIdAndUserId?: Maybe<SessionsUser>;
  /** Reads a single `User` using its globally unique `ID`. */
  user?: Maybe<User>;
  userById?: Maybe<User>;
  /** Reads a single `UserEmail` using its globally unique `ID`. */
  userEmail?: Maybe<UserEmail>;
  userEmailById?: Maybe<UserEmail>;
  userEmailByUserIdAndEmail?: Maybe<UserEmail>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCloverMerchantsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CloverMerchantsOrderBy>>;
  condition?: Maybe<CloverMerchantCondition>;
  filter?: Maybe<CloverMerchantFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLocationTablesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationTablesOrderBy>>;
  condition?: Maybe<LocationTableCondition>;
  filter?: Maybe<LocationTableFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLocationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLocationsSessionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsSessionsOrderBy>>;
  condition?: Maybe<LocationsSessionCondition>;
  filter?: Maybe<LocationsSessionFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllMenuSectionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MenuSectionsOrderBy>>;
  condition?: Maybe<MenuSectionCondition>;
  filter?: Maybe<MenuSectionFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllMenusArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MenusOrderBy>>;
  condition?: Maybe<MenuCondition>;
  filter?: Maybe<MenuFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllModifiersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModifiersOrderBy>>;
  condition?: Maybe<ModifierCondition>;
  filter?: Maybe<ModifierFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllModifiersGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModifiersGroupsOrderBy>>;
  condition?: Maybe<ModifiersGroupCondition>;
  filter?: Maybe<ModifiersGroupFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllModifiersItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModifiersItemsOrderBy>>;
  condition?: Maybe<ModifiersItemCondition>;
  filter?: Maybe<ModifiersItemFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSectionsItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SectionsItemsOrderBy>>;
  condition?: Maybe<SectionsItemCondition>;
  filter?: Maybe<SectionsItemFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSelectedModifiersGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersGroupsOrderBy>>;
  condition?: Maybe<SelectedModifiersGroupCondition>;
  filter?: Maybe<SelectedModifiersGroupFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSelectedModifiersItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersItemsOrderBy>>;
  condition?: Maybe<SelectedModifiersItemCondition>;
  filter?: Maybe<SelectedModifiersItemFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSessionsItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SessionsItemsOrderBy>>;
  condition?: Maybe<SessionsItemCondition>;
  filter?: Maybe<SessionsItemFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSessionsUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SessionsUsersOrderBy>>;
  condition?: Maybe<SessionsUserCondition>;
  filter?: Maybe<SessionsUserFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllUserEmailsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserEmailsOrderBy>>;
  condition?: Maybe<UserEmailCondition>;
  filter?: Maybe<UserEmailFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAuthArgs = {
  test?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAvailableMenuSectionsArgs = {
  locationId: Scalars['Int'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<MenuSectionFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCloverMerchantArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCloverMerchantByIdArgs = {
  id: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCurrentLocationArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCurrentOrderTotalArgs = {
  sessionId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryItemArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryItemByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLocationArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLocationByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLocationTableArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLocationTableByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLocationsSessionArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLocationsSessionByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMenuArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMenuByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMenuSectionArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMenuSectionByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryModifierArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryModifierByItemIdAndModifiersGroupIdArgs = {
  itemId: Scalars['Int'];
  modifiersGroupId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryModifiersGroupArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryModifiersGroupByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryModifiersItemArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryModifiersItemByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySectionsItemArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySectionsItemByLocationIdAndItemIdAndMenuSectionIdArgs = {
  locationId: Scalars['Int'];
  itemId: Scalars['Int'];
  menuSectionId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelectedModifiersGroupArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelectedModifiersGroupByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelectedModifiersItemArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelectedModifiersItemByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySessionsItemArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySessionsItemByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySessionsUserArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySessionsUserByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySessionsUserBySessionIdAndUserIdArgs = {
  sessionId: Scalars['Int'];
  userId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserEmailArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserEmailByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserEmailByUserIdAndEmailArgs = {
  userId: Scalars['UUID'];
  email: Scalars['String'];
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/** A connection to a list of `CloverMerchant` values. */
export type CloverMerchantsConnection = {
  __typename?: 'CloverMerchantsConnection';
  /** A list of `CloverMerchant` objects. */
  nodes: Array<CloverMerchant>;
  /** A list of edges which contains the `CloverMerchant` and cursor to aid in pagination. */
  edges: Array<CloverMerchantsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CloverMerchant` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type CloverMerchant = Node & {
  __typename?: 'CloverMerchant';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['String'];
  userId: Scalars['UUID'];
  locationId?: Maybe<Scalars['Int']>;
  code: Scalars['String'];
  token?: Maybe<Scalars['String']>;
  paymentKey?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  syncingItemIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  syncingModifiersIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  isSyncedMerchant: Scalars['Boolean'];
  isSyncingMerchant: Scalars['Boolean'];
  isSyncedInventoryItems: Scalars['Boolean'];
  isSyncingInventoryItems: Scalars['Boolean'];
  isSyncedModifiers: Scalars['Boolean'];
  isSyncingModifiers: Scalars['Boolean'];
  isSyncedOrderTypes: Scalars['Boolean'];
  isSyncingOrderTypes: Scalars['Boolean'];
  isSyncedPaymentKey: Scalars['Boolean'];
  isSyncingPaymentKey: Scalars['Boolean'];
  isInitialized: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `CloverMerchant`. */
  userByUserId?: Maybe<User>;
  /** Reads a single `Location` that is related to this `CloverMerchant`. */
  locationByLocationId?: Maybe<Location>;
};



/** A user who can log in to the application. */
export type User = Node & {
  __typename?: 'User';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Unique identifier for the user. */
  id: Scalars['UUID'];
  /** Public-facing username of the user. */
  username?: Maybe<Scalars['String']>;
  role: UserRoles;
  isVerified: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `UserEmail`. */
  userEmailsByUserId: UserEmailsConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByUserId: LocationsConnection;
  /** Reads and enables pagination through a set of `LocationsSession`. */
  locationsSessionsByOwnerId: LocationsSessionsConnection;
  /** Reads and enables pagination through a set of `SessionsUser`. */
  sessionsUsersByUserId: SessionsUsersConnection;
  /** Reads and enables pagination through a set of `SessionsItem`. */
  sessionsItemsByUserId: SessionsItemsConnection;
  /** Reads and enables pagination through a set of `SelectedModifiersGroup`. */
  selectedModifiersGroupsByUserId: SelectedModifiersGroupsConnection;
  /** Reads and enables pagination through a set of `SelectedModifiersItem`. */
  selectedModifiersItemsByUserId: SelectedModifiersItemsConnection;
  /** Reads and enables pagination through a set of `CloverMerchant`. */
  cloverMerchantsByUserId: CloverMerchantsConnection;
  hasPassword?: Maybe<Scalars['Boolean']>;
  isOwner?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByLocationsSessionOwnerIdAndLocationId: UserLocationsByLocationsSessionOwnerIdAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `LocationsSession`. */
  locationsSessionsBySessionsUserUserIdAndSessionId: UserLocationsSessionsBySessionsUserUserIdAndSessionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `LocationsSession`. */
  locationsSessionsBySessionsItemUserIdAndSessionId: UserLocationsSessionsBySessionsItemUserIdAndSessionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsBySessionsItemUserIdAndItemId: UserItemsBySessionsItemUserIdAndItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `SessionsItem`. */
  sessionsItemsBySelectedModifiersGroupUserIdAndSessionsItemId: UserSessionsItemsBySelectedModifiersGroupUserIdAndSessionsItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ModifiersGroup`. */
  modifiersGroupsBySelectedModifiersGroupUserIdAndModifiersGroupId: UserModifiersGroupsBySelectedModifiersGroupUserIdAndModifiersGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `SessionsItem`. */
  sessionsItemsBySelectedModifiersItemUserIdAndSessionsItemId: UserSessionsItemsBySelectedModifiersItemUserIdAndSessionsItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `SelectedModifiersGroup`. */
  selectedModifiersGroupsBySelectedModifiersItemUserIdAndSelectedModifiersGroupId: UserSelectedModifiersGroupsBySelectedModifiersItemUserIdAndSelectedModifiersGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ModifiersItem`. */
  modifiersItemsBySelectedModifiersItemUserIdAndModifiersItemId: UserModifiersItemsBySelectedModifiersItemUserIdAndModifiersItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByCloverMerchantUserIdAndLocationId: UserLocationsByCloverMerchantUserIdAndLocationIdManyToManyConnection;
};


/** A user who can log in to the application. */
export type UserUserEmailsByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserEmailsOrderBy>>;
  condition?: Maybe<UserEmailCondition>;
  filter?: Maybe<UserEmailFilter>;
};


/** A user who can log in to the application. */
export type UserLocationsByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


/** A user who can log in to the application. */
export type UserLocationsSessionsByOwnerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsSessionsOrderBy>>;
  condition?: Maybe<LocationsSessionCondition>;
  filter?: Maybe<LocationsSessionFilter>;
};


/** A user who can log in to the application. */
export type UserSessionsUsersByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SessionsUsersOrderBy>>;
  condition?: Maybe<SessionsUserCondition>;
  filter?: Maybe<SessionsUserFilter>;
};


/** A user who can log in to the application. */
export type UserSessionsItemsByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SessionsItemsOrderBy>>;
  condition?: Maybe<SessionsItemCondition>;
  filter?: Maybe<SessionsItemFilter>;
};


/** A user who can log in to the application. */
export type UserSelectedModifiersGroupsByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersGroupsOrderBy>>;
  condition?: Maybe<SelectedModifiersGroupCondition>;
  filter?: Maybe<SelectedModifiersGroupFilter>;
};


/** A user who can log in to the application. */
export type UserSelectedModifiersItemsByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersItemsOrderBy>>;
  condition?: Maybe<SelectedModifiersItemCondition>;
  filter?: Maybe<SelectedModifiersItemFilter>;
};


/** A user who can log in to the application. */
export type UserCloverMerchantsByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CloverMerchantsOrderBy>>;
  condition?: Maybe<CloverMerchantCondition>;
  filter?: Maybe<CloverMerchantFilter>;
};


/** A user who can log in to the application. */
export type UserLocationsByLocationsSessionOwnerIdAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


/** A user who can log in to the application. */
export type UserLocationsSessionsBySessionsUserUserIdAndSessionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsSessionsOrderBy>>;
  condition?: Maybe<LocationsSessionCondition>;
  filter?: Maybe<LocationsSessionFilter>;
};


/** A user who can log in to the application. */
export type UserLocationsSessionsBySessionsItemUserIdAndSessionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsSessionsOrderBy>>;
  condition?: Maybe<LocationsSessionCondition>;
  filter?: Maybe<LocationsSessionFilter>;
};


/** A user who can log in to the application. */
export type UserItemsBySessionsItemUserIdAndItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


/** A user who can log in to the application. */
export type UserSessionsItemsBySelectedModifiersGroupUserIdAndSessionsItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SessionsItemsOrderBy>>;
  condition?: Maybe<SessionsItemCondition>;
  filter?: Maybe<SessionsItemFilter>;
};


/** A user who can log in to the application. */
export type UserModifiersGroupsBySelectedModifiersGroupUserIdAndModifiersGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModifiersGroupsOrderBy>>;
  condition?: Maybe<ModifiersGroupCondition>;
  filter?: Maybe<ModifiersGroupFilter>;
};


/** A user who can log in to the application. */
export type UserSessionsItemsBySelectedModifiersItemUserIdAndSessionsItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SessionsItemsOrderBy>>;
  condition?: Maybe<SessionsItemCondition>;
  filter?: Maybe<SessionsItemFilter>;
};


/** A user who can log in to the application. */
export type UserSelectedModifiersGroupsBySelectedModifiersItemUserIdAndSelectedModifiersGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersGroupsOrderBy>>;
  condition?: Maybe<SelectedModifiersGroupCondition>;
  filter?: Maybe<SelectedModifiersGroupFilter>;
};


/** A user who can log in to the application. */
export type UserModifiersItemsBySelectedModifiersItemUserIdAndModifiersItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModifiersItemsOrderBy>>;
  condition?: Maybe<ModifiersItemCondition>;
  filter?: Maybe<ModifiersItemFilter>;
};


/** A user who can log in to the application. */
export type UserLocationsByCloverMerchantUserIdAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};

export enum UserRoles {
  Customer = 'CUSTOMER',
  Merchant = 'MERCHANT'
}

/** A connection to a list of `UserEmail` values. */
export type UserEmailsConnection = {
  __typename?: 'UserEmailsConnection';
  /** A list of `UserEmail` objects. */
  nodes: Array<UserEmail>;
  /** A list of edges which contains the `UserEmail` and cursor to aid in pagination. */
  edges: Array<UserEmailsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserEmail` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** Information about a user's email address. */
export type UserEmail = Node & {
  __typename?: 'UserEmail';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  userId: Scalars['UUID'];
  /** The users email address, in `a@b.c` format. */
  email: Scalars['String'];
  /** True if the user has is_verified their email address (by clicking the link in the email we sent them, or logging in with a social login provider), false otherwise. */
  isVerified: Scalars['Boolean'];
  isPrimary: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `UserEmail`. */
  userByUserId?: Maybe<User>;
};

/** A `UserEmail` edge in the connection. */
export type UserEmailsEdge = {
  __typename?: 'UserEmailsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UserEmail` at the end of the edge. */
  node: UserEmail;
};


/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
};

/** Methods to use when ordering `UserEmail`. */
export enum UserEmailsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  IsVerifiedAsc = 'IS_VERIFIED_ASC',
  IsVerifiedDesc = 'IS_VERIFIED_DESC',
  IsPrimaryAsc = 'IS_PRIMARY_ASC',
  IsPrimaryDesc = 'IS_PRIMARY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `UserEmail` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type UserEmailCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `email` field. */
  email?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `isVerified` field. */
  isVerified?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isPrimary` field. */
  isPrimary?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `UserEmail` object types. All fields are combined with a logical ‘and.’ */
export type UserEmailFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<UuidFilter>;
  /** Filter by the object’s `email` field. */
  email?: Maybe<StringFilter>;
  /** Filter by the object’s `isVerified` field. */
  isVerified?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isPrimary` field. */
  isPrimary?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `userByUserId` relation. */
  userByUserId?: Maybe<UserFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<UserEmailFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<UserEmailFilter>>;
  /** Negates the expression. */
  not?: Maybe<UserEmailFilter>;
};

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['UUID']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['UUID']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['UUID']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['UUID']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['UUID']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['UUID']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['UUID']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['UUID']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['UUID']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['String']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['String']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['String']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['String']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['String']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['String']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Contains the specified string (case-sensitive). */
  includes?: Maybe<Scalars['String']>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: Maybe<Scalars['String']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: Maybe<Scalars['String']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: Maybe<Scalars['String']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: Maybe<Scalars['String']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: Maybe<Scalars['String']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: Maybe<Scalars['String']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: Maybe<Scalars['String']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: Maybe<Scalars['String']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: Maybe<Scalars['String']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: Maybe<Scalars['String']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: Maybe<Scalars['String']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: Maybe<Scalars['String']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: Maybe<Scalars['String']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: Maybe<Scalars['String']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: Maybe<Scalars['String']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: Maybe<Scalars['String']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: Maybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: Maybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: Maybe<Scalars['String']>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: Maybe<Array<Scalars['String']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: Maybe<Array<Scalars['String']>>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: Maybe<Scalars['String']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: Maybe<Scalars['String']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Boolean']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Boolean']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Boolean']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Boolean']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Boolean']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Boolean']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Boolean']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Boolean']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Boolean']>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Datetime']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Datetime']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Datetime']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Datetime']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Datetime']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Datetime']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Datetime']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Datetime']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Datetime']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `User` object types. All fields are combined with a logical ‘and.’ */
export type UserFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `username` field. */
  username?: Maybe<StringFilter>;
  /** Filter by the object’s `role` field. */
  role?: Maybe<UserRolesFilter>;
  /** Filter by the object’s `isVerified` field. */
  isVerified?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `hasPassword` field. */
  hasPassword?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isOwner` field. */
  isOwner?: Maybe<BooleanFilter>;
  /** Filter by the object’s `userEmailsByUserId` relation. */
  userEmailsByUserId?: Maybe<UserToManyUserEmailFilter>;
  /** Some related `userEmailsByUserId` exist. */
  userEmailsByUserIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `locationsByUserId` relation. */
  locationsByUserId?: Maybe<UserToManyLocationFilter>;
  /** Some related `locationsByUserId` exist. */
  locationsByUserIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `locationsSessionsByOwnerId` relation. */
  locationsSessionsByOwnerId?: Maybe<UserToManyLocationsSessionFilter>;
  /** Some related `locationsSessionsByOwnerId` exist. */
  locationsSessionsByOwnerIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `sessionsUsersByUserId` relation. */
  sessionsUsersByUserId?: Maybe<UserToManySessionsUserFilter>;
  /** Some related `sessionsUsersByUserId` exist. */
  sessionsUsersByUserIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `sessionsItemsByUserId` relation. */
  sessionsItemsByUserId?: Maybe<UserToManySessionsItemFilter>;
  /** Some related `sessionsItemsByUserId` exist. */
  sessionsItemsByUserIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `selectedModifiersGroupsByUserId` relation. */
  selectedModifiersGroupsByUserId?: Maybe<UserToManySelectedModifiersGroupFilter>;
  /** Some related `selectedModifiersGroupsByUserId` exist. */
  selectedModifiersGroupsByUserIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `selectedModifiersItemsByUserId` relation. */
  selectedModifiersItemsByUserId?: Maybe<UserToManySelectedModifiersItemFilter>;
  /** Some related `selectedModifiersItemsByUserId` exist. */
  selectedModifiersItemsByUserIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `cloverMerchantsByUserId` relation. */
  cloverMerchantsByUserId?: Maybe<UserToManyCloverMerchantFilter>;
  /** Some related `cloverMerchantsByUserId` exist. */
  cloverMerchantsByUserIdExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<UserFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<UserFilter>>;
  /** Negates the expression. */
  not?: Maybe<UserFilter>;
};

/** A filter to be used against UserRoles fields. All fields are combined with a logical ‘and.’ */
export type UserRolesFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<UserRoles>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<UserRoles>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<UserRoles>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<UserRoles>;
  /** Included in the specified list. */
  in?: Maybe<Array<UserRoles>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<UserRoles>>;
  /** Less than the specified value. */
  lessThan?: Maybe<UserRoles>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<UserRoles>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<UserRoles>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<UserRoles>;
};

/** A filter to be used against many `UserEmail` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyUserEmailFilter = {
  /** Every related `UserEmail` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<UserEmailFilter>;
  /** Some related `UserEmail` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<UserEmailFilter>;
  /** No related `UserEmail` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<UserEmailFilter>;
};

/** A filter to be used against many `Location` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyLocationFilter = {
  /** Every related `Location` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LocationFilter>;
  /** Some related `Location` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LocationFilter>;
  /** No related `Location` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LocationFilter>;
};

/** A filter to be used against `Location` object types. All fields are combined with a logical ‘and.’ */
export type LocationFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `locationTablesByLocationId` relation. */
  locationTablesByLocationId?: Maybe<LocationToManyLocationTableFilter>;
  /** Some related `locationTablesByLocationId` exist. */
  locationTablesByLocationIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `menusByLocationId` relation. */
  menusByLocationId?: Maybe<LocationToManyMenuFilter>;
  /** Some related `menusByLocationId` exist. */
  menusByLocationIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `menuSectionsByLocationId` relation. */
  menuSectionsByLocationId?: Maybe<LocationToManyMenuSectionFilter>;
  /** Some related `menuSectionsByLocationId` exist. */
  menuSectionsByLocationIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `itemsByLocationId` relation. */
  itemsByLocationId?: Maybe<LocationToManyItemFilter>;
  /** Some related `itemsByLocationId` exist. */
  itemsByLocationIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `sectionsItemsByLocationId` relation. */
  sectionsItemsByLocationId?: Maybe<LocationToManySectionsItemFilter>;
  /** Some related `sectionsItemsByLocationId` exist. */
  sectionsItemsByLocationIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `locationsSessionsByLocationId` relation. */
  locationsSessionsByLocationId?: Maybe<LocationToManyLocationsSessionFilter>;
  /** Some related `locationsSessionsByLocationId` exist. */
  locationsSessionsByLocationIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `cloverMerchantsByLocationId` relation. */
  cloverMerchantsByLocationId?: Maybe<LocationToManyCloverMerchantFilter>;
  /** Some related `cloverMerchantsByLocationId` exist. */
  cloverMerchantsByLocationIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userByUserId` relation. */
  userByUserId?: Maybe<UserFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LocationFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LocationFilter>>;
  /** Negates the expression. */
  not?: Maybe<LocationFilter>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Int']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Int']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Int']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Int']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Int']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Int']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Int']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Int']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Int']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Int']>;
};

/** A filter to be used against many `LocationTable` object types. All fields are combined with a logical ‘and.’ */
export type LocationToManyLocationTableFilter = {
  /** Every related `LocationTable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LocationTableFilter>;
  /** Some related `LocationTable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LocationTableFilter>;
  /** No related `LocationTable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LocationTableFilter>;
};

/** A filter to be used against `LocationTable` object types. All fields are combined with a logical ‘and.’ */
export type LocationTableFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `locationId` field. */
  locationId?: Maybe<IntFilter>;
  /** Filter by the object’s `tableNumber` field. */
  tableNumber?: Maybe<IntFilter>;
  /** Filter by the object’s `code` field. */
  code?: Maybe<StringFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `locationByLocationId` relation. */
  locationByLocationId?: Maybe<LocationFilter>;
  /** A related `locationByLocationId` exists. */
  locationByLocationIdExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LocationTableFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LocationTableFilter>>;
  /** Negates the expression. */
  not?: Maybe<LocationTableFilter>;
};

/** A filter to be used against many `Menu` object types. All fields are combined with a logical ‘and.’ */
export type LocationToManyMenuFilter = {
  /** Every related `Menu` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<MenuFilter>;
  /** Some related `Menu` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<MenuFilter>;
  /** No related `Menu` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<MenuFilter>;
};

/** A filter to be used against `Menu` object types. All fields are combined with a logical ‘and.’ */
export type MenuFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `locationId` field. */
  locationId?: Maybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `menuSectionsByMenuId` relation. */
  menuSectionsByMenuId?: Maybe<MenuToManyMenuSectionFilter>;
  /** Some related `menuSectionsByMenuId` exist. */
  menuSectionsByMenuIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `locationByLocationId` relation. */
  locationByLocationId?: Maybe<LocationFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<MenuFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<MenuFilter>>;
  /** Negates the expression. */
  not?: Maybe<MenuFilter>;
};

/** A filter to be used against many `MenuSection` object types. All fields are combined with a logical ‘and.’ */
export type MenuToManyMenuSectionFilter = {
  /** Every related `MenuSection` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<MenuSectionFilter>;
  /** Some related `MenuSection` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<MenuSectionFilter>;
  /** No related `MenuSection` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<MenuSectionFilter>;
};

/** A filter to be used against `MenuSection` object types. All fields are combined with a logical ‘and.’ */
export type MenuSectionFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `locationId` field. */
  locationId?: Maybe<IntFilter>;
  /** Filter by the object’s `menuId` field. */
  menuId?: Maybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: Maybe<StringFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `sectionsItemsByMenuSectionId` relation. */
  sectionsItemsByMenuSectionId?: Maybe<MenuSectionToManySectionsItemFilter>;
  /** Some related `sectionsItemsByMenuSectionId` exist. */
  sectionsItemsByMenuSectionIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `locationByLocationId` relation. */
  locationByLocationId?: Maybe<LocationFilter>;
  /** Filter by the object’s `menuByMenuId` relation. */
  menuByMenuId?: Maybe<MenuFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<MenuSectionFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<MenuSectionFilter>>;
  /** Negates the expression. */
  not?: Maybe<MenuSectionFilter>;
};

/** A filter to be used against many `SectionsItem` object types. All fields are combined with a logical ‘and.’ */
export type MenuSectionToManySectionsItemFilter = {
  /** Every related `SectionsItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SectionsItemFilter>;
  /** Some related `SectionsItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SectionsItemFilter>;
  /** No related `SectionsItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SectionsItemFilter>;
};

/** A filter to be used against `SectionsItem` object types. All fields are combined with a logical ‘and.’ */
export type SectionsItemFilter = {
  /** Filter by the object’s `locationId` field. */
  locationId?: Maybe<IntFilter>;
  /** Filter by the object’s `itemId` field. */
  itemId?: Maybe<IntFilter>;
  /** Filter by the object’s `menuSectionId` field. */
  menuSectionId?: Maybe<IntFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `locationByLocationId` relation. */
  locationByLocationId?: Maybe<LocationFilter>;
  /** Filter by the object’s `itemByItemId` relation. */
  itemByItemId?: Maybe<ItemFilter>;
  /** Filter by the object’s `menuSectionByMenuSectionId` relation. */
  menuSectionByMenuSectionId?: Maybe<MenuSectionFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SectionsItemFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SectionsItemFilter>>;
  /** Negates the expression. */
  not?: Maybe<SectionsItemFilter>;
};

/** A filter to be used against `Item` object types. All fields are combined with a logical ‘and.’ */
export type ItemFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `locationId` field. */
  locationId?: Maybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: Maybe<StringFilter>;
  /** Filter by the object’s `price` field. */
  price?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `sectionsItemsByItemId` relation. */
  sectionsItemsByItemId?: Maybe<ItemToManySectionsItemFilter>;
  /** Some related `sectionsItemsByItemId` exist. */
  sectionsItemsByItemIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `modifiersByItemId` relation. */
  modifiersByItemId?: Maybe<ItemToManyModifierFilter>;
  /** Some related `modifiersByItemId` exist. */
  modifiersByItemIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `sessionsItemsByItemId` relation. */
  sessionsItemsByItemId?: Maybe<ItemToManySessionsItemFilter>;
  /** Some related `sessionsItemsByItemId` exist. */
  sessionsItemsByItemIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `locationByLocationId` relation. */
  locationByLocationId?: Maybe<LocationFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ItemFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ItemFilter>>;
  /** Negates the expression. */
  not?: Maybe<ItemFilter>;
};

/** A filter to be used against many `SectionsItem` object types. All fields are combined with a logical ‘and.’ */
export type ItemToManySectionsItemFilter = {
  /** Every related `SectionsItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SectionsItemFilter>;
  /** Some related `SectionsItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SectionsItemFilter>;
  /** No related `SectionsItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SectionsItemFilter>;
};

/** A filter to be used against many `Modifier` object types. All fields are combined with a logical ‘and.’ */
export type ItemToManyModifierFilter = {
  /** Every related `Modifier` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ModifierFilter>;
  /** Some related `Modifier` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ModifierFilter>;
  /** No related `Modifier` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ModifierFilter>;
};

/** A filter to be used against `Modifier` object types. All fields are combined with a logical ‘and.’ */
export type ModifierFilter = {
  /** Filter by the object’s `itemId` field. */
  itemId?: Maybe<IntFilter>;
  /** Filter by the object’s `modifiersGroupId` field. */
  modifiersGroupId?: Maybe<IntFilter>;
  /** Filter by the object’s `parentModifiersItemId` field. */
  parentModifiersItemId?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `itemByItemId` relation. */
  itemByItemId?: Maybe<ItemFilter>;
  /** Filter by the object’s `modifiersGroupByModifiersGroupId` relation. */
  modifiersGroupByModifiersGroupId?: Maybe<ModifiersGroupFilter>;
  /** Filter by the object’s `modifiersItemByParentModifiersItemId` relation. */
  modifiersItemByParentModifiersItemId?: Maybe<ModifiersItemFilter>;
  /** A related `modifiersItemByParentModifiersItemId` exists. */
  modifiersItemByParentModifiersItemIdExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ModifierFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ModifierFilter>>;
  /** Negates the expression. */
  not?: Maybe<ModifierFilter>;
};

/** A filter to be used against `ModifiersGroup` object types. All fields are combined with a logical ‘and.’ */
export type ModifiersGroupFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: Maybe<StringFilter>;
  /** Filter by the object’s `minimum` field. */
  minimum?: Maybe<IntFilter>;
  /** Filter by the object’s `maximum` field. */
  maximum?: Maybe<IntFilter>;
  /** Filter by the object’s `required` field. */
  required?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `modifiersItemsByModifiersGroupId` relation. */
  modifiersItemsByModifiersGroupId?: Maybe<ModifiersGroupToManyModifiersItemFilter>;
  /** Some related `modifiersItemsByModifiersGroupId` exist. */
  modifiersItemsByModifiersGroupIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `modifiersByModifiersGroupId` relation. */
  modifiersByModifiersGroupId?: Maybe<ModifiersGroupToManyModifierFilter>;
  /** Some related `modifiersByModifiersGroupId` exist. */
  modifiersByModifiersGroupIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `selectedModifiersGroupsByModifiersGroupId` relation. */
  selectedModifiersGroupsByModifiersGroupId?: Maybe<ModifiersGroupToManySelectedModifiersGroupFilter>;
  /** Some related `selectedModifiersGroupsByModifiersGroupId` exist. */
  selectedModifiersGroupsByModifiersGroupIdExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ModifiersGroupFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ModifiersGroupFilter>>;
  /** Negates the expression. */
  not?: Maybe<ModifiersGroupFilter>;
};

/** A filter to be used against many `ModifiersItem` object types. All fields are combined with a logical ‘and.’ */
export type ModifiersGroupToManyModifiersItemFilter = {
  /** Every related `ModifiersItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ModifiersItemFilter>;
  /** Some related `ModifiersItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ModifiersItemFilter>;
  /** No related `ModifiersItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ModifiersItemFilter>;
};

/** A filter to be used against `ModifiersItem` object types. All fields are combined with a logical ‘and.’ */
export type ModifiersItemFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `modifiersGroupId` field. */
  modifiersGroupId?: Maybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `price` field. */
  price?: Maybe<IntFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `modifiersByParentModifiersItemId` relation. */
  modifiersByParentModifiersItemId?: Maybe<ModifiersItemToManyModifierFilter>;
  /** Some related `modifiersByParentModifiersItemId` exist. */
  modifiersByParentModifiersItemIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `selectedModifiersItemsByModifiersItemId` relation. */
  selectedModifiersItemsByModifiersItemId?: Maybe<ModifiersItemToManySelectedModifiersItemFilter>;
  /** Some related `selectedModifiersItemsByModifiersItemId` exist. */
  selectedModifiersItemsByModifiersItemIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `modifiersGroupByModifiersGroupId` relation. */
  modifiersGroupByModifiersGroupId?: Maybe<ModifiersGroupFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ModifiersItemFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ModifiersItemFilter>>;
  /** Negates the expression. */
  not?: Maybe<ModifiersItemFilter>;
};

/** A filter to be used against many `Modifier` object types. All fields are combined with a logical ‘and.’ */
export type ModifiersItemToManyModifierFilter = {
  /** Every related `Modifier` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ModifierFilter>;
  /** Some related `Modifier` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ModifierFilter>;
  /** No related `Modifier` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ModifierFilter>;
};

/** A filter to be used against many `SelectedModifiersItem` object types. All fields are combined with a logical ‘and.’ */
export type ModifiersItemToManySelectedModifiersItemFilter = {
  /** Every related `SelectedModifiersItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SelectedModifiersItemFilter>;
  /** Some related `SelectedModifiersItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SelectedModifiersItemFilter>;
  /** No related `SelectedModifiersItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SelectedModifiersItemFilter>;
};

/** A filter to be used against `SelectedModifiersItem` object types. All fields are combined with a logical ‘and.’ */
export type SelectedModifiersItemFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<UuidFilter>;
  /** Filter by the object’s `sessionsItemId` field. */
  sessionsItemId?: Maybe<IntFilter>;
  /** Filter by the object’s `selectedModifiersGroupId` field. */
  selectedModifiersGroupId?: Maybe<IntFilter>;
  /** Filter by the object’s `modifiersItemId` field. */
  modifiersItemId?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `userByUserId` relation. */
  userByUserId?: Maybe<UserFilter>;
  /** Filter by the object’s `sessionsItemBySessionsItemId` relation. */
  sessionsItemBySessionsItemId?: Maybe<SessionsItemFilter>;
  /** Filter by the object’s `selectedModifiersGroupBySelectedModifiersGroupId` relation. */
  selectedModifiersGroupBySelectedModifiersGroupId?: Maybe<SelectedModifiersGroupFilter>;
  /** Filter by the object’s `modifiersItemByModifiersItemId` relation. */
  modifiersItemByModifiersItemId?: Maybe<ModifiersItemFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SelectedModifiersItemFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SelectedModifiersItemFilter>>;
  /** Negates the expression. */
  not?: Maybe<SelectedModifiersItemFilter>;
};

/** A filter to be used against `SessionsItem` object types. All fields are combined with a logical ‘and.’ */
export type SessionsItemFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `sessionId` field. */
  sessionId?: Maybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<UuidFilter>;
  /** Filter by the object’s `itemId` field. */
  itemId?: Maybe<IntFilter>;
  /** Filter by the object’s `quantity` field. */
  quantity?: Maybe<IntFilter>;
  /** Filter by the object’s `isOrdered` field. */
  isOrdered?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isPaid` field. */
  isPaid?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `selectedModifiersGroupsBySessionsItemId` relation. */
  selectedModifiersGroupsBySessionsItemId?: Maybe<SessionsItemToManySelectedModifiersGroupFilter>;
  /** Some related `selectedModifiersGroupsBySessionsItemId` exist. */
  selectedModifiersGroupsBySessionsItemIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `selectedModifiersItemsBySessionsItemId` relation. */
  selectedModifiersItemsBySessionsItemId?: Maybe<SessionsItemToManySelectedModifiersItemFilter>;
  /** Some related `selectedModifiersItemsBySessionsItemId` exist. */
  selectedModifiersItemsBySessionsItemIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `locationsSessionBySessionId` relation. */
  locationsSessionBySessionId?: Maybe<LocationsSessionFilter>;
  /** Filter by the object’s `userByUserId` relation. */
  userByUserId?: Maybe<UserFilter>;
  /** Filter by the object’s `itemByItemId` relation. */
  itemByItemId?: Maybe<ItemFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SessionsItemFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SessionsItemFilter>>;
  /** Negates the expression. */
  not?: Maybe<SessionsItemFilter>;
};

/** A filter to be used against many `SelectedModifiersGroup` object types. All fields are combined with a logical ‘and.’ */
export type SessionsItemToManySelectedModifiersGroupFilter = {
  /** Every related `SelectedModifiersGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SelectedModifiersGroupFilter>;
  /** Some related `SelectedModifiersGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SelectedModifiersGroupFilter>;
  /** No related `SelectedModifiersGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SelectedModifiersGroupFilter>;
};

/** A filter to be used against `SelectedModifiersGroup` object types. All fields are combined with a logical ‘and.’ */
export type SelectedModifiersGroupFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<UuidFilter>;
  /** Filter by the object’s `sessionsItemId` field. */
  sessionsItemId?: Maybe<IntFilter>;
  /** Filter by the object’s `modifiersGroupId` field. */
  modifiersGroupId?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `selectedModifiersItemsBySelectedModifiersGroupId` relation. */
  selectedModifiersItemsBySelectedModifiersGroupId?: Maybe<SelectedModifiersGroupToManySelectedModifiersItemFilter>;
  /** Some related `selectedModifiersItemsBySelectedModifiersGroupId` exist. */
  selectedModifiersItemsBySelectedModifiersGroupIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userByUserId` relation. */
  userByUserId?: Maybe<UserFilter>;
  /** Filter by the object’s `sessionsItemBySessionsItemId` relation. */
  sessionsItemBySessionsItemId?: Maybe<SessionsItemFilter>;
  /** Filter by the object’s `modifiersGroupByModifiersGroupId` relation. */
  modifiersGroupByModifiersGroupId?: Maybe<ModifiersGroupFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SelectedModifiersGroupFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SelectedModifiersGroupFilter>>;
  /** Negates the expression. */
  not?: Maybe<SelectedModifiersGroupFilter>;
};

/** A filter to be used against many `SelectedModifiersItem` object types. All fields are combined with a logical ‘and.’ */
export type SelectedModifiersGroupToManySelectedModifiersItemFilter = {
  /** Every related `SelectedModifiersItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SelectedModifiersItemFilter>;
  /** Some related `SelectedModifiersItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SelectedModifiersItemFilter>;
  /** No related `SelectedModifiersItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SelectedModifiersItemFilter>;
};

/** A filter to be used against many `SelectedModifiersItem` object types. All fields are combined with a logical ‘and.’ */
export type SessionsItemToManySelectedModifiersItemFilter = {
  /** Every related `SelectedModifiersItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SelectedModifiersItemFilter>;
  /** Some related `SelectedModifiersItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SelectedModifiersItemFilter>;
  /** No related `SelectedModifiersItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SelectedModifiersItemFilter>;
};

/** A filter to be used against `LocationsSession` object types. All fields are combined with a logical ‘and.’ */
export type LocationsSessionFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `locationId` field. */
  locationId?: Maybe<IntFilter>;
  /** Filter by the object’s `locationTableId` field. */
  locationTableId?: Maybe<IntFilter>;
  /** Filter by the object’s `locationTableCode` field. */
  locationTableCode?: Maybe<StringFilter>;
  /** Filter by the object’s `ownerId` field. */
  ownerId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `sessionsUsersBySessionId` relation. */
  sessionsUsersBySessionId?: Maybe<LocationsSessionToManySessionsUserFilter>;
  /** Some related `sessionsUsersBySessionId` exist. */
  sessionsUsersBySessionIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `sessionsItemsBySessionId` relation. */
  sessionsItemsBySessionId?: Maybe<LocationsSessionToManySessionsItemFilter>;
  /** Some related `sessionsItemsBySessionId` exist. */
  sessionsItemsBySessionIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `locationByLocationId` relation. */
  locationByLocationId?: Maybe<LocationFilter>;
  /** Filter by the object’s `userByOwnerId` relation. */
  userByOwnerId?: Maybe<UserFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LocationsSessionFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LocationsSessionFilter>>;
  /** Negates the expression. */
  not?: Maybe<LocationsSessionFilter>;
};

/** A filter to be used against many `SessionsUser` object types. All fields are combined with a logical ‘and.’ */
export type LocationsSessionToManySessionsUserFilter = {
  /** Every related `SessionsUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SessionsUserFilter>;
  /** Some related `SessionsUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SessionsUserFilter>;
  /** No related `SessionsUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SessionsUserFilter>;
};

/** A filter to be used against `SessionsUser` object types. All fields are combined with a logical ‘and.’ */
export type SessionsUserFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `sessionId` field. */
  sessionId?: Maybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<UuidFilter>;
  /** Filter by the object’s `isOrderPlacing` field. */
  isOrderPlacing?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isOrderPaying` field. */
  isOrderPaying?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `locationsSessionBySessionId` relation. */
  locationsSessionBySessionId?: Maybe<LocationsSessionFilter>;
  /** Filter by the object’s `userByUserId` relation. */
  userByUserId?: Maybe<UserFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SessionsUserFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SessionsUserFilter>>;
  /** Negates the expression. */
  not?: Maybe<SessionsUserFilter>;
};

/** A filter to be used against many `SessionsItem` object types. All fields are combined with a logical ‘and.’ */
export type LocationsSessionToManySessionsItemFilter = {
  /** Every related `SessionsItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SessionsItemFilter>;
  /** Some related `SessionsItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SessionsItemFilter>;
  /** No related `SessionsItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SessionsItemFilter>;
};

/** A filter to be used against many `Modifier` object types. All fields are combined with a logical ‘and.’ */
export type ModifiersGroupToManyModifierFilter = {
  /** Every related `Modifier` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ModifierFilter>;
  /** Some related `Modifier` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ModifierFilter>;
  /** No related `Modifier` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ModifierFilter>;
};

/** A filter to be used against many `SelectedModifiersGroup` object types. All fields are combined with a logical ‘and.’ */
export type ModifiersGroupToManySelectedModifiersGroupFilter = {
  /** Every related `SelectedModifiersGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SelectedModifiersGroupFilter>;
  /** Some related `SelectedModifiersGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SelectedModifiersGroupFilter>;
  /** No related `SelectedModifiersGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SelectedModifiersGroupFilter>;
};

/** A filter to be used against many `SessionsItem` object types. All fields are combined with a logical ‘and.’ */
export type ItemToManySessionsItemFilter = {
  /** Every related `SessionsItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SessionsItemFilter>;
  /** Some related `SessionsItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SessionsItemFilter>;
  /** No related `SessionsItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SessionsItemFilter>;
};

/** A filter to be used against many `MenuSection` object types. All fields are combined with a logical ‘and.’ */
export type LocationToManyMenuSectionFilter = {
  /** Every related `MenuSection` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<MenuSectionFilter>;
  /** Some related `MenuSection` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<MenuSectionFilter>;
  /** No related `MenuSection` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<MenuSectionFilter>;
};

/** A filter to be used against many `Item` object types. All fields are combined with a logical ‘and.’ */
export type LocationToManyItemFilter = {
  /** Every related `Item` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ItemFilter>;
  /** Some related `Item` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ItemFilter>;
  /** No related `Item` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ItemFilter>;
};

/** A filter to be used against many `SectionsItem` object types. All fields are combined with a logical ‘and.’ */
export type LocationToManySectionsItemFilter = {
  /** Every related `SectionsItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SectionsItemFilter>;
  /** Some related `SectionsItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SectionsItemFilter>;
  /** No related `SectionsItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SectionsItemFilter>;
};

/** A filter to be used against many `LocationsSession` object types. All fields are combined with a logical ‘and.’ */
export type LocationToManyLocationsSessionFilter = {
  /** Every related `LocationsSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LocationsSessionFilter>;
  /** Some related `LocationsSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LocationsSessionFilter>;
  /** No related `LocationsSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LocationsSessionFilter>;
};

/** A filter to be used against many `CloverMerchant` object types. All fields are combined with a logical ‘and.’ */
export type LocationToManyCloverMerchantFilter = {
  /** Every related `CloverMerchant` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CloverMerchantFilter>;
  /** Some related `CloverMerchant` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CloverMerchantFilter>;
  /** No related `CloverMerchant` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CloverMerchantFilter>;
};

/** A filter to be used against `CloverMerchant` object types. All fields are combined with a logical ‘and.’ */
export type CloverMerchantFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<StringFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<UuidFilter>;
  /** Filter by the object’s `locationId` field. */
  locationId?: Maybe<IntFilter>;
  /** Filter by the object’s `code` field. */
  code?: Maybe<StringFilter>;
  /** Filter by the object’s `token` field. */
  token?: Maybe<StringFilter>;
  /** Filter by the object’s `paymentKey` field. */
  paymentKey?: Maybe<StringFilter>;
  /** Filter by the object’s `error` field. */
  error?: Maybe<StringFilter>;
  /** Filter by the object’s `syncingItemIds` field. */
  syncingItemIds?: Maybe<StringListFilter>;
  /** Filter by the object’s `syncingModifiersIds` field. */
  syncingModifiersIds?: Maybe<StringListFilter>;
  /** Filter by the object’s `isSyncedMerchant` field. */
  isSyncedMerchant?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isSyncingMerchant` field. */
  isSyncingMerchant?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isSyncedInventoryItems` field. */
  isSyncedInventoryItems?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isSyncingInventoryItems` field. */
  isSyncingInventoryItems?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isSyncedModifiers` field. */
  isSyncedModifiers?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isSyncingModifiers` field. */
  isSyncingModifiers?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isSyncedOrderTypes` field. */
  isSyncedOrderTypes?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isSyncingOrderTypes` field. */
  isSyncingOrderTypes?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isSyncedPaymentKey` field. */
  isSyncedPaymentKey?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isSyncingPaymentKey` field. */
  isSyncingPaymentKey?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isInitialized` field. */
  isInitialized?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `userByUserId` relation. */
  userByUserId?: Maybe<UserFilter>;
  /** Filter by the object’s `locationByLocationId` relation. */
  locationByLocationId?: Maybe<LocationFilter>;
  /** A related `locationByLocationId` exists. */
  locationByLocationIdExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CloverMerchantFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CloverMerchantFilter>>;
  /** Negates the expression. */
  not?: Maybe<CloverMerchantFilter>;
};

/** A filter to be used against String List fields. All fields are combined with a logical ‘and.’ */
export type StringListFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Contains the specified list of values. */
  contains?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Contained by the specified list of values. */
  containedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Overlaps the specified list of values. */
  overlaps?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any array item is equal to the specified value. */
  anyEqualTo?: Maybe<Scalars['String']>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: Maybe<Scalars['String']>;
  /** Any array item is less than the specified value. */
  anyLessThan?: Maybe<Scalars['String']>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: Maybe<Scalars['String']>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: Maybe<Scalars['String']>;
};

/** A filter to be used against many `LocationsSession` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyLocationsSessionFilter = {
  /** Every related `LocationsSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LocationsSessionFilter>;
  /** Some related `LocationsSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LocationsSessionFilter>;
  /** No related `LocationsSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LocationsSessionFilter>;
};

/** A filter to be used against many `SessionsUser` object types. All fields are combined with a logical ‘and.’ */
export type UserToManySessionsUserFilter = {
  /** Every related `SessionsUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SessionsUserFilter>;
  /** Some related `SessionsUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SessionsUserFilter>;
  /** No related `SessionsUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SessionsUserFilter>;
};

/** A filter to be used against many `SessionsItem` object types. All fields are combined with a logical ‘and.’ */
export type UserToManySessionsItemFilter = {
  /** Every related `SessionsItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SessionsItemFilter>;
  /** Some related `SessionsItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SessionsItemFilter>;
  /** No related `SessionsItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SessionsItemFilter>;
};

/** A filter to be used against many `SelectedModifiersGroup` object types. All fields are combined with a logical ‘and.’ */
export type UserToManySelectedModifiersGroupFilter = {
  /** Every related `SelectedModifiersGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SelectedModifiersGroupFilter>;
  /** Some related `SelectedModifiersGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SelectedModifiersGroupFilter>;
  /** No related `SelectedModifiersGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SelectedModifiersGroupFilter>;
};

/** A filter to be used against many `SelectedModifiersItem` object types. All fields are combined with a logical ‘and.’ */
export type UserToManySelectedModifiersItemFilter = {
  /** Every related `SelectedModifiersItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SelectedModifiersItemFilter>;
  /** Some related `SelectedModifiersItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SelectedModifiersItemFilter>;
  /** No related `SelectedModifiersItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SelectedModifiersItemFilter>;
};

/** A filter to be used against many `CloverMerchant` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyCloverMerchantFilter = {
  /** Every related `CloverMerchant` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CloverMerchantFilter>;
  /** Some related `CloverMerchant` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CloverMerchantFilter>;
  /** No related `CloverMerchant` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CloverMerchantFilter>;
};

/** A connection to a list of `Location` values. */
export type LocationsConnection = {
  __typename?: 'LocationsConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location` and cursor to aid in pagination. */
  edges: Array<LocationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type Location = Node & {
  __typename?: 'Location';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  userId: Scalars['UUID'];
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Location`. */
  userByUserId?: Maybe<User>;
  /** Reads and enables pagination through a set of `LocationTable`. */
  locationTablesByLocationId: LocationTablesConnection;
  /** Reads and enables pagination through a set of `Menu`. */
  menusByLocationId: MenusConnection;
  /** Reads and enables pagination through a set of `MenuSection`. */
  menuSectionsByLocationId: MenuSectionsConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByLocationId: ItemsConnection;
  /** Reads and enables pagination through a set of `SectionsItem`. */
  sectionsItemsByLocationId: SectionsItemsConnection;
  /** Reads and enables pagination through a set of `LocationsSession`. */
  locationsSessionsByLocationId: LocationsSessionsConnection;
  /** Reads and enables pagination through a set of `CloverMerchant`. */
  cloverMerchantsByLocationId: CloverMerchantsConnection;
  /** Reads and enables pagination through a set of `Menu`. */
  menusByMenuSectionLocationIdAndMenuId: LocationMenusByMenuSectionLocationIdAndMenuIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsBySectionsItemLocationIdAndItemId: LocationItemsBySectionsItemLocationIdAndItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `MenuSection`. */
  menuSectionsBySectionsItemLocationIdAndMenuSectionId: LocationMenuSectionsBySectionsItemLocationIdAndMenuSectionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByLocationsSessionLocationIdAndOwnerId: LocationUsersByLocationsSessionLocationIdAndOwnerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByCloverMerchantLocationIdAndUserId: LocationUsersByCloverMerchantLocationIdAndUserIdManyToManyConnection;
};


export type LocationLocationTablesByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationTablesOrderBy>>;
  condition?: Maybe<LocationTableCondition>;
  filter?: Maybe<LocationTableFilter>;
};


export type LocationMenusByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MenusOrderBy>>;
  condition?: Maybe<MenuCondition>;
  filter?: Maybe<MenuFilter>;
};


export type LocationMenuSectionsByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MenuSectionsOrderBy>>;
  condition?: Maybe<MenuSectionCondition>;
  filter?: Maybe<MenuSectionFilter>;
};


export type LocationItemsByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type LocationSectionsItemsByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SectionsItemsOrderBy>>;
  condition?: Maybe<SectionsItemCondition>;
  filter?: Maybe<SectionsItemFilter>;
};


export type LocationLocationsSessionsByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsSessionsOrderBy>>;
  condition?: Maybe<LocationsSessionCondition>;
  filter?: Maybe<LocationsSessionFilter>;
};


export type LocationCloverMerchantsByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CloverMerchantsOrderBy>>;
  condition?: Maybe<CloverMerchantCondition>;
  filter?: Maybe<CloverMerchantFilter>;
};


export type LocationMenusByMenuSectionLocationIdAndMenuIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MenusOrderBy>>;
  condition?: Maybe<MenuCondition>;
  filter?: Maybe<MenuFilter>;
};


export type LocationItemsBySectionsItemLocationIdAndItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type LocationMenuSectionsBySectionsItemLocationIdAndMenuSectionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MenuSectionsOrderBy>>;
  condition?: Maybe<MenuSectionCondition>;
  filter?: Maybe<MenuSectionFilter>;
};


export type LocationUsersByLocationsSessionLocationIdAndOwnerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type LocationUsersByCloverMerchantLocationIdAndUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};

/** A connection to a list of `LocationTable` values. */
export type LocationTablesConnection = {
  __typename?: 'LocationTablesConnection';
  /** A list of `LocationTable` objects. */
  nodes: Array<LocationTable>;
  /** A list of edges which contains the `LocationTable` and cursor to aid in pagination. */
  edges: Array<LocationTablesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LocationTable` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type LocationTable = Node & {
  __typename?: 'LocationTable';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  locationId?: Maybe<Scalars['Int']>;
  tableNumber: Scalars['Int'];
  code?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Location` that is related to this `LocationTable`. */
  locationByLocationId?: Maybe<Location>;
};

/** A `LocationTable` edge in the connection. */
export type LocationTablesEdge = {
  __typename?: 'LocationTablesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LocationTable` at the end of the edge. */
  node: LocationTable;
};

/** Methods to use when ordering `LocationTable`. */
export enum LocationTablesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LocationIdAsc = 'LOCATION_ID_ASC',
  LocationIdDesc = 'LOCATION_ID_DESC',
  TableNumberAsc = 'TABLE_NUMBER_ASC',
  TableNumberDesc = 'TABLE_NUMBER_DESC',
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `LocationTable` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LocationTableCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `locationId` field. */
  locationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `tableNumber` field. */
  tableNumber?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `code` field. */
  code?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `isActive` field. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `Menu` values. */
export type MenusConnection = {
  __typename?: 'MenusConnection';
  /** A list of `Menu` objects. */
  nodes: Array<Menu>;
  /** A list of edges which contains the `Menu` and cursor to aid in pagination. */
  edges: Array<MenusEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Menu` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type Menu = Node & {
  __typename?: 'Menu';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  locationId: Scalars['Int'];
  name: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Location` that is related to this `Menu`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads and enables pagination through a set of `MenuSection`. */
  menuSectionsByMenuId: MenuSectionsConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByMenuSectionMenuIdAndLocationId: MenuLocationsByMenuSectionMenuIdAndLocationIdManyToManyConnection;
};


export type MenuMenuSectionsByMenuIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MenuSectionsOrderBy>>;
  condition?: Maybe<MenuSectionCondition>;
  filter?: Maybe<MenuSectionFilter>;
};


export type MenuLocationsByMenuSectionMenuIdAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};

/** A connection to a list of `MenuSection` values. */
export type MenuSectionsConnection = {
  __typename?: 'MenuSectionsConnection';
  /** A list of `MenuSection` objects. */
  nodes: Array<MenuSection>;
  /** A list of edges which contains the `MenuSection` and cursor to aid in pagination. */
  edges: Array<MenuSectionsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MenuSection` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type MenuSection = Node & {
  __typename?: 'MenuSection';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  locationId: Scalars['Int'];
  menuId: Scalars['Int'];
  name: Scalars['String'];
  description: Scalars['String'];
  isActive: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Location` that is related to this `MenuSection`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `Menu` that is related to this `MenuSection`. */
  menuByMenuId?: Maybe<Menu>;
  /** Reads and enables pagination through a set of `SectionsItem`. */
  sectionsItemsByMenuSectionId: SectionsItemsConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsBySectionsItemMenuSectionIdAndLocationId: MenuSectionLocationsBySectionsItemMenuSectionIdAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsBySectionsItemMenuSectionIdAndItemId: MenuSectionItemsBySectionsItemMenuSectionIdAndItemIdManyToManyConnection;
};


export type MenuSectionSectionsItemsByMenuSectionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SectionsItemsOrderBy>>;
  condition?: Maybe<SectionsItemCondition>;
  filter?: Maybe<SectionsItemFilter>;
};


export type MenuSectionLocationsBySectionsItemMenuSectionIdAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type MenuSectionItemsBySectionsItemMenuSectionIdAndItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `SectionsItem` values. */
export type SectionsItemsConnection = {
  __typename?: 'SectionsItemsConnection';
  /** A list of `SectionsItem` objects. */
  nodes: Array<SectionsItem>;
  /** A list of edges which contains the `SectionsItem` and cursor to aid in pagination. */
  edges: Array<SectionsItemsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SectionsItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type SectionsItem = Node & {
  __typename?: 'SectionsItem';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  locationId: Scalars['Int'];
  itemId: Scalars['Int'];
  menuSectionId: Scalars['Int'];
  isActive: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Location` that is related to this `SectionsItem`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `Item` that is related to this `SectionsItem`. */
  itemByItemId?: Maybe<Item>;
  /** Reads a single `MenuSection` that is related to this `SectionsItem`. */
  menuSectionByMenuSectionId?: Maybe<MenuSection>;
};

export type Item = Node & {
  __typename?: 'Item';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  locationId: Scalars['Int'];
  name: Scalars['String'];
  description: Scalars['String'];
  price: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Location` that is related to this `Item`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads and enables pagination through a set of `SectionsItem`. */
  sectionsItemsByItemId: SectionsItemsConnection;
  /** Reads and enables pagination through a set of `Modifier`. */
  modifiersByItemId: ModifiersConnection;
  /** Reads and enables pagination through a set of `SessionsItem`. */
  sessionsItemsByItemId: SessionsItemsConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsBySectionsItemItemIdAndLocationId: ItemLocationsBySectionsItemItemIdAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `MenuSection`. */
  menuSectionsBySectionsItemItemIdAndMenuSectionId: ItemMenuSectionsBySectionsItemItemIdAndMenuSectionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ModifiersGroup`. */
  modifiersGroupsByModifierItemIdAndModifiersGroupId: ItemModifiersGroupsByModifierItemIdAndModifiersGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ModifiersItem`. */
  modifiersItemsByModifierItemIdAndParentModifiersItemId: ItemModifiersItemsByModifierItemIdAndParentModifiersItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `LocationsSession`. */
  locationsSessionsBySessionsItemItemIdAndSessionId: ItemLocationsSessionsBySessionsItemItemIdAndSessionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersBySessionsItemItemIdAndUserId: ItemUsersBySessionsItemItemIdAndUserIdManyToManyConnection;
};


export type ItemSectionsItemsByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SectionsItemsOrderBy>>;
  condition?: Maybe<SectionsItemCondition>;
  filter?: Maybe<SectionsItemFilter>;
};


export type ItemModifiersByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModifiersOrderBy>>;
  condition?: Maybe<ModifierCondition>;
  filter?: Maybe<ModifierFilter>;
};


export type ItemSessionsItemsByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SessionsItemsOrderBy>>;
  condition?: Maybe<SessionsItemCondition>;
  filter?: Maybe<SessionsItemFilter>;
};


export type ItemLocationsBySectionsItemItemIdAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type ItemMenuSectionsBySectionsItemItemIdAndMenuSectionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MenuSectionsOrderBy>>;
  condition?: Maybe<MenuSectionCondition>;
  filter?: Maybe<MenuSectionFilter>;
};


export type ItemModifiersGroupsByModifierItemIdAndModifiersGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModifiersGroupsOrderBy>>;
  condition?: Maybe<ModifiersGroupCondition>;
  filter?: Maybe<ModifiersGroupFilter>;
};


export type ItemModifiersItemsByModifierItemIdAndParentModifiersItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModifiersItemsOrderBy>>;
  condition?: Maybe<ModifiersItemCondition>;
  filter?: Maybe<ModifiersItemFilter>;
};


export type ItemLocationsSessionsBySessionsItemItemIdAndSessionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsSessionsOrderBy>>;
  condition?: Maybe<LocationsSessionCondition>;
  filter?: Maybe<LocationsSessionFilter>;
};


export type ItemUsersBySessionsItemItemIdAndUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};

/** Methods to use when ordering `SectionsItem`. */
export enum SectionsItemsOrderBy {
  Natural = 'NATURAL',
  LocationIdAsc = 'LOCATION_ID_ASC',
  LocationIdDesc = 'LOCATION_ID_DESC',
  ItemIdAsc = 'ITEM_ID_ASC',
  ItemIdDesc = 'ITEM_ID_DESC',
  MenuSectionIdAsc = 'MENU_SECTION_ID_ASC',
  MenuSectionIdDesc = 'MENU_SECTION_ID_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SectionsItem` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SectionsItemCondition = {
  /** Checks for equality with the object’s `locationId` field. */
  locationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `itemId` field. */
  itemId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `menuSectionId` field. */
  menuSectionId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isActive` field. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `Modifier` values. */
export type ModifiersConnection = {
  __typename?: 'ModifiersConnection';
  /** A list of `Modifier` objects. */
  nodes: Array<Modifier>;
  /** A list of edges which contains the `Modifier` and cursor to aid in pagination. */
  edges: Array<ModifiersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Modifier` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type Modifier = Node & {
  __typename?: 'Modifier';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  itemId: Scalars['Int'];
  modifiersGroupId: Scalars['Int'];
  parentModifiersItemId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Item` that is related to this `Modifier`. */
  itemByItemId?: Maybe<Item>;
  /** Reads a single `ModifiersGroup` that is related to this `Modifier`. */
  modifiersGroupByModifiersGroupId?: Maybe<ModifiersGroup>;
  /** Reads a single `ModifiersItem` that is related to this `Modifier`. */
  modifiersItemByParentModifiersItemId?: Maybe<ModifiersItem>;
};

export type ModifiersGroup = Node & {
  __typename?: 'ModifiersGroup';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  name: Scalars['String'];
  description: Scalars['String'];
  minimum: Scalars['Int'];
  maximum: Scalars['Int'];
  required: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `ModifiersItem`. */
  modifiersItemsByModifiersGroupId: ModifiersItemsConnection;
  /** Reads and enables pagination through a set of `Modifier`. */
  modifiersByModifiersGroupId: ModifiersConnection;
  /** Reads and enables pagination through a set of `SelectedModifiersGroup`. */
  selectedModifiersGroupsByModifiersGroupId: SelectedModifiersGroupsConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByModifierModifiersGroupIdAndItemId: ModifiersGroupItemsByModifierModifiersGroupIdAndItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ModifiersItem`. */
  modifiersItemsByModifierModifiersGroupIdAndParentModifiersItemId: ModifiersGroupModifiersItemsByModifierModifiersGroupIdAndParentModifiersItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersBySelectedModifiersGroupModifiersGroupIdAndUserId: ModifiersGroupUsersBySelectedModifiersGroupModifiersGroupIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `SessionsItem`. */
  sessionsItemsBySelectedModifiersGroupModifiersGroupIdAndSessionsItemId: ModifiersGroupSessionsItemsBySelectedModifiersGroupModifiersGroupIdAndSessionsItemIdManyToManyConnection;
};


export type ModifiersGroupModifiersItemsByModifiersGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModifiersItemsOrderBy>>;
  condition?: Maybe<ModifiersItemCondition>;
  filter?: Maybe<ModifiersItemFilter>;
};


export type ModifiersGroupModifiersByModifiersGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModifiersOrderBy>>;
  condition?: Maybe<ModifierCondition>;
  filter?: Maybe<ModifierFilter>;
};


export type ModifiersGroupSelectedModifiersGroupsByModifiersGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersGroupsOrderBy>>;
  condition?: Maybe<SelectedModifiersGroupCondition>;
  filter?: Maybe<SelectedModifiersGroupFilter>;
};


export type ModifiersGroupItemsByModifierModifiersGroupIdAndItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type ModifiersGroupModifiersItemsByModifierModifiersGroupIdAndParentModifiersItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModifiersItemsOrderBy>>;
  condition?: Maybe<ModifiersItemCondition>;
  filter?: Maybe<ModifiersItemFilter>;
};


export type ModifiersGroupUsersBySelectedModifiersGroupModifiersGroupIdAndUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type ModifiersGroupSessionsItemsBySelectedModifiersGroupModifiersGroupIdAndSessionsItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SessionsItemsOrderBy>>;
  condition?: Maybe<SessionsItemCondition>;
  filter?: Maybe<SessionsItemFilter>;
};

/** A connection to a list of `ModifiersItem` values. */
export type ModifiersItemsConnection = {
  __typename?: 'ModifiersItemsConnection';
  /** A list of `ModifiersItem` objects. */
  nodes: Array<ModifiersItem>;
  /** A list of edges which contains the `ModifiersItem` and cursor to aid in pagination. */
  edges: Array<ModifiersItemsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ModifiersItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type ModifiersItem = Node & {
  __typename?: 'ModifiersItem';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  modifiersGroupId: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Int'];
  isActive: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `ModifiersGroup` that is related to this `ModifiersItem`. */
  modifiersGroupByModifiersGroupId?: Maybe<ModifiersGroup>;
  /** Reads and enables pagination through a set of `Modifier`. */
  modifiersByParentModifiersItemId: ModifiersConnection;
  /** Reads and enables pagination through a set of `SelectedModifiersItem`. */
  selectedModifiersItemsByModifiersItemId: SelectedModifiersItemsConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByModifierParentModifiersItemIdAndItemId: ModifiersItemItemsByModifierParentModifiersItemIdAndItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ModifiersGroup`. */
  modifiersGroupsByModifierParentModifiersItemIdAndModifiersGroupId: ModifiersItemModifiersGroupsByModifierParentModifiersItemIdAndModifiersGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersBySelectedModifiersItemModifiersItemIdAndUserId: ModifiersItemUsersBySelectedModifiersItemModifiersItemIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `SessionsItem`. */
  sessionsItemsBySelectedModifiersItemModifiersItemIdAndSessionsItemId: ModifiersItemSessionsItemsBySelectedModifiersItemModifiersItemIdAndSessionsItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `SelectedModifiersGroup`. */
  selectedModifiersGroupsBySelectedModifiersItemModifiersItemIdAndSelectedModifiersGroupId: ModifiersItemSelectedModifiersGroupsBySelectedModifiersItemModifiersItemIdAndSelectedModifiersGroupIdManyToManyConnection;
};


export type ModifiersItemModifiersByParentModifiersItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModifiersOrderBy>>;
  condition?: Maybe<ModifierCondition>;
  filter?: Maybe<ModifierFilter>;
};


export type ModifiersItemSelectedModifiersItemsByModifiersItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersItemsOrderBy>>;
  condition?: Maybe<SelectedModifiersItemCondition>;
  filter?: Maybe<SelectedModifiersItemFilter>;
};


export type ModifiersItemItemsByModifierParentModifiersItemIdAndItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type ModifiersItemModifiersGroupsByModifierParentModifiersItemIdAndModifiersGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModifiersGroupsOrderBy>>;
  condition?: Maybe<ModifiersGroupCondition>;
  filter?: Maybe<ModifiersGroupFilter>;
};


export type ModifiersItemUsersBySelectedModifiersItemModifiersItemIdAndUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type ModifiersItemSessionsItemsBySelectedModifiersItemModifiersItemIdAndSessionsItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SessionsItemsOrderBy>>;
  condition?: Maybe<SessionsItemCondition>;
  filter?: Maybe<SessionsItemFilter>;
};


export type ModifiersItemSelectedModifiersGroupsBySelectedModifiersItemModifiersItemIdAndSelectedModifiersGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersGroupsOrderBy>>;
  condition?: Maybe<SelectedModifiersGroupCondition>;
  filter?: Maybe<SelectedModifiersGroupFilter>;
};

/** Methods to use when ordering `Modifier`. */
export enum ModifiersOrderBy {
  Natural = 'NATURAL',
  ItemIdAsc = 'ITEM_ID_ASC',
  ItemIdDesc = 'ITEM_ID_DESC',
  ModifiersGroupIdAsc = 'MODIFIERS_GROUP_ID_ASC',
  ModifiersGroupIdDesc = 'MODIFIERS_GROUP_ID_DESC',
  ParentModifiersItemIdAsc = 'PARENT_MODIFIERS_ITEM_ID_ASC',
  ParentModifiersItemIdDesc = 'PARENT_MODIFIERS_ITEM_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `Modifier` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ModifierCondition = {
  /** Checks for equality with the object’s `itemId` field. */
  itemId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `modifiersGroupId` field. */
  modifiersGroupId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `parentModifiersItemId` field. */
  parentModifiersItemId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `SelectedModifiersItem` values. */
export type SelectedModifiersItemsConnection = {
  __typename?: 'SelectedModifiersItemsConnection';
  /** A list of `SelectedModifiersItem` objects. */
  nodes: Array<SelectedModifiersItem>;
  /** A list of edges which contains the `SelectedModifiersItem` and cursor to aid in pagination. */
  edges: Array<SelectedModifiersItemsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SelectedModifiersItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type SelectedModifiersItem = Node & {
  __typename?: 'SelectedModifiersItem';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  userId: Scalars['UUID'];
  sessionsItemId: Scalars['Int'];
  selectedModifiersGroupId: Scalars['Int'];
  modifiersItemId: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `SelectedModifiersItem`. */
  userByUserId?: Maybe<User>;
  /** Reads a single `SessionsItem` that is related to this `SelectedModifiersItem`. */
  sessionsItemBySessionsItemId?: Maybe<SessionsItem>;
  /** Reads a single `SelectedModifiersGroup` that is related to this `SelectedModifiersItem`. */
  selectedModifiersGroupBySelectedModifiersGroupId?: Maybe<SelectedModifiersGroup>;
  /** Reads a single `ModifiersItem` that is related to this `SelectedModifiersItem`. */
  modifiersItemByModifiersItemId?: Maybe<ModifiersItem>;
  /** Reads and enables pagination through a set of `SelectedModifiersGroup`. */
  modifiers: SelectedModifiersGroupsConnection;
};


export type SelectedModifiersItemModifiersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<SelectedModifiersGroupFilter>;
};

export type SessionsItem = Node & {
  __typename?: 'SessionsItem';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  sessionId: Scalars['Int'];
  userId: Scalars['UUID'];
  itemId: Scalars['Int'];
  quantity: Scalars['Int'];
  isOrdered: Scalars['Boolean'];
  isPaid: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `LocationsSession` that is related to this `SessionsItem`. */
  locationsSessionBySessionId?: Maybe<LocationsSession>;
  /** Reads a single `User` that is related to this `SessionsItem`. */
  userByUserId?: Maybe<User>;
  /** Reads a single `Item` that is related to this `SessionsItem`. */
  itemByItemId?: Maybe<Item>;
  /** Reads and enables pagination through a set of `SelectedModifiersGroup`. */
  selectedModifiersGroupsBySessionsItemId: SelectedModifiersGroupsConnection;
  /** Reads and enables pagination through a set of `SelectedModifiersItem`. */
  selectedModifiersItemsBySessionsItemId: SelectedModifiersItemsConnection;
  /** Reads and enables pagination through a set of `SelectedModifiersGroup`. */
  modifiers: SelectedModifiersGroupsConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersBySelectedModifiersGroupSessionsItemIdAndUserId: SessionsItemUsersBySelectedModifiersGroupSessionsItemIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ModifiersGroup`. */
  modifiersGroupsBySelectedModifiersGroupSessionsItemIdAndModifiersGroupId: SessionsItemModifiersGroupsBySelectedModifiersGroupSessionsItemIdAndModifiersGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersBySelectedModifiersItemSessionsItemIdAndUserId: SessionsItemUsersBySelectedModifiersItemSessionsItemIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `SelectedModifiersGroup`. */
  selectedModifiersGroupsBySelectedModifiersItemSessionsItemIdAndSelectedModifiersGroupId: SessionsItemSelectedModifiersGroupsBySelectedModifiersItemSessionsItemIdAndSelectedModifiersGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ModifiersItem`. */
  modifiersItemsBySelectedModifiersItemSessionsItemIdAndModifiersItemId: SessionsItemModifiersItemsBySelectedModifiersItemSessionsItemIdAndModifiersItemIdManyToManyConnection;
};


export type SessionsItemSelectedModifiersGroupsBySessionsItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersGroupsOrderBy>>;
  condition?: Maybe<SelectedModifiersGroupCondition>;
  filter?: Maybe<SelectedModifiersGroupFilter>;
};


export type SessionsItemSelectedModifiersItemsBySessionsItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersItemsOrderBy>>;
  condition?: Maybe<SelectedModifiersItemCondition>;
  filter?: Maybe<SelectedModifiersItemFilter>;
};


export type SessionsItemModifiersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<SelectedModifiersGroupFilter>;
};


export type SessionsItemUsersBySelectedModifiersGroupSessionsItemIdAndUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type SessionsItemModifiersGroupsBySelectedModifiersGroupSessionsItemIdAndModifiersGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModifiersGroupsOrderBy>>;
  condition?: Maybe<ModifiersGroupCondition>;
  filter?: Maybe<ModifiersGroupFilter>;
};


export type SessionsItemUsersBySelectedModifiersItemSessionsItemIdAndUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type SessionsItemSelectedModifiersGroupsBySelectedModifiersItemSessionsItemIdAndSelectedModifiersGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersGroupsOrderBy>>;
  condition?: Maybe<SelectedModifiersGroupCondition>;
  filter?: Maybe<SelectedModifiersGroupFilter>;
};


export type SessionsItemModifiersItemsBySelectedModifiersItemSessionsItemIdAndModifiersItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModifiersItemsOrderBy>>;
  condition?: Maybe<ModifiersItemCondition>;
  filter?: Maybe<ModifiersItemFilter>;
};

export type LocationsSession = Node & {
  __typename?: 'LocationsSession';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  locationId: Scalars['Int'];
  locationTableId: Scalars['Int'];
  locationTableCode?: Maybe<Scalars['String']>;
  ownerId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Location` that is related to this `LocationsSession`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `User` that is related to this `LocationsSession`. */
  userByOwnerId?: Maybe<User>;
  /** Reads and enables pagination through a set of `SessionsUser`. */
  sessionsUsersBySessionId: SessionsUsersConnection;
  /** Reads and enables pagination through a set of `SessionsItem`. */
  sessionsItemsBySessionId: SessionsItemsConnection;
  /** Reads and enables pagination through a set of `ItemsGroupByUser`. */
  itemsGroupByUser: ItemsGroupByUsersConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersBySessionsUserSessionIdAndUserId: LocationsSessionUsersBySessionsUserSessionIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersBySessionsItemSessionIdAndUserId: LocationsSessionUsersBySessionsItemSessionIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsBySessionsItemSessionIdAndItemId: LocationsSessionItemsBySessionsItemSessionIdAndItemIdManyToManyConnection;
};


export type LocationsSessionSessionsUsersBySessionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SessionsUsersOrderBy>>;
  condition?: Maybe<SessionsUserCondition>;
  filter?: Maybe<SessionsUserFilter>;
};


export type LocationsSessionSessionsItemsBySessionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SessionsItemsOrderBy>>;
  condition?: Maybe<SessionsItemCondition>;
  filter?: Maybe<SessionsItemFilter>;
};


export type LocationsSessionItemsGroupByUserArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
};


export type LocationsSessionUsersBySessionsUserSessionIdAndUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type LocationsSessionUsersBySessionsItemSessionIdAndUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type LocationsSessionItemsBySessionsItemSessionIdAndItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `SessionsUser` values. */
export type SessionsUsersConnection = {
  __typename?: 'SessionsUsersConnection';
  /** A list of `SessionsUser` objects. */
  nodes: Array<SessionsUser>;
  /** A list of edges which contains the `SessionsUser` and cursor to aid in pagination. */
  edges: Array<SessionsUsersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SessionsUser` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type SessionsUser = Node & {
  __typename?: 'SessionsUser';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  sessionId: Scalars['Int'];
  userId: Scalars['UUID'];
  isOrderPlacing: Scalars['Boolean'];
  isOrderPaying: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `LocationsSession` that is related to this `SessionsUser`. */
  locationsSessionBySessionId?: Maybe<LocationsSession>;
  /** Reads a single `User` that is related to this `SessionsUser`. */
  userByUserId?: Maybe<User>;
};

/** A `SessionsUser` edge in the connection. */
export type SessionsUsersEdge = {
  __typename?: 'SessionsUsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SessionsUser` at the end of the edge. */
  node: SessionsUser;
};

/** Methods to use when ordering `SessionsUser`. */
export enum SessionsUsersOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SessionIdAsc = 'SESSION_ID_ASC',
  SessionIdDesc = 'SESSION_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  IsOrderPlacingAsc = 'IS_ORDER_PLACING_ASC',
  IsOrderPlacingDesc = 'IS_ORDER_PLACING_DESC',
  IsOrderPayingAsc = 'IS_ORDER_PAYING_ASC',
  IsOrderPayingDesc = 'IS_ORDER_PAYING_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SessionsUser` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SessionsUserCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `sessionId` field. */
  sessionId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `isOrderPlacing` field. */
  isOrderPlacing?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isOrderPaying` field. */
  isOrderPaying?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isActive` field. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `SessionsItem` values. */
export type SessionsItemsConnection = {
  __typename?: 'SessionsItemsConnection';
  /** A list of `SessionsItem` objects. */
  nodes: Array<SessionsItem>;
  /** A list of edges which contains the `SessionsItem` and cursor to aid in pagination. */
  edges: Array<SessionsItemsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SessionsItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SessionsItem` edge in the connection. */
export type SessionsItemsEdge = {
  __typename?: 'SessionsItemsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SessionsItem` at the end of the edge. */
  node: SessionsItem;
};

/** Methods to use when ordering `SessionsItem`. */
export enum SessionsItemsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SessionIdAsc = 'SESSION_ID_ASC',
  SessionIdDesc = 'SESSION_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  ItemIdAsc = 'ITEM_ID_ASC',
  ItemIdDesc = 'ITEM_ID_DESC',
  QuantityAsc = 'QUANTITY_ASC',
  QuantityDesc = 'QUANTITY_DESC',
  IsOrderedAsc = 'IS_ORDERED_ASC',
  IsOrderedDesc = 'IS_ORDERED_DESC',
  IsPaidAsc = 'IS_PAID_ASC',
  IsPaidDesc = 'IS_PAID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SessionsItem` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SessionsItemCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `sessionId` field. */
  sessionId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `itemId` field. */
  itemId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `quantity` field. */
  quantity?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isOrdered` field. */
  isOrdered?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isPaid` field. */
  isPaid?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `ItemsGroupByUser` values. */
export type ItemsGroupByUsersConnection = {
  __typename?: 'ItemsGroupByUsersConnection';
  /** A list of `ItemsGroupByUser` objects. */
  nodes: Array<ItemsGroupByUser>;
  /** A list of edges which contains the `ItemsGroupByUser` and cursor to aid in pagination. */
  edges: Array<ItemsGroupByUsersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ItemsGroupByUser` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type ItemsGroupByUser = {
  __typename?: 'ItemsGroupByUser';
  sessionsUser?: Maybe<SessionsUser>;
  items?: Maybe<Array<Maybe<SessionsItem>>>;
};

/** A `ItemsGroupByUser` edge in the connection. */
export type ItemsGroupByUsersEdge = {
  __typename?: 'ItemsGroupByUsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ItemsGroupByUser` at the end of the edge. */
  node: ItemsGroupByUser;
};

/** A connection to a list of `User` values, with data from `SessionsUser`. */
export type LocationsSessionUsersBySessionsUserSessionIdAndUserIdManyToManyConnection = {
  __typename?: 'LocationsSessionUsersBySessionsUserSessionIdAndUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `SessionsUser`, and the cursor to aid in pagination. */
  edges: Array<LocationsSessionUsersBySessionsUserSessionIdAndUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `SessionsUser`. */
export type LocationsSessionUsersBySessionsUserSessionIdAndUserIdManyToManyEdge = {
  __typename?: 'LocationsSessionUsersBySessionsUserSessionIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  id: Scalars['Int'];
  isOrderPlacing: Scalars['Boolean'];
  isOrderPaying: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** Methods to use when ordering `User`. */
export enum UsersOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
  RoleAsc = 'ROLE_ASC',
  RoleDesc = 'ROLE_DESC',
  IsVerifiedAsc = 'IS_VERIFIED_ASC',
  IsVerifiedDesc = 'IS_VERIFIED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A condition to be used against `User` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type UserCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `username` field. */
  username?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `role` field. */
  role?: Maybe<UserRoles>;
  /** Checks for equality with the object’s `isVerified` field. */
  isVerified?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `User` values, with data from `SessionsItem`. */
export type LocationsSessionUsersBySessionsItemSessionIdAndUserIdManyToManyConnection = {
  __typename?: 'LocationsSessionUsersBySessionsItemSessionIdAndUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `SessionsItem`, and the cursor to aid in pagination. */
  edges: Array<LocationsSessionUsersBySessionsItemSessionIdAndUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `SessionsItem`. */
export type LocationsSessionUsersBySessionsItemSessionIdAndUserIdManyToManyEdge = {
  __typename?: 'LocationsSessionUsersBySessionsItemSessionIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `SessionsItem`. */
  sessionsItemsByUserId: SessionsItemsConnection;
};


/** A `User` edge in the connection, with data from `SessionsItem`. */
export type LocationsSessionUsersBySessionsItemSessionIdAndUserIdManyToManyEdgeSessionsItemsByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SessionsItemsOrderBy>>;
  condition?: Maybe<SessionsItemCondition>;
  filter?: Maybe<SessionsItemFilter>;
};

/** A connection to a list of `Item` values, with data from `SessionsItem`. */
export type LocationsSessionItemsBySessionsItemSessionIdAndItemIdManyToManyConnection = {
  __typename?: 'LocationsSessionItemsBySessionsItemSessionIdAndItemIdManyToManyConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item`, info from the `SessionsItem`, and the cursor to aid in pagination. */
  edges: Array<LocationsSessionItemsBySessionsItemSessionIdAndItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection, with data from `SessionsItem`. */
export type LocationsSessionItemsBySessionsItemSessionIdAndItemIdManyToManyEdge = {
  __typename?: 'LocationsSessionItemsBySessionsItemSessionIdAndItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
  /** Reads and enables pagination through a set of `SessionsItem`. */
  sessionsItemsByItemId: SessionsItemsConnection;
};


/** A `Item` edge in the connection, with data from `SessionsItem`. */
export type LocationsSessionItemsBySessionsItemSessionIdAndItemIdManyToManyEdgeSessionsItemsByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SessionsItemsOrderBy>>;
  condition?: Maybe<SessionsItemCondition>;
  filter?: Maybe<SessionsItemFilter>;
};

/** Methods to use when ordering `Item`. */
export enum ItemsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LocationIdAsc = 'LOCATION_ID_ASC',
  LocationIdDesc = 'LOCATION_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A condition to be used against `Item` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ItemCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `locationId` field. */
  locationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `description` field. */
  description?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `price` field. */
  price?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `SelectedModifiersGroup` values. */
export type SelectedModifiersGroupsConnection = {
  __typename?: 'SelectedModifiersGroupsConnection';
  /** A list of `SelectedModifiersGroup` objects. */
  nodes: Array<SelectedModifiersGroup>;
  /** A list of edges which contains the `SelectedModifiersGroup` and cursor to aid in pagination. */
  edges: Array<SelectedModifiersGroupsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SelectedModifiersGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type SelectedModifiersGroup = Node & {
  __typename?: 'SelectedModifiersGroup';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  userId: Scalars['UUID'];
  sessionsItemId: Scalars['Int'];
  modifiersGroupId: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `SelectedModifiersGroup`. */
  userByUserId?: Maybe<User>;
  /** Reads a single `SessionsItem` that is related to this `SelectedModifiersGroup`. */
  sessionsItemBySessionsItemId?: Maybe<SessionsItem>;
  /** Reads a single `ModifiersGroup` that is related to this `SelectedModifiersGroup`. */
  modifiersGroupByModifiersGroupId?: Maybe<ModifiersGroup>;
  /** Reads and enables pagination through a set of `SelectedModifiersItem`. */
  selectedModifiersItemsBySelectedModifiersGroupId: SelectedModifiersItemsConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersBySelectedModifiersItemSelectedModifiersGroupIdAndUserId: SelectedModifiersGroupUsersBySelectedModifiersItemSelectedModifiersGroupIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `SessionsItem`. */
  sessionsItemsBySelectedModifiersItemSelectedModifiersGroupIdAndSessionsItemId: SelectedModifiersGroupSessionsItemsBySelectedModifiersItemSelectedModifiersGroupIdAndSessionsItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ModifiersItem`. */
  modifiersItemsBySelectedModifiersItemSelectedModifiersGroupIdAndModifiersItemId: SelectedModifiersGroupModifiersItemsBySelectedModifiersItemSelectedModifiersGroupIdAndModifiersItemIdManyToManyConnection;
};


export type SelectedModifiersGroupSelectedModifiersItemsBySelectedModifiersGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersItemsOrderBy>>;
  condition?: Maybe<SelectedModifiersItemCondition>;
  filter?: Maybe<SelectedModifiersItemFilter>;
};


export type SelectedModifiersGroupUsersBySelectedModifiersItemSelectedModifiersGroupIdAndUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type SelectedModifiersGroupSessionsItemsBySelectedModifiersItemSelectedModifiersGroupIdAndSessionsItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SessionsItemsOrderBy>>;
  condition?: Maybe<SessionsItemCondition>;
  filter?: Maybe<SessionsItemFilter>;
};


export type SelectedModifiersGroupModifiersItemsBySelectedModifiersItemSelectedModifiersGroupIdAndModifiersItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModifiersItemsOrderBy>>;
  condition?: Maybe<ModifiersItemCondition>;
  filter?: Maybe<ModifiersItemFilter>;
};

/** Methods to use when ordering `SelectedModifiersItem`. */
export enum SelectedModifiersItemsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  SessionsItemIdAsc = 'SESSIONS_ITEM_ID_ASC',
  SessionsItemIdDesc = 'SESSIONS_ITEM_ID_DESC',
  SelectedModifiersGroupIdAsc = 'SELECTED_MODIFIERS_GROUP_ID_ASC',
  SelectedModifiersGroupIdDesc = 'SELECTED_MODIFIERS_GROUP_ID_DESC',
  ModifiersItemIdAsc = 'MODIFIERS_ITEM_ID_ASC',
  ModifiersItemIdDesc = 'MODIFIERS_ITEM_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SelectedModifiersItem` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type SelectedModifiersItemCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `sessionsItemId` field. */
  sessionsItemId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `selectedModifiersGroupId` field. */
  selectedModifiersGroupId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `modifiersItemId` field. */
  modifiersItemId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `User` values, with data from `SelectedModifiersItem`. */
export type SelectedModifiersGroupUsersBySelectedModifiersItemSelectedModifiersGroupIdAndUserIdManyToManyConnection = {
  __typename?: 'SelectedModifiersGroupUsersBySelectedModifiersItemSelectedModifiersGroupIdAndUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `SelectedModifiersItem`, and the cursor to aid in pagination. */
  edges: Array<SelectedModifiersGroupUsersBySelectedModifiersItemSelectedModifiersGroupIdAndUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `SelectedModifiersItem`. */
export type SelectedModifiersGroupUsersBySelectedModifiersItemSelectedModifiersGroupIdAndUserIdManyToManyEdge = {
  __typename?: 'SelectedModifiersGroupUsersBySelectedModifiersItemSelectedModifiersGroupIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `SelectedModifiersItem`. */
  selectedModifiersItemsByUserId: SelectedModifiersItemsConnection;
};


/** A `User` edge in the connection, with data from `SelectedModifiersItem`. */
export type SelectedModifiersGroupUsersBySelectedModifiersItemSelectedModifiersGroupIdAndUserIdManyToManyEdgeSelectedModifiersItemsByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersItemsOrderBy>>;
  condition?: Maybe<SelectedModifiersItemCondition>;
  filter?: Maybe<SelectedModifiersItemFilter>;
};

/** A connection to a list of `SessionsItem` values, with data from `SelectedModifiersItem`. */
export type SelectedModifiersGroupSessionsItemsBySelectedModifiersItemSelectedModifiersGroupIdAndSessionsItemIdManyToManyConnection = {
  __typename?: 'SelectedModifiersGroupSessionsItemsBySelectedModifiersItemSelectedModifiersGroupIdAndSessionsItemIdManyToManyConnection';
  /** A list of `SessionsItem` objects. */
  nodes: Array<SessionsItem>;
  /** A list of edges which contains the `SessionsItem`, info from the `SelectedModifiersItem`, and the cursor to aid in pagination. */
  edges: Array<SelectedModifiersGroupSessionsItemsBySelectedModifiersItemSelectedModifiersGroupIdAndSessionsItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SessionsItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SessionsItem` edge in the connection, with data from `SelectedModifiersItem`. */
export type SelectedModifiersGroupSessionsItemsBySelectedModifiersItemSelectedModifiersGroupIdAndSessionsItemIdManyToManyEdge = {
  __typename?: 'SelectedModifiersGroupSessionsItemsBySelectedModifiersItemSelectedModifiersGroupIdAndSessionsItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SessionsItem` at the end of the edge. */
  node: SessionsItem;
  /** Reads and enables pagination through a set of `SelectedModifiersItem`. */
  selectedModifiersItemsBySessionsItemId: SelectedModifiersItemsConnection;
};


/** A `SessionsItem` edge in the connection, with data from `SelectedModifiersItem`. */
export type SelectedModifiersGroupSessionsItemsBySelectedModifiersItemSelectedModifiersGroupIdAndSessionsItemIdManyToManyEdgeSelectedModifiersItemsBySessionsItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersItemsOrderBy>>;
  condition?: Maybe<SelectedModifiersItemCondition>;
  filter?: Maybe<SelectedModifiersItemFilter>;
};

/** A connection to a list of `ModifiersItem` values, with data from `SelectedModifiersItem`. */
export type SelectedModifiersGroupModifiersItemsBySelectedModifiersItemSelectedModifiersGroupIdAndModifiersItemIdManyToManyConnection = {
  __typename?: 'SelectedModifiersGroupModifiersItemsBySelectedModifiersItemSelectedModifiersGroupIdAndModifiersItemIdManyToManyConnection';
  /** A list of `ModifiersItem` objects. */
  nodes: Array<ModifiersItem>;
  /** A list of edges which contains the `ModifiersItem`, info from the `SelectedModifiersItem`, and the cursor to aid in pagination. */
  edges: Array<SelectedModifiersGroupModifiersItemsBySelectedModifiersItemSelectedModifiersGroupIdAndModifiersItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ModifiersItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ModifiersItem` edge in the connection, with data from `SelectedModifiersItem`. */
export type SelectedModifiersGroupModifiersItemsBySelectedModifiersItemSelectedModifiersGroupIdAndModifiersItemIdManyToManyEdge = {
  __typename?: 'SelectedModifiersGroupModifiersItemsBySelectedModifiersItemSelectedModifiersGroupIdAndModifiersItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ModifiersItem` at the end of the edge. */
  node: ModifiersItem;
  /** Reads and enables pagination through a set of `SelectedModifiersItem`. */
  selectedModifiersItemsByModifiersItemId: SelectedModifiersItemsConnection;
};


/** A `ModifiersItem` edge in the connection, with data from `SelectedModifiersItem`. */
export type SelectedModifiersGroupModifiersItemsBySelectedModifiersItemSelectedModifiersGroupIdAndModifiersItemIdManyToManyEdgeSelectedModifiersItemsByModifiersItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersItemsOrderBy>>;
  condition?: Maybe<SelectedModifiersItemCondition>;
  filter?: Maybe<SelectedModifiersItemFilter>;
};

/** Methods to use when ordering `ModifiersItem`. */
export enum ModifiersItemsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ModifiersGroupIdAsc = 'MODIFIERS_GROUP_ID_ASC',
  ModifiersGroupIdDesc = 'MODIFIERS_GROUP_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `ModifiersItem` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ModifiersItemCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `modifiersGroupId` field. */
  modifiersGroupId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `price` field. */
  price?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isActive` field. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A `SelectedModifiersGroup` edge in the connection. */
export type SelectedModifiersGroupsEdge = {
  __typename?: 'SelectedModifiersGroupsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SelectedModifiersGroup` at the end of the edge. */
  node: SelectedModifiersGroup;
};

/** Methods to use when ordering `SelectedModifiersGroup`. */
export enum SelectedModifiersGroupsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  SessionsItemIdAsc = 'SESSIONS_ITEM_ID_ASC',
  SessionsItemIdDesc = 'SESSIONS_ITEM_ID_DESC',
  ModifiersGroupIdAsc = 'MODIFIERS_GROUP_ID_ASC',
  ModifiersGroupIdDesc = 'MODIFIERS_GROUP_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SelectedModifiersGroup` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type SelectedModifiersGroupCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `sessionsItemId` field. */
  sessionsItemId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `modifiersGroupId` field. */
  modifiersGroupId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `User` values, with data from `SelectedModifiersGroup`. */
export type SessionsItemUsersBySelectedModifiersGroupSessionsItemIdAndUserIdManyToManyConnection = {
  __typename?: 'SessionsItemUsersBySelectedModifiersGroupSessionsItemIdAndUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `SelectedModifiersGroup`, and the cursor to aid in pagination. */
  edges: Array<SessionsItemUsersBySelectedModifiersGroupSessionsItemIdAndUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `SelectedModifiersGroup`. */
export type SessionsItemUsersBySelectedModifiersGroupSessionsItemIdAndUserIdManyToManyEdge = {
  __typename?: 'SessionsItemUsersBySelectedModifiersGroupSessionsItemIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `SelectedModifiersGroup`. */
  selectedModifiersGroupsByUserId: SelectedModifiersGroupsConnection;
};


/** A `User` edge in the connection, with data from `SelectedModifiersGroup`. */
export type SessionsItemUsersBySelectedModifiersGroupSessionsItemIdAndUserIdManyToManyEdgeSelectedModifiersGroupsByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersGroupsOrderBy>>;
  condition?: Maybe<SelectedModifiersGroupCondition>;
  filter?: Maybe<SelectedModifiersGroupFilter>;
};

/** A connection to a list of `ModifiersGroup` values, with data from `SelectedModifiersGroup`. */
export type SessionsItemModifiersGroupsBySelectedModifiersGroupSessionsItemIdAndModifiersGroupIdManyToManyConnection = {
  __typename?: 'SessionsItemModifiersGroupsBySelectedModifiersGroupSessionsItemIdAndModifiersGroupIdManyToManyConnection';
  /** A list of `ModifiersGroup` objects. */
  nodes: Array<ModifiersGroup>;
  /** A list of edges which contains the `ModifiersGroup`, info from the `SelectedModifiersGroup`, and the cursor to aid in pagination. */
  edges: Array<SessionsItemModifiersGroupsBySelectedModifiersGroupSessionsItemIdAndModifiersGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ModifiersGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ModifiersGroup` edge in the connection, with data from `SelectedModifiersGroup`. */
export type SessionsItemModifiersGroupsBySelectedModifiersGroupSessionsItemIdAndModifiersGroupIdManyToManyEdge = {
  __typename?: 'SessionsItemModifiersGroupsBySelectedModifiersGroupSessionsItemIdAndModifiersGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ModifiersGroup` at the end of the edge. */
  node: ModifiersGroup;
  /** Reads and enables pagination through a set of `SelectedModifiersGroup`. */
  selectedModifiersGroupsByModifiersGroupId: SelectedModifiersGroupsConnection;
};


/** A `ModifiersGroup` edge in the connection, with data from `SelectedModifiersGroup`. */
export type SessionsItemModifiersGroupsBySelectedModifiersGroupSessionsItemIdAndModifiersGroupIdManyToManyEdgeSelectedModifiersGroupsByModifiersGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersGroupsOrderBy>>;
  condition?: Maybe<SelectedModifiersGroupCondition>;
  filter?: Maybe<SelectedModifiersGroupFilter>;
};

/** Methods to use when ordering `ModifiersGroup`. */
export enum ModifiersGroupsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  MinimumAsc = 'MINIMUM_ASC',
  MinimumDesc = 'MINIMUM_DESC',
  MaximumAsc = 'MAXIMUM_ASC',
  MaximumDesc = 'MAXIMUM_DESC',
  RequiredAsc = 'REQUIRED_ASC',
  RequiredDesc = 'REQUIRED_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `ModifiersGroup` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ModifiersGroupCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `description` field. */
  description?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `minimum` field. */
  minimum?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `maximum` field. */
  maximum?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `required` field. */
  required?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isActive` field. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `User` values, with data from `SelectedModifiersItem`. */
export type SessionsItemUsersBySelectedModifiersItemSessionsItemIdAndUserIdManyToManyConnection = {
  __typename?: 'SessionsItemUsersBySelectedModifiersItemSessionsItemIdAndUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `SelectedModifiersItem`, and the cursor to aid in pagination. */
  edges: Array<SessionsItemUsersBySelectedModifiersItemSessionsItemIdAndUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `SelectedModifiersItem`. */
export type SessionsItemUsersBySelectedModifiersItemSessionsItemIdAndUserIdManyToManyEdge = {
  __typename?: 'SessionsItemUsersBySelectedModifiersItemSessionsItemIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `SelectedModifiersItem`. */
  selectedModifiersItemsByUserId: SelectedModifiersItemsConnection;
};


/** A `User` edge in the connection, with data from `SelectedModifiersItem`. */
export type SessionsItemUsersBySelectedModifiersItemSessionsItemIdAndUserIdManyToManyEdgeSelectedModifiersItemsByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersItemsOrderBy>>;
  condition?: Maybe<SelectedModifiersItemCondition>;
  filter?: Maybe<SelectedModifiersItemFilter>;
};

/** A connection to a list of `SelectedModifiersGroup` values, with data from `SelectedModifiersItem`. */
export type SessionsItemSelectedModifiersGroupsBySelectedModifiersItemSessionsItemIdAndSelectedModifiersGroupIdManyToManyConnection = {
  __typename?: 'SessionsItemSelectedModifiersGroupsBySelectedModifiersItemSessionsItemIdAndSelectedModifiersGroupIdManyToManyConnection';
  /** A list of `SelectedModifiersGroup` objects. */
  nodes: Array<SelectedModifiersGroup>;
  /** A list of edges which contains the `SelectedModifiersGroup`, info from the `SelectedModifiersItem`, and the cursor to aid in pagination. */
  edges: Array<SessionsItemSelectedModifiersGroupsBySelectedModifiersItemSessionsItemIdAndSelectedModifiersGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SelectedModifiersGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SelectedModifiersGroup` edge in the connection, with data from `SelectedModifiersItem`. */
export type SessionsItemSelectedModifiersGroupsBySelectedModifiersItemSessionsItemIdAndSelectedModifiersGroupIdManyToManyEdge = {
  __typename?: 'SessionsItemSelectedModifiersGroupsBySelectedModifiersItemSessionsItemIdAndSelectedModifiersGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SelectedModifiersGroup` at the end of the edge. */
  node: SelectedModifiersGroup;
  /** Reads and enables pagination through a set of `SelectedModifiersItem`. */
  selectedModifiersItemsBySelectedModifiersGroupId: SelectedModifiersItemsConnection;
};


/** A `SelectedModifiersGroup` edge in the connection, with data from `SelectedModifiersItem`. */
export type SessionsItemSelectedModifiersGroupsBySelectedModifiersItemSessionsItemIdAndSelectedModifiersGroupIdManyToManyEdgeSelectedModifiersItemsBySelectedModifiersGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersItemsOrderBy>>;
  condition?: Maybe<SelectedModifiersItemCondition>;
  filter?: Maybe<SelectedModifiersItemFilter>;
};

/** A connection to a list of `ModifiersItem` values, with data from `SelectedModifiersItem`. */
export type SessionsItemModifiersItemsBySelectedModifiersItemSessionsItemIdAndModifiersItemIdManyToManyConnection = {
  __typename?: 'SessionsItemModifiersItemsBySelectedModifiersItemSessionsItemIdAndModifiersItemIdManyToManyConnection';
  /** A list of `ModifiersItem` objects. */
  nodes: Array<ModifiersItem>;
  /** A list of edges which contains the `ModifiersItem`, info from the `SelectedModifiersItem`, and the cursor to aid in pagination. */
  edges: Array<SessionsItemModifiersItemsBySelectedModifiersItemSessionsItemIdAndModifiersItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ModifiersItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ModifiersItem` edge in the connection, with data from `SelectedModifiersItem`. */
export type SessionsItemModifiersItemsBySelectedModifiersItemSessionsItemIdAndModifiersItemIdManyToManyEdge = {
  __typename?: 'SessionsItemModifiersItemsBySelectedModifiersItemSessionsItemIdAndModifiersItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ModifiersItem` at the end of the edge. */
  node: ModifiersItem;
  /** Reads and enables pagination through a set of `SelectedModifiersItem`. */
  selectedModifiersItemsByModifiersItemId: SelectedModifiersItemsConnection;
};


/** A `ModifiersItem` edge in the connection, with data from `SelectedModifiersItem`. */
export type SessionsItemModifiersItemsBySelectedModifiersItemSessionsItemIdAndModifiersItemIdManyToManyEdgeSelectedModifiersItemsByModifiersItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersItemsOrderBy>>;
  condition?: Maybe<SelectedModifiersItemCondition>;
  filter?: Maybe<SelectedModifiersItemFilter>;
};

/** A `SelectedModifiersItem` edge in the connection. */
export type SelectedModifiersItemsEdge = {
  __typename?: 'SelectedModifiersItemsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SelectedModifiersItem` at the end of the edge. */
  node: SelectedModifiersItem;
};

/** A connection to a list of `Item` values, with data from `Modifier`. */
export type ModifiersItemItemsByModifierParentModifiersItemIdAndItemIdManyToManyConnection = {
  __typename?: 'ModifiersItemItemsByModifierParentModifiersItemIdAndItemIdManyToManyConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item`, info from the `Modifier`, and the cursor to aid in pagination. */
  edges: Array<ModifiersItemItemsByModifierParentModifiersItemIdAndItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection, with data from `Modifier`. */
export type ModifiersItemItemsByModifierParentModifiersItemIdAndItemIdManyToManyEdge = {
  __typename?: 'ModifiersItemItemsByModifierParentModifiersItemIdAndItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
  /** Reads and enables pagination through a set of `Modifier`. */
  modifiersByItemId: ModifiersConnection;
};


/** A `Item` edge in the connection, with data from `Modifier`. */
export type ModifiersItemItemsByModifierParentModifiersItemIdAndItemIdManyToManyEdgeModifiersByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModifiersOrderBy>>;
  condition?: Maybe<ModifierCondition>;
  filter?: Maybe<ModifierFilter>;
};

/** A connection to a list of `ModifiersGroup` values, with data from `Modifier`. */
export type ModifiersItemModifiersGroupsByModifierParentModifiersItemIdAndModifiersGroupIdManyToManyConnection = {
  __typename?: 'ModifiersItemModifiersGroupsByModifierParentModifiersItemIdAndModifiersGroupIdManyToManyConnection';
  /** A list of `ModifiersGroup` objects. */
  nodes: Array<ModifiersGroup>;
  /** A list of edges which contains the `ModifiersGroup`, info from the `Modifier`, and the cursor to aid in pagination. */
  edges: Array<ModifiersItemModifiersGroupsByModifierParentModifiersItemIdAndModifiersGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ModifiersGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ModifiersGroup` edge in the connection, with data from `Modifier`. */
export type ModifiersItemModifiersGroupsByModifierParentModifiersItemIdAndModifiersGroupIdManyToManyEdge = {
  __typename?: 'ModifiersItemModifiersGroupsByModifierParentModifiersItemIdAndModifiersGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ModifiersGroup` at the end of the edge. */
  node: ModifiersGroup;
  /** Reads and enables pagination through a set of `Modifier`. */
  modifiersByModifiersGroupId: ModifiersConnection;
};


/** A `ModifiersGroup` edge in the connection, with data from `Modifier`. */
export type ModifiersItemModifiersGroupsByModifierParentModifiersItemIdAndModifiersGroupIdManyToManyEdgeModifiersByModifiersGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModifiersOrderBy>>;
  condition?: Maybe<ModifierCondition>;
  filter?: Maybe<ModifierFilter>;
};

/** A connection to a list of `User` values, with data from `SelectedModifiersItem`. */
export type ModifiersItemUsersBySelectedModifiersItemModifiersItemIdAndUserIdManyToManyConnection = {
  __typename?: 'ModifiersItemUsersBySelectedModifiersItemModifiersItemIdAndUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `SelectedModifiersItem`, and the cursor to aid in pagination. */
  edges: Array<ModifiersItemUsersBySelectedModifiersItemModifiersItemIdAndUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `SelectedModifiersItem`. */
export type ModifiersItemUsersBySelectedModifiersItemModifiersItemIdAndUserIdManyToManyEdge = {
  __typename?: 'ModifiersItemUsersBySelectedModifiersItemModifiersItemIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `SelectedModifiersItem`. */
  selectedModifiersItemsByUserId: SelectedModifiersItemsConnection;
};


/** A `User` edge in the connection, with data from `SelectedModifiersItem`. */
export type ModifiersItemUsersBySelectedModifiersItemModifiersItemIdAndUserIdManyToManyEdgeSelectedModifiersItemsByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersItemsOrderBy>>;
  condition?: Maybe<SelectedModifiersItemCondition>;
  filter?: Maybe<SelectedModifiersItemFilter>;
};

/** A connection to a list of `SessionsItem` values, with data from `SelectedModifiersItem`. */
export type ModifiersItemSessionsItemsBySelectedModifiersItemModifiersItemIdAndSessionsItemIdManyToManyConnection = {
  __typename?: 'ModifiersItemSessionsItemsBySelectedModifiersItemModifiersItemIdAndSessionsItemIdManyToManyConnection';
  /** A list of `SessionsItem` objects. */
  nodes: Array<SessionsItem>;
  /** A list of edges which contains the `SessionsItem`, info from the `SelectedModifiersItem`, and the cursor to aid in pagination. */
  edges: Array<ModifiersItemSessionsItemsBySelectedModifiersItemModifiersItemIdAndSessionsItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SessionsItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SessionsItem` edge in the connection, with data from `SelectedModifiersItem`. */
export type ModifiersItemSessionsItemsBySelectedModifiersItemModifiersItemIdAndSessionsItemIdManyToManyEdge = {
  __typename?: 'ModifiersItemSessionsItemsBySelectedModifiersItemModifiersItemIdAndSessionsItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SessionsItem` at the end of the edge. */
  node: SessionsItem;
  /** Reads and enables pagination through a set of `SelectedModifiersItem`. */
  selectedModifiersItemsBySessionsItemId: SelectedModifiersItemsConnection;
};


/** A `SessionsItem` edge in the connection, with data from `SelectedModifiersItem`. */
export type ModifiersItemSessionsItemsBySelectedModifiersItemModifiersItemIdAndSessionsItemIdManyToManyEdgeSelectedModifiersItemsBySessionsItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersItemsOrderBy>>;
  condition?: Maybe<SelectedModifiersItemCondition>;
  filter?: Maybe<SelectedModifiersItemFilter>;
};

/** A connection to a list of `SelectedModifiersGroup` values, with data from `SelectedModifiersItem`. */
export type ModifiersItemSelectedModifiersGroupsBySelectedModifiersItemModifiersItemIdAndSelectedModifiersGroupIdManyToManyConnection = {
  __typename?: 'ModifiersItemSelectedModifiersGroupsBySelectedModifiersItemModifiersItemIdAndSelectedModifiersGroupIdManyToManyConnection';
  /** A list of `SelectedModifiersGroup` objects. */
  nodes: Array<SelectedModifiersGroup>;
  /** A list of edges which contains the `SelectedModifiersGroup`, info from the `SelectedModifiersItem`, and the cursor to aid in pagination. */
  edges: Array<ModifiersItemSelectedModifiersGroupsBySelectedModifiersItemModifiersItemIdAndSelectedModifiersGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SelectedModifiersGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SelectedModifiersGroup` edge in the connection, with data from `SelectedModifiersItem`. */
export type ModifiersItemSelectedModifiersGroupsBySelectedModifiersItemModifiersItemIdAndSelectedModifiersGroupIdManyToManyEdge = {
  __typename?: 'ModifiersItemSelectedModifiersGroupsBySelectedModifiersItemModifiersItemIdAndSelectedModifiersGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SelectedModifiersGroup` at the end of the edge. */
  node: SelectedModifiersGroup;
  /** Reads and enables pagination through a set of `SelectedModifiersItem`. */
  selectedModifiersItemsBySelectedModifiersGroupId: SelectedModifiersItemsConnection;
};


/** A `SelectedModifiersGroup` edge in the connection, with data from `SelectedModifiersItem`. */
export type ModifiersItemSelectedModifiersGroupsBySelectedModifiersItemModifiersItemIdAndSelectedModifiersGroupIdManyToManyEdgeSelectedModifiersItemsBySelectedModifiersGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersItemsOrderBy>>;
  condition?: Maybe<SelectedModifiersItemCondition>;
  filter?: Maybe<SelectedModifiersItemFilter>;
};

/** A `ModifiersItem` edge in the connection. */
export type ModifiersItemsEdge = {
  __typename?: 'ModifiersItemsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ModifiersItem` at the end of the edge. */
  node: ModifiersItem;
};

/** A connection to a list of `Item` values, with data from `Modifier`. */
export type ModifiersGroupItemsByModifierModifiersGroupIdAndItemIdManyToManyConnection = {
  __typename?: 'ModifiersGroupItemsByModifierModifiersGroupIdAndItemIdManyToManyConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item`, info from the `Modifier`, and the cursor to aid in pagination. */
  edges: Array<ModifiersGroupItemsByModifierModifiersGroupIdAndItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection, with data from `Modifier`. */
export type ModifiersGroupItemsByModifierModifiersGroupIdAndItemIdManyToManyEdge = {
  __typename?: 'ModifiersGroupItemsByModifierModifiersGroupIdAndItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
  parentModifiersItemId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A connection to a list of `ModifiersItem` values, with data from `Modifier`. */
export type ModifiersGroupModifiersItemsByModifierModifiersGroupIdAndParentModifiersItemIdManyToManyConnection = {
  __typename?: 'ModifiersGroupModifiersItemsByModifierModifiersGroupIdAndParentModifiersItemIdManyToManyConnection';
  /** A list of `ModifiersItem` objects. */
  nodes: Array<ModifiersItem>;
  /** A list of edges which contains the `ModifiersItem`, info from the `Modifier`, and the cursor to aid in pagination. */
  edges: Array<ModifiersGroupModifiersItemsByModifierModifiersGroupIdAndParentModifiersItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ModifiersItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ModifiersItem` edge in the connection, with data from `Modifier`. */
export type ModifiersGroupModifiersItemsByModifierModifiersGroupIdAndParentModifiersItemIdManyToManyEdge = {
  __typename?: 'ModifiersGroupModifiersItemsByModifierModifiersGroupIdAndParentModifiersItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ModifiersItem` at the end of the edge. */
  node: ModifiersItem;
  /** Reads and enables pagination through a set of `Modifier`. */
  modifiersByParentModifiersItemId: ModifiersConnection;
};


/** A `ModifiersItem` edge in the connection, with data from `Modifier`. */
export type ModifiersGroupModifiersItemsByModifierModifiersGroupIdAndParentModifiersItemIdManyToManyEdgeModifiersByParentModifiersItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModifiersOrderBy>>;
  condition?: Maybe<ModifierCondition>;
  filter?: Maybe<ModifierFilter>;
};

/** A connection to a list of `User` values, with data from `SelectedModifiersGroup`. */
export type ModifiersGroupUsersBySelectedModifiersGroupModifiersGroupIdAndUserIdManyToManyConnection = {
  __typename?: 'ModifiersGroupUsersBySelectedModifiersGroupModifiersGroupIdAndUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `SelectedModifiersGroup`, and the cursor to aid in pagination. */
  edges: Array<ModifiersGroupUsersBySelectedModifiersGroupModifiersGroupIdAndUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `SelectedModifiersGroup`. */
export type ModifiersGroupUsersBySelectedModifiersGroupModifiersGroupIdAndUserIdManyToManyEdge = {
  __typename?: 'ModifiersGroupUsersBySelectedModifiersGroupModifiersGroupIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `SelectedModifiersGroup`. */
  selectedModifiersGroupsByUserId: SelectedModifiersGroupsConnection;
};


/** A `User` edge in the connection, with data from `SelectedModifiersGroup`. */
export type ModifiersGroupUsersBySelectedModifiersGroupModifiersGroupIdAndUserIdManyToManyEdgeSelectedModifiersGroupsByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersGroupsOrderBy>>;
  condition?: Maybe<SelectedModifiersGroupCondition>;
  filter?: Maybe<SelectedModifiersGroupFilter>;
};

/** A connection to a list of `SessionsItem` values, with data from `SelectedModifiersGroup`. */
export type ModifiersGroupSessionsItemsBySelectedModifiersGroupModifiersGroupIdAndSessionsItemIdManyToManyConnection = {
  __typename?: 'ModifiersGroupSessionsItemsBySelectedModifiersGroupModifiersGroupIdAndSessionsItemIdManyToManyConnection';
  /** A list of `SessionsItem` objects. */
  nodes: Array<SessionsItem>;
  /** A list of edges which contains the `SessionsItem`, info from the `SelectedModifiersGroup`, and the cursor to aid in pagination. */
  edges: Array<ModifiersGroupSessionsItemsBySelectedModifiersGroupModifiersGroupIdAndSessionsItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SessionsItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SessionsItem` edge in the connection, with data from `SelectedModifiersGroup`. */
export type ModifiersGroupSessionsItemsBySelectedModifiersGroupModifiersGroupIdAndSessionsItemIdManyToManyEdge = {
  __typename?: 'ModifiersGroupSessionsItemsBySelectedModifiersGroupModifiersGroupIdAndSessionsItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SessionsItem` at the end of the edge. */
  node: SessionsItem;
  /** Reads and enables pagination through a set of `SelectedModifiersGroup`. */
  selectedModifiersGroupsBySessionsItemId: SelectedModifiersGroupsConnection;
};


/** A `SessionsItem` edge in the connection, with data from `SelectedModifiersGroup`. */
export type ModifiersGroupSessionsItemsBySelectedModifiersGroupModifiersGroupIdAndSessionsItemIdManyToManyEdgeSelectedModifiersGroupsBySessionsItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersGroupsOrderBy>>;
  condition?: Maybe<SelectedModifiersGroupCondition>;
  filter?: Maybe<SelectedModifiersGroupFilter>;
};

/** A `Modifier` edge in the connection. */
export type ModifiersEdge = {
  __typename?: 'ModifiersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Modifier` at the end of the edge. */
  node: Modifier;
};

/** A connection to a list of `Location` values, with data from `SectionsItem`. */
export type ItemLocationsBySectionsItemItemIdAndLocationIdManyToManyConnection = {
  __typename?: 'ItemLocationsBySectionsItemItemIdAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `SectionsItem`, and the cursor to aid in pagination. */
  edges: Array<ItemLocationsBySectionsItemItemIdAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `SectionsItem`. */
export type ItemLocationsBySectionsItemItemIdAndLocationIdManyToManyEdge = {
  __typename?: 'ItemLocationsBySectionsItemItemIdAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `SectionsItem`. */
  sectionsItemsByLocationId: SectionsItemsConnection;
};


/** A `Location` edge in the connection, with data from `SectionsItem`. */
export type ItemLocationsBySectionsItemItemIdAndLocationIdManyToManyEdgeSectionsItemsByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SectionsItemsOrderBy>>;
  condition?: Maybe<SectionsItemCondition>;
  filter?: Maybe<SectionsItemFilter>;
};

/** Methods to use when ordering `Location`. */
export enum LocationsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `Location` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type LocationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `isActive` field. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `MenuSection` values, with data from `SectionsItem`. */
export type ItemMenuSectionsBySectionsItemItemIdAndMenuSectionIdManyToManyConnection = {
  __typename?: 'ItemMenuSectionsBySectionsItemItemIdAndMenuSectionIdManyToManyConnection';
  /** A list of `MenuSection` objects. */
  nodes: Array<MenuSection>;
  /** A list of edges which contains the `MenuSection`, info from the `SectionsItem`, and the cursor to aid in pagination. */
  edges: Array<ItemMenuSectionsBySectionsItemItemIdAndMenuSectionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MenuSection` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `MenuSection` edge in the connection, with data from `SectionsItem`. */
export type ItemMenuSectionsBySectionsItemItemIdAndMenuSectionIdManyToManyEdge = {
  __typename?: 'ItemMenuSectionsBySectionsItemItemIdAndMenuSectionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MenuSection` at the end of the edge. */
  node: MenuSection;
  /** Reads and enables pagination through a set of `SectionsItem`. */
  sectionsItemsByMenuSectionId: SectionsItemsConnection;
};


/** A `MenuSection` edge in the connection, with data from `SectionsItem`. */
export type ItemMenuSectionsBySectionsItemItemIdAndMenuSectionIdManyToManyEdgeSectionsItemsByMenuSectionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SectionsItemsOrderBy>>;
  condition?: Maybe<SectionsItemCondition>;
  filter?: Maybe<SectionsItemFilter>;
};

/** Methods to use when ordering `MenuSection`. */
export enum MenuSectionsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LocationIdAsc = 'LOCATION_ID_ASC',
  LocationIdDesc = 'LOCATION_ID_DESC',
  MenuIdAsc = 'MENU_ID_ASC',
  MenuIdDesc = 'MENU_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `MenuSection` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type MenuSectionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `locationId` field. */
  locationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `menuId` field. */
  menuId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `description` field. */
  description?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `isActive` field. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `ModifiersGroup` values, with data from `Modifier`. */
export type ItemModifiersGroupsByModifierItemIdAndModifiersGroupIdManyToManyConnection = {
  __typename?: 'ItemModifiersGroupsByModifierItemIdAndModifiersGroupIdManyToManyConnection';
  /** A list of `ModifiersGroup` objects. */
  nodes: Array<ModifiersGroup>;
  /** A list of edges which contains the `ModifiersGroup`, info from the `Modifier`, and the cursor to aid in pagination. */
  edges: Array<ItemModifiersGroupsByModifierItemIdAndModifiersGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ModifiersGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ModifiersGroup` edge in the connection, with data from `Modifier`. */
export type ItemModifiersGroupsByModifierItemIdAndModifiersGroupIdManyToManyEdge = {
  __typename?: 'ItemModifiersGroupsByModifierItemIdAndModifiersGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ModifiersGroup` at the end of the edge. */
  node: ModifiersGroup;
  parentModifiersItemId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A connection to a list of `ModifiersItem` values, with data from `Modifier`. */
export type ItemModifiersItemsByModifierItemIdAndParentModifiersItemIdManyToManyConnection = {
  __typename?: 'ItemModifiersItemsByModifierItemIdAndParentModifiersItemIdManyToManyConnection';
  /** A list of `ModifiersItem` objects. */
  nodes: Array<ModifiersItem>;
  /** A list of edges which contains the `ModifiersItem`, info from the `Modifier`, and the cursor to aid in pagination. */
  edges: Array<ItemModifiersItemsByModifierItemIdAndParentModifiersItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ModifiersItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ModifiersItem` edge in the connection, with data from `Modifier`. */
export type ItemModifiersItemsByModifierItemIdAndParentModifiersItemIdManyToManyEdge = {
  __typename?: 'ItemModifiersItemsByModifierItemIdAndParentModifiersItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ModifiersItem` at the end of the edge. */
  node: ModifiersItem;
  /** Reads and enables pagination through a set of `Modifier`. */
  modifiersByParentModifiersItemId: ModifiersConnection;
};


/** A `ModifiersItem` edge in the connection, with data from `Modifier`. */
export type ItemModifiersItemsByModifierItemIdAndParentModifiersItemIdManyToManyEdgeModifiersByParentModifiersItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModifiersOrderBy>>;
  condition?: Maybe<ModifierCondition>;
  filter?: Maybe<ModifierFilter>;
};

/** A connection to a list of `LocationsSession` values, with data from `SessionsItem`. */
export type ItemLocationsSessionsBySessionsItemItemIdAndSessionIdManyToManyConnection = {
  __typename?: 'ItemLocationsSessionsBySessionsItemItemIdAndSessionIdManyToManyConnection';
  /** A list of `LocationsSession` objects. */
  nodes: Array<LocationsSession>;
  /** A list of edges which contains the `LocationsSession`, info from the `SessionsItem`, and the cursor to aid in pagination. */
  edges: Array<ItemLocationsSessionsBySessionsItemItemIdAndSessionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LocationsSession` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LocationsSession` edge in the connection, with data from `SessionsItem`. */
export type ItemLocationsSessionsBySessionsItemItemIdAndSessionIdManyToManyEdge = {
  __typename?: 'ItemLocationsSessionsBySessionsItemItemIdAndSessionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LocationsSession` at the end of the edge. */
  node: LocationsSession;
  /** Reads and enables pagination through a set of `SessionsItem`. */
  sessionsItemsBySessionId: SessionsItemsConnection;
};


/** A `LocationsSession` edge in the connection, with data from `SessionsItem`. */
export type ItemLocationsSessionsBySessionsItemItemIdAndSessionIdManyToManyEdgeSessionsItemsBySessionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SessionsItemsOrderBy>>;
  condition?: Maybe<SessionsItemCondition>;
  filter?: Maybe<SessionsItemFilter>;
};

/** Methods to use when ordering `LocationsSession`. */
export enum LocationsSessionsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LocationIdAsc = 'LOCATION_ID_ASC',
  LocationIdDesc = 'LOCATION_ID_DESC',
  LocationTableIdAsc = 'LOCATION_TABLE_ID_ASC',
  LocationTableIdDesc = 'LOCATION_TABLE_ID_DESC',
  LocationTableCodeAsc = 'LOCATION_TABLE_CODE_ASC',
  LocationTableCodeDesc = 'LOCATION_TABLE_CODE_DESC',
  OwnerIdAsc = 'OWNER_ID_ASC',
  OwnerIdDesc = 'OWNER_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `LocationsSession` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LocationsSessionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `locationId` field. */
  locationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `locationTableId` field. */
  locationTableId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `locationTableCode` field. */
  locationTableCode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `ownerId` field. */
  ownerId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `User` values, with data from `SessionsItem`. */
export type ItemUsersBySessionsItemItemIdAndUserIdManyToManyConnection = {
  __typename?: 'ItemUsersBySessionsItemItemIdAndUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `SessionsItem`, and the cursor to aid in pagination. */
  edges: Array<ItemUsersBySessionsItemItemIdAndUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `SessionsItem`. */
export type ItemUsersBySessionsItemItemIdAndUserIdManyToManyEdge = {
  __typename?: 'ItemUsersBySessionsItemItemIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `SessionsItem`. */
  sessionsItemsByUserId: SessionsItemsConnection;
};


/** A `User` edge in the connection, with data from `SessionsItem`. */
export type ItemUsersBySessionsItemItemIdAndUserIdManyToManyEdgeSessionsItemsByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SessionsItemsOrderBy>>;
  condition?: Maybe<SessionsItemCondition>;
  filter?: Maybe<SessionsItemFilter>;
};

/** A `SectionsItem` edge in the connection. */
export type SectionsItemsEdge = {
  __typename?: 'SectionsItemsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SectionsItem` at the end of the edge. */
  node: SectionsItem;
};

/** A connection to a list of `Location` values, with data from `SectionsItem`. */
export type MenuSectionLocationsBySectionsItemMenuSectionIdAndLocationIdManyToManyConnection = {
  __typename?: 'MenuSectionLocationsBySectionsItemMenuSectionIdAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `SectionsItem`, and the cursor to aid in pagination. */
  edges: Array<MenuSectionLocationsBySectionsItemMenuSectionIdAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `SectionsItem`. */
export type MenuSectionLocationsBySectionsItemMenuSectionIdAndLocationIdManyToManyEdge = {
  __typename?: 'MenuSectionLocationsBySectionsItemMenuSectionIdAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `SectionsItem`. */
  sectionsItemsByLocationId: SectionsItemsConnection;
};


/** A `Location` edge in the connection, with data from `SectionsItem`. */
export type MenuSectionLocationsBySectionsItemMenuSectionIdAndLocationIdManyToManyEdgeSectionsItemsByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SectionsItemsOrderBy>>;
  condition?: Maybe<SectionsItemCondition>;
  filter?: Maybe<SectionsItemFilter>;
};

/** A connection to a list of `Item` values, with data from `SectionsItem`. */
export type MenuSectionItemsBySectionsItemMenuSectionIdAndItemIdManyToManyConnection = {
  __typename?: 'MenuSectionItemsBySectionsItemMenuSectionIdAndItemIdManyToManyConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item`, info from the `SectionsItem`, and the cursor to aid in pagination. */
  edges: Array<MenuSectionItemsBySectionsItemMenuSectionIdAndItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection, with data from `SectionsItem`. */
export type MenuSectionItemsBySectionsItemMenuSectionIdAndItemIdManyToManyEdge = {
  __typename?: 'MenuSectionItemsBySectionsItemMenuSectionIdAndItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
  /** Reads and enables pagination through a set of `SectionsItem`. */
  sectionsItemsByItemId: SectionsItemsConnection;
};


/** A `Item` edge in the connection, with data from `SectionsItem`. */
export type MenuSectionItemsBySectionsItemMenuSectionIdAndItemIdManyToManyEdgeSectionsItemsByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SectionsItemsOrderBy>>;
  condition?: Maybe<SectionsItemCondition>;
  filter?: Maybe<SectionsItemFilter>;
};

/** A `MenuSection` edge in the connection. */
export type MenuSectionsEdge = {
  __typename?: 'MenuSectionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MenuSection` at the end of the edge. */
  node: MenuSection;
};

/** A connection to a list of `Location` values, with data from `MenuSection`. */
export type MenuLocationsByMenuSectionMenuIdAndLocationIdManyToManyConnection = {
  __typename?: 'MenuLocationsByMenuSectionMenuIdAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `MenuSection`, and the cursor to aid in pagination. */
  edges: Array<MenuLocationsByMenuSectionMenuIdAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `MenuSection`. */
export type MenuLocationsByMenuSectionMenuIdAndLocationIdManyToManyEdge = {
  __typename?: 'MenuLocationsByMenuSectionMenuIdAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `MenuSection`. */
  menuSectionsByLocationId: MenuSectionsConnection;
};


/** A `Location` edge in the connection, with data from `MenuSection`. */
export type MenuLocationsByMenuSectionMenuIdAndLocationIdManyToManyEdgeMenuSectionsByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MenuSectionsOrderBy>>;
  condition?: Maybe<MenuSectionCondition>;
  filter?: Maybe<MenuSectionFilter>;
};

/** A `Menu` edge in the connection. */
export type MenusEdge = {
  __typename?: 'MenusEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Menu` at the end of the edge. */
  node: Menu;
};

/** Methods to use when ordering `Menu`. */
export enum MenusOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LocationIdAsc = 'LOCATION_ID_ASC',
  LocationIdDesc = 'LOCATION_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A condition to be used against `Menu` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type MenuCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `locationId` field. */
  locationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `Item` values. */
export type ItemsConnection = {
  __typename?: 'ItemsConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item` and cursor to aid in pagination. */
  edges: Array<ItemsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection. */
export type ItemsEdge = {
  __typename?: 'ItemsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
};

/** A connection to a list of `LocationsSession` values. */
export type LocationsSessionsConnection = {
  __typename?: 'LocationsSessionsConnection';
  /** A list of `LocationsSession` objects. */
  nodes: Array<LocationsSession>;
  /** A list of edges which contains the `LocationsSession` and cursor to aid in pagination. */
  edges: Array<LocationsSessionsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LocationsSession` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LocationsSession` edge in the connection. */
export type LocationsSessionsEdge = {
  __typename?: 'LocationsSessionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LocationsSession` at the end of the edge. */
  node: LocationsSession;
};

/** Methods to use when ordering `CloverMerchant`. */
export enum CloverMerchantsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  LocationIdAsc = 'LOCATION_ID_ASC',
  LocationIdDesc = 'LOCATION_ID_DESC',
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  TokenAsc = 'TOKEN_ASC',
  TokenDesc = 'TOKEN_DESC',
  PaymentKeyAsc = 'PAYMENT_KEY_ASC',
  PaymentKeyDesc = 'PAYMENT_KEY_DESC',
  ErrorAsc = 'ERROR_ASC',
  ErrorDesc = 'ERROR_DESC',
  SyncingItemIdsAsc = 'SYNCING_ITEM_IDS_ASC',
  SyncingItemIdsDesc = 'SYNCING_ITEM_IDS_DESC',
  SyncingModifiersIdsAsc = 'SYNCING_MODIFIERS_IDS_ASC',
  SyncingModifiersIdsDesc = 'SYNCING_MODIFIERS_IDS_DESC',
  IsSyncedMerchantAsc = 'IS_SYNCED_MERCHANT_ASC',
  IsSyncedMerchantDesc = 'IS_SYNCED_MERCHANT_DESC',
  IsSyncingMerchantAsc = 'IS_SYNCING_MERCHANT_ASC',
  IsSyncingMerchantDesc = 'IS_SYNCING_MERCHANT_DESC',
  IsSyncedInventoryItemsAsc = 'IS_SYNCED_INVENTORY_ITEMS_ASC',
  IsSyncedInventoryItemsDesc = 'IS_SYNCED_INVENTORY_ITEMS_DESC',
  IsSyncingInventoryItemsAsc = 'IS_SYNCING_INVENTORY_ITEMS_ASC',
  IsSyncingInventoryItemsDesc = 'IS_SYNCING_INVENTORY_ITEMS_DESC',
  IsSyncedModifiersAsc = 'IS_SYNCED_MODIFIERS_ASC',
  IsSyncedModifiersDesc = 'IS_SYNCED_MODIFIERS_DESC',
  IsSyncingModifiersAsc = 'IS_SYNCING_MODIFIERS_ASC',
  IsSyncingModifiersDesc = 'IS_SYNCING_MODIFIERS_DESC',
  IsSyncedOrderTypesAsc = 'IS_SYNCED_ORDER_TYPES_ASC',
  IsSyncedOrderTypesDesc = 'IS_SYNCED_ORDER_TYPES_DESC',
  IsSyncingOrderTypesAsc = 'IS_SYNCING_ORDER_TYPES_ASC',
  IsSyncingOrderTypesDesc = 'IS_SYNCING_ORDER_TYPES_DESC',
  IsSyncedPaymentKeyAsc = 'IS_SYNCED_PAYMENT_KEY_ASC',
  IsSyncedPaymentKeyDesc = 'IS_SYNCED_PAYMENT_KEY_DESC',
  IsSyncingPaymentKeyAsc = 'IS_SYNCING_PAYMENT_KEY_ASC',
  IsSyncingPaymentKeyDesc = 'IS_SYNCING_PAYMENT_KEY_DESC',
  IsInitializedAsc = 'IS_INITIALIZED_ASC',
  IsInitializedDesc = 'IS_INITIALIZED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `CloverMerchant` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CloverMerchantCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `locationId` field. */
  locationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `code` field. */
  code?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `token` field. */
  token?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `paymentKey` field. */
  paymentKey?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `error` field. */
  error?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `syncingItemIds` field. */
  syncingItemIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `syncingModifiersIds` field. */
  syncingModifiersIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `isSyncedMerchant` field. */
  isSyncedMerchant?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isSyncingMerchant` field. */
  isSyncingMerchant?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isSyncedInventoryItems` field. */
  isSyncedInventoryItems?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isSyncingInventoryItems` field. */
  isSyncingInventoryItems?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isSyncedModifiers` field. */
  isSyncedModifiers?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isSyncingModifiers` field. */
  isSyncingModifiers?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isSyncedOrderTypes` field. */
  isSyncedOrderTypes?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isSyncingOrderTypes` field. */
  isSyncingOrderTypes?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isSyncedPaymentKey` field. */
  isSyncedPaymentKey?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isSyncingPaymentKey` field. */
  isSyncingPaymentKey?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isInitialized` field. */
  isInitialized?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `Menu` values, with data from `MenuSection`. */
export type LocationMenusByMenuSectionLocationIdAndMenuIdManyToManyConnection = {
  __typename?: 'LocationMenusByMenuSectionLocationIdAndMenuIdManyToManyConnection';
  /** A list of `Menu` objects. */
  nodes: Array<Menu>;
  /** A list of edges which contains the `Menu`, info from the `MenuSection`, and the cursor to aid in pagination. */
  edges: Array<LocationMenusByMenuSectionLocationIdAndMenuIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Menu` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Menu` edge in the connection, with data from `MenuSection`. */
export type LocationMenusByMenuSectionLocationIdAndMenuIdManyToManyEdge = {
  __typename?: 'LocationMenusByMenuSectionLocationIdAndMenuIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Menu` at the end of the edge. */
  node: Menu;
  /** Reads and enables pagination through a set of `MenuSection`. */
  menuSectionsByMenuId: MenuSectionsConnection;
};


/** A `Menu` edge in the connection, with data from `MenuSection`. */
export type LocationMenusByMenuSectionLocationIdAndMenuIdManyToManyEdgeMenuSectionsByMenuIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MenuSectionsOrderBy>>;
  condition?: Maybe<MenuSectionCondition>;
  filter?: Maybe<MenuSectionFilter>;
};

/** A connection to a list of `Item` values, with data from `SectionsItem`. */
export type LocationItemsBySectionsItemLocationIdAndItemIdManyToManyConnection = {
  __typename?: 'LocationItemsBySectionsItemLocationIdAndItemIdManyToManyConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item`, info from the `SectionsItem`, and the cursor to aid in pagination. */
  edges: Array<LocationItemsBySectionsItemLocationIdAndItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection, with data from `SectionsItem`. */
export type LocationItemsBySectionsItemLocationIdAndItemIdManyToManyEdge = {
  __typename?: 'LocationItemsBySectionsItemLocationIdAndItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
  /** Reads and enables pagination through a set of `SectionsItem`. */
  sectionsItemsByItemId: SectionsItemsConnection;
};


/** A `Item` edge in the connection, with data from `SectionsItem`. */
export type LocationItemsBySectionsItemLocationIdAndItemIdManyToManyEdgeSectionsItemsByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SectionsItemsOrderBy>>;
  condition?: Maybe<SectionsItemCondition>;
  filter?: Maybe<SectionsItemFilter>;
};

/** A connection to a list of `MenuSection` values, with data from `SectionsItem`. */
export type LocationMenuSectionsBySectionsItemLocationIdAndMenuSectionIdManyToManyConnection = {
  __typename?: 'LocationMenuSectionsBySectionsItemLocationIdAndMenuSectionIdManyToManyConnection';
  /** A list of `MenuSection` objects. */
  nodes: Array<MenuSection>;
  /** A list of edges which contains the `MenuSection`, info from the `SectionsItem`, and the cursor to aid in pagination. */
  edges: Array<LocationMenuSectionsBySectionsItemLocationIdAndMenuSectionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MenuSection` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `MenuSection` edge in the connection, with data from `SectionsItem`. */
export type LocationMenuSectionsBySectionsItemLocationIdAndMenuSectionIdManyToManyEdge = {
  __typename?: 'LocationMenuSectionsBySectionsItemLocationIdAndMenuSectionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MenuSection` at the end of the edge. */
  node: MenuSection;
  /** Reads and enables pagination through a set of `SectionsItem`. */
  sectionsItemsByMenuSectionId: SectionsItemsConnection;
};


/** A `MenuSection` edge in the connection, with data from `SectionsItem`. */
export type LocationMenuSectionsBySectionsItemLocationIdAndMenuSectionIdManyToManyEdgeSectionsItemsByMenuSectionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SectionsItemsOrderBy>>;
  condition?: Maybe<SectionsItemCondition>;
  filter?: Maybe<SectionsItemFilter>;
};

/** A connection to a list of `User` values, with data from `LocationsSession`. */
export type LocationUsersByLocationsSessionLocationIdAndOwnerIdManyToManyConnection = {
  __typename?: 'LocationUsersByLocationsSessionLocationIdAndOwnerIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `LocationsSession`, and the cursor to aid in pagination. */
  edges: Array<LocationUsersByLocationsSessionLocationIdAndOwnerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `LocationsSession`. */
export type LocationUsersByLocationsSessionLocationIdAndOwnerIdManyToManyEdge = {
  __typename?: 'LocationUsersByLocationsSessionLocationIdAndOwnerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `LocationsSession`. */
  locationsSessionsByOwnerId: LocationsSessionsConnection;
};


/** A `User` edge in the connection, with data from `LocationsSession`. */
export type LocationUsersByLocationsSessionLocationIdAndOwnerIdManyToManyEdgeLocationsSessionsByOwnerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsSessionsOrderBy>>;
  condition?: Maybe<LocationsSessionCondition>;
  filter?: Maybe<LocationsSessionFilter>;
};

/** A connection to a list of `User` values, with data from `CloverMerchant`. */
export type LocationUsersByCloverMerchantLocationIdAndUserIdManyToManyConnection = {
  __typename?: 'LocationUsersByCloverMerchantLocationIdAndUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `CloverMerchant`, and the cursor to aid in pagination. */
  edges: Array<LocationUsersByCloverMerchantLocationIdAndUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `CloverMerchant`. */
export type LocationUsersByCloverMerchantLocationIdAndUserIdManyToManyEdge = {
  __typename?: 'LocationUsersByCloverMerchantLocationIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `CloverMerchant`. */
  cloverMerchantsByUserId: CloverMerchantsConnection;
};


/** A `User` edge in the connection, with data from `CloverMerchant`. */
export type LocationUsersByCloverMerchantLocationIdAndUserIdManyToManyEdgeCloverMerchantsByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CloverMerchantsOrderBy>>;
  condition?: Maybe<CloverMerchantCondition>;
  filter?: Maybe<CloverMerchantFilter>;
};

/** A `Location` edge in the connection. */
export type LocationsEdge = {
  __typename?: 'LocationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
};

/** A connection to a list of `Location` values, with data from `LocationsSession`. */
export type UserLocationsByLocationsSessionOwnerIdAndLocationIdManyToManyConnection = {
  __typename?: 'UserLocationsByLocationsSessionOwnerIdAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `LocationsSession`, and the cursor to aid in pagination. */
  edges: Array<UserLocationsByLocationsSessionOwnerIdAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `LocationsSession`. */
export type UserLocationsByLocationsSessionOwnerIdAndLocationIdManyToManyEdge = {
  __typename?: 'UserLocationsByLocationsSessionOwnerIdAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `LocationsSession`. */
  locationsSessionsByLocationId: LocationsSessionsConnection;
};


/** A `Location` edge in the connection, with data from `LocationsSession`. */
export type UserLocationsByLocationsSessionOwnerIdAndLocationIdManyToManyEdgeLocationsSessionsByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsSessionsOrderBy>>;
  condition?: Maybe<LocationsSessionCondition>;
  filter?: Maybe<LocationsSessionFilter>;
};

/** A connection to a list of `LocationsSession` values, with data from `SessionsUser`. */
export type UserLocationsSessionsBySessionsUserUserIdAndSessionIdManyToManyConnection = {
  __typename?: 'UserLocationsSessionsBySessionsUserUserIdAndSessionIdManyToManyConnection';
  /** A list of `LocationsSession` objects. */
  nodes: Array<LocationsSession>;
  /** A list of edges which contains the `LocationsSession`, info from the `SessionsUser`, and the cursor to aid in pagination. */
  edges: Array<UserLocationsSessionsBySessionsUserUserIdAndSessionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LocationsSession` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LocationsSession` edge in the connection, with data from `SessionsUser`. */
export type UserLocationsSessionsBySessionsUserUserIdAndSessionIdManyToManyEdge = {
  __typename?: 'UserLocationsSessionsBySessionsUserUserIdAndSessionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LocationsSession` at the end of the edge. */
  node: LocationsSession;
  id: Scalars['Int'];
  isOrderPlacing: Scalars['Boolean'];
  isOrderPaying: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A connection to a list of `LocationsSession` values, with data from `SessionsItem`. */
export type UserLocationsSessionsBySessionsItemUserIdAndSessionIdManyToManyConnection = {
  __typename?: 'UserLocationsSessionsBySessionsItemUserIdAndSessionIdManyToManyConnection';
  /** A list of `LocationsSession` objects. */
  nodes: Array<LocationsSession>;
  /** A list of edges which contains the `LocationsSession`, info from the `SessionsItem`, and the cursor to aid in pagination. */
  edges: Array<UserLocationsSessionsBySessionsItemUserIdAndSessionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LocationsSession` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LocationsSession` edge in the connection, with data from `SessionsItem`. */
export type UserLocationsSessionsBySessionsItemUserIdAndSessionIdManyToManyEdge = {
  __typename?: 'UserLocationsSessionsBySessionsItemUserIdAndSessionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LocationsSession` at the end of the edge. */
  node: LocationsSession;
  /** Reads and enables pagination through a set of `SessionsItem`. */
  sessionsItemsBySessionId: SessionsItemsConnection;
};


/** A `LocationsSession` edge in the connection, with data from `SessionsItem`. */
export type UserLocationsSessionsBySessionsItemUserIdAndSessionIdManyToManyEdgeSessionsItemsBySessionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SessionsItemsOrderBy>>;
  condition?: Maybe<SessionsItemCondition>;
  filter?: Maybe<SessionsItemFilter>;
};

/** A connection to a list of `Item` values, with data from `SessionsItem`. */
export type UserItemsBySessionsItemUserIdAndItemIdManyToManyConnection = {
  __typename?: 'UserItemsBySessionsItemUserIdAndItemIdManyToManyConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item`, info from the `SessionsItem`, and the cursor to aid in pagination. */
  edges: Array<UserItemsBySessionsItemUserIdAndItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection, with data from `SessionsItem`. */
export type UserItemsBySessionsItemUserIdAndItemIdManyToManyEdge = {
  __typename?: 'UserItemsBySessionsItemUserIdAndItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
  /** Reads and enables pagination through a set of `SessionsItem`. */
  sessionsItemsByItemId: SessionsItemsConnection;
};


/** A `Item` edge in the connection, with data from `SessionsItem`. */
export type UserItemsBySessionsItemUserIdAndItemIdManyToManyEdgeSessionsItemsByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SessionsItemsOrderBy>>;
  condition?: Maybe<SessionsItemCondition>;
  filter?: Maybe<SessionsItemFilter>;
};

/** A connection to a list of `SessionsItem` values, with data from `SelectedModifiersGroup`. */
export type UserSessionsItemsBySelectedModifiersGroupUserIdAndSessionsItemIdManyToManyConnection = {
  __typename?: 'UserSessionsItemsBySelectedModifiersGroupUserIdAndSessionsItemIdManyToManyConnection';
  /** A list of `SessionsItem` objects. */
  nodes: Array<SessionsItem>;
  /** A list of edges which contains the `SessionsItem`, info from the `SelectedModifiersGroup`, and the cursor to aid in pagination. */
  edges: Array<UserSessionsItemsBySelectedModifiersGroupUserIdAndSessionsItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SessionsItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SessionsItem` edge in the connection, with data from `SelectedModifiersGroup`. */
export type UserSessionsItemsBySelectedModifiersGroupUserIdAndSessionsItemIdManyToManyEdge = {
  __typename?: 'UserSessionsItemsBySelectedModifiersGroupUserIdAndSessionsItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SessionsItem` at the end of the edge. */
  node: SessionsItem;
  /** Reads and enables pagination through a set of `SelectedModifiersGroup`. */
  selectedModifiersGroupsBySessionsItemId: SelectedModifiersGroupsConnection;
};


/** A `SessionsItem` edge in the connection, with data from `SelectedModifiersGroup`. */
export type UserSessionsItemsBySelectedModifiersGroupUserIdAndSessionsItemIdManyToManyEdgeSelectedModifiersGroupsBySessionsItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersGroupsOrderBy>>;
  condition?: Maybe<SelectedModifiersGroupCondition>;
  filter?: Maybe<SelectedModifiersGroupFilter>;
};

/** A connection to a list of `ModifiersGroup` values, with data from `SelectedModifiersGroup`. */
export type UserModifiersGroupsBySelectedModifiersGroupUserIdAndModifiersGroupIdManyToManyConnection = {
  __typename?: 'UserModifiersGroupsBySelectedModifiersGroupUserIdAndModifiersGroupIdManyToManyConnection';
  /** A list of `ModifiersGroup` objects. */
  nodes: Array<ModifiersGroup>;
  /** A list of edges which contains the `ModifiersGroup`, info from the `SelectedModifiersGroup`, and the cursor to aid in pagination. */
  edges: Array<UserModifiersGroupsBySelectedModifiersGroupUserIdAndModifiersGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ModifiersGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ModifiersGroup` edge in the connection, with data from `SelectedModifiersGroup`. */
export type UserModifiersGroupsBySelectedModifiersGroupUserIdAndModifiersGroupIdManyToManyEdge = {
  __typename?: 'UserModifiersGroupsBySelectedModifiersGroupUserIdAndModifiersGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ModifiersGroup` at the end of the edge. */
  node: ModifiersGroup;
  /** Reads and enables pagination through a set of `SelectedModifiersGroup`. */
  selectedModifiersGroupsByModifiersGroupId: SelectedModifiersGroupsConnection;
};


/** A `ModifiersGroup` edge in the connection, with data from `SelectedModifiersGroup`. */
export type UserModifiersGroupsBySelectedModifiersGroupUserIdAndModifiersGroupIdManyToManyEdgeSelectedModifiersGroupsByModifiersGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersGroupsOrderBy>>;
  condition?: Maybe<SelectedModifiersGroupCondition>;
  filter?: Maybe<SelectedModifiersGroupFilter>;
};

/** A connection to a list of `SessionsItem` values, with data from `SelectedModifiersItem`. */
export type UserSessionsItemsBySelectedModifiersItemUserIdAndSessionsItemIdManyToManyConnection = {
  __typename?: 'UserSessionsItemsBySelectedModifiersItemUserIdAndSessionsItemIdManyToManyConnection';
  /** A list of `SessionsItem` objects. */
  nodes: Array<SessionsItem>;
  /** A list of edges which contains the `SessionsItem`, info from the `SelectedModifiersItem`, and the cursor to aid in pagination. */
  edges: Array<UserSessionsItemsBySelectedModifiersItemUserIdAndSessionsItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SessionsItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SessionsItem` edge in the connection, with data from `SelectedModifiersItem`. */
export type UserSessionsItemsBySelectedModifiersItemUserIdAndSessionsItemIdManyToManyEdge = {
  __typename?: 'UserSessionsItemsBySelectedModifiersItemUserIdAndSessionsItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SessionsItem` at the end of the edge. */
  node: SessionsItem;
  /** Reads and enables pagination through a set of `SelectedModifiersItem`. */
  selectedModifiersItemsBySessionsItemId: SelectedModifiersItemsConnection;
};


/** A `SessionsItem` edge in the connection, with data from `SelectedModifiersItem`. */
export type UserSessionsItemsBySelectedModifiersItemUserIdAndSessionsItemIdManyToManyEdgeSelectedModifiersItemsBySessionsItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersItemsOrderBy>>;
  condition?: Maybe<SelectedModifiersItemCondition>;
  filter?: Maybe<SelectedModifiersItemFilter>;
};

/** A connection to a list of `SelectedModifiersGroup` values, with data from `SelectedModifiersItem`. */
export type UserSelectedModifiersGroupsBySelectedModifiersItemUserIdAndSelectedModifiersGroupIdManyToManyConnection = {
  __typename?: 'UserSelectedModifiersGroupsBySelectedModifiersItemUserIdAndSelectedModifiersGroupIdManyToManyConnection';
  /** A list of `SelectedModifiersGroup` objects. */
  nodes: Array<SelectedModifiersGroup>;
  /** A list of edges which contains the `SelectedModifiersGroup`, info from the `SelectedModifiersItem`, and the cursor to aid in pagination. */
  edges: Array<UserSelectedModifiersGroupsBySelectedModifiersItemUserIdAndSelectedModifiersGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SelectedModifiersGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SelectedModifiersGroup` edge in the connection, with data from `SelectedModifiersItem`. */
export type UserSelectedModifiersGroupsBySelectedModifiersItemUserIdAndSelectedModifiersGroupIdManyToManyEdge = {
  __typename?: 'UserSelectedModifiersGroupsBySelectedModifiersItemUserIdAndSelectedModifiersGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SelectedModifiersGroup` at the end of the edge. */
  node: SelectedModifiersGroup;
  /** Reads and enables pagination through a set of `SelectedModifiersItem`. */
  selectedModifiersItemsBySelectedModifiersGroupId: SelectedModifiersItemsConnection;
};


/** A `SelectedModifiersGroup` edge in the connection, with data from `SelectedModifiersItem`. */
export type UserSelectedModifiersGroupsBySelectedModifiersItemUserIdAndSelectedModifiersGroupIdManyToManyEdgeSelectedModifiersItemsBySelectedModifiersGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersItemsOrderBy>>;
  condition?: Maybe<SelectedModifiersItemCondition>;
  filter?: Maybe<SelectedModifiersItemFilter>;
};

/** A connection to a list of `ModifiersItem` values, with data from `SelectedModifiersItem`. */
export type UserModifiersItemsBySelectedModifiersItemUserIdAndModifiersItemIdManyToManyConnection = {
  __typename?: 'UserModifiersItemsBySelectedModifiersItemUserIdAndModifiersItemIdManyToManyConnection';
  /** A list of `ModifiersItem` objects. */
  nodes: Array<ModifiersItem>;
  /** A list of edges which contains the `ModifiersItem`, info from the `SelectedModifiersItem`, and the cursor to aid in pagination. */
  edges: Array<UserModifiersItemsBySelectedModifiersItemUserIdAndModifiersItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ModifiersItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ModifiersItem` edge in the connection, with data from `SelectedModifiersItem`. */
export type UserModifiersItemsBySelectedModifiersItemUserIdAndModifiersItemIdManyToManyEdge = {
  __typename?: 'UserModifiersItemsBySelectedModifiersItemUserIdAndModifiersItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ModifiersItem` at the end of the edge. */
  node: ModifiersItem;
  /** Reads and enables pagination through a set of `SelectedModifiersItem`. */
  selectedModifiersItemsByModifiersItemId: SelectedModifiersItemsConnection;
};


/** A `ModifiersItem` edge in the connection, with data from `SelectedModifiersItem`. */
export type UserModifiersItemsBySelectedModifiersItemUserIdAndModifiersItemIdManyToManyEdgeSelectedModifiersItemsByModifiersItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelectedModifiersItemsOrderBy>>;
  condition?: Maybe<SelectedModifiersItemCondition>;
  filter?: Maybe<SelectedModifiersItemFilter>;
};

/** A connection to a list of `Location` values, with data from `CloverMerchant`. */
export type UserLocationsByCloverMerchantUserIdAndLocationIdManyToManyConnection = {
  __typename?: 'UserLocationsByCloverMerchantUserIdAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `CloverMerchant`, and the cursor to aid in pagination. */
  edges: Array<UserLocationsByCloverMerchantUserIdAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `CloverMerchant`. */
export type UserLocationsByCloverMerchantUserIdAndLocationIdManyToManyEdge = {
  __typename?: 'UserLocationsByCloverMerchantUserIdAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `CloverMerchant`. */
  cloverMerchantsByLocationId: CloverMerchantsConnection;
};


/** A `Location` edge in the connection, with data from `CloverMerchant`. */
export type UserLocationsByCloverMerchantUserIdAndLocationIdManyToManyEdgeCloverMerchantsByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CloverMerchantsOrderBy>>;
  condition?: Maybe<CloverMerchantCondition>;
  filter?: Maybe<CloverMerchantFilter>;
};

/** A `CloverMerchant` edge in the connection. */
export type CloverMerchantsEdge = {
  __typename?: 'CloverMerchantsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CloverMerchant` at the end of the edge. */
  node: CloverMerchant;
};

/** A connection to a list of `ModifiersGroup` values. */
export type ModifiersGroupsConnection = {
  __typename?: 'ModifiersGroupsConnection';
  /** A list of `ModifiersGroup` objects. */
  nodes: Array<ModifiersGroup>;
  /** A list of edges which contains the `ModifiersGroup` and cursor to aid in pagination. */
  edges: Array<ModifiersGroupsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ModifiersGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ModifiersGroup` edge in the connection. */
export type ModifiersGroupsEdge = {
  __typename?: 'ModifiersGroupsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ModifiersGroup` at the end of the edge. */
  node: ModifiersGroup;
};

/** A connection to a list of `User` values. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UsersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  /** Creates a single `LocationTable`. */
  createLocationTable?: Maybe<CreateLocationTablePayload>;
  /** Creates a single `SelectedModifiersGroup`. */
  createSelectedModifiersGroup?: Maybe<CreateSelectedModifiersGroupPayload>;
  /** Creates a single `SelectedModifiersItem`. */
  createSelectedModifiersItem?: Maybe<CreateSelectedModifiersItemPayload>;
  /** Creates a single `SessionsItem`. */
  createSessionsItem?: Maybe<CreateSessionsItemPayload>;
  /** Creates a single `UserEmail`. */
  createUserEmail?: Maybe<CreateUserEmailPayload>;
  /** Updates a single `LocationTable` using its globally unique id and a patch. */
  updateLocationTable?: Maybe<UpdateLocationTablePayload>;
  /** Updates a single `LocationTable` using a unique key and a patch. */
  updateLocationTableById?: Maybe<UpdateLocationTablePayload>;
  /** Updates a single `SessionsItem` using its globally unique id and a patch. */
  updateSessionsItem?: Maybe<UpdateSessionsItemPayload>;
  /** Updates a single `SessionsItem` using a unique key and a patch. */
  updateSessionsItemById?: Maybe<UpdateSessionsItemPayload>;
  /** Updates a single `User` using its globally unique id and a patch. */
  updateUser?: Maybe<UpdateUserPayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUserById?: Maybe<UpdateUserPayload>;
  /** Deletes a single `LocationTable` using its globally unique id. */
  deleteLocationTable?: Maybe<DeleteLocationTablePayload>;
  /** Deletes a single `LocationTable` using a unique key. */
  deleteLocationTableById?: Maybe<DeleteLocationTablePayload>;
  /** Deletes a single `UserEmail` using its globally unique id. */
  deleteUserEmail?: Maybe<DeleteUserEmailPayload>;
  /** Deletes a single `UserEmail` using a unique key. */
  deleteUserEmailById?: Maybe<DeleteUserEmailPayload>;
  /** Deletes a single `UserEmail` using a unique key. */
  deleteUserEmailByUserIdAndEmail?: Maybe<DeleteUserEmailPayload>;
  addLocationTable?: Maybe<AddLocationTablePayload>;
  addSessionItem?: Maybe<AddSessionItemPayload>;
  bindItemToGroup?: Maybe<BindItemToGroupPayload>;
  joinSession?: Maybe<JoinSessionPayload>;
  leaveSession?: Maybe<LeaveSessionPayload>;
  placeOrder?: Maybe<PlaceOrderPayload>;
  regenerateTableCode?: Maybe<RegenerateTableCodePayload>;
  removeLocationTable?: Maybe<RemoveLocationTablePayload>;
  startSession?: Maybe<StartSessionPayload>;
  /** Once you have received a verification token for your email, you may call this mutation with that token to make your email verified. */
  verifyEmail?: Maybe<VerifyEmailPayload>;
  sessionPay?: Maybe<SessionPayPayload>;
  /** Use this mutation to create an merchant account for access to dashboard */
  registerMerchant?: Maybe<RegisterMerchantPayload>;
  /** Use this mutation to create an merchant account for access to dashboard */
  registerCustomer?: Maybe<RegisterCustomerPayload>;
  /** Use this mutation to create an merchant account for access to dashboard */
  registerCustomerQuick?: Maybe<RegisterCustomerQuickPayload>;
  /** Use this mutation to log in to account by emeail and password */
  loginByEmailAndPassword?: Maybe<LoginByEmailAndPasswordPayload>;
  /** Use this mutation to auto log in by refreshToken */
  loginByRefreshToken?: Maybe<LoginByRefreshTokenPayload>;
  /** Use this mutation to logout from your account */
  logout?: Maybe<LogoutPayload>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLocationTableArgs = {
  input: CreateLocationTableInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSelectedModifiersGroupArgs = {
  input: CreateSelectedModifiersGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSelectedModifiersItemArgs = {
  input: CreateSelectedModifiersItemInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSessionsItemArgs = {
  input: CreateSessionsItemInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserEmailArgs = {
  input: CreateUserEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLocationTableArgs = {
  input: UpdateLocationTableInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLocationTableByIdArgs = {
  input: UpdateLocationTableByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSessionsItemArgs = {
  input: UpdateSessionsItemInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSessionsItemByIdArgs = {
  input: UpdateSessionsItemByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserByIdArgs = {
  input: UpdateUserByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLocationTableArgs = {
  input: DeleteLocationTableInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLocationTableByIdArgs = {
  input: DeleteLocationTableByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserEmailArgs = {
  input: DeleteUserEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserEmailByIdArgs = {
  input: DeleteUserEmailByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserEmailByUserIdAndEmailArgs = {
  input: DeleteUserEmailByUserIdAndEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddLocationTableArgs = {
  input: AddLocationTableInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddSessionItemArgs = {
  input: AddSessionItemInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationBindItemToGroupArgs = {
  input: BindItemToGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationJoinSessionArgs = {
  input: JoinSessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationLeaveSessionArgs = {
  input: LeaveSessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationPlaceOrderArgs = {
  input: PlaceOrderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegenerateTableCodeArgs = {
  input: RegenerateTableCodeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemoveLocationTableArgs = {
  input: RemoveLocationTableInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationStartSessionArgs = {
  input: StartSessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationVerifyEmailArgs = {
  input: VerifyEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSessionPayArgs = {
  input: SessionPayInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegisterMerchantArgs = {
  input: RegisterMerchantInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegisterCustomerArgs = {
  input: RegisterCustomerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationLoginByEmailAndPasswordArgs = {
  input: LoginByEmailAndPasswordInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationLoginByRefreshTokenArgs = {
  input?: Maybe<LoginByRefreshTokenInput>;
};

/** The output of our create `LocationTable` mutation. */
export type CreateLocationTablePayload = {
  __typename?: 'CreateLocationTablePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LocationTable` that was created by this mutation. */
  locationTable?: Maybe<LocationTable>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Location` that is related to this `LocationTable`. */
  locationByLocationId?: Maybe<Location>;
  /** An edge for our `LocationTable`. May be used by Relay 1. */
  locationTableEdge?: Maybe<LocationTablesEdge>;
};


/** The output of our create `LocationTable` mutation. */
export type CreateLocationTablePayloadLocationTableEdgeArgs = {
  orderBy?: Maybe<Array<LocationTablesOrderBy>>;
};

/** All input for the create `LocationTable` mutation. */
export type CreateLocationTableInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LocationTable` to be created by this mutation. */
  locationTable: LocationTableInput;
};

/** An input for mutations affecting `LocationTable` */
export type LocationTableInput = {
  locationId?: Maybe<Scalars['Int']>;
  tableNumber: Scalars['Int'];
  code?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

/** The output of our create `SelectedModifiersGroup` mutation. */
export type CreateSelectedModifiersGroupPayload = {
  __typename?: 'CreateSelectedModifiersGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SelectedModifiersGroup` that was created by this mutation. */
  selectedModifiersGroup?: Maybe<SelectedModifiersGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `SelectedModifiersGroup`. */
  userByUserId?: Maybe<User>;
  /** Reads a single `SessionsItem` that is related to this `SelectedModifiersGroup`. */
  sessionsItemBySessionsItemId?: Maybe<SessionsItem>;
  /** Reads a single `ModifiersGroup` that is related to this `SelectedModifiersGroup`. */
  modifiersGroupByModifiersGroupId?: Maybe<ModifiersGroup>;
  /** An edge for our `SelectedModifiersGroup`. May be used by Relay 1. */
  selectedModifiersGroupEdge?: Maybe<SelectedModifiersGroupsEdge>;
};


/** The output of our create `SelectedModifiersGroup` mutation. */
export type CreateSelectedModifiersGroupPayloadSelectedModifiersGroupEdgeArgs = {
  orderBy?: Maybe<Array<SelectedModifiersGroupsOrderBy>>;
};

/** All input for the create `SelectedModifiersGroup` mutation. */
export type CreateSelectedModifiersGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SelectedModifiersGroup` to be created by this mutation. */
  selectedModifiersGroup: SelectedModifiersGroupInput;
};

/** An input for mutations affecting `SelectedModifiersGroup` */
export type SelectedModifiersGroupInput = {
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['UUID']>;
  sessionsItemId: Scalars['Int'];
  modifiersGroupId: Scalars['Int'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** The output of our create `SelectedModifiersItem` mutation. */
export type CreateSelectedModifiersItemPayload = {
  __typename?: 'CreateSelectedModifiersItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SelectedModifiersItem` that was created by this mutation. */
  selectedModifiersItem?: Maybe<SelectedModifiersItem>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `SelectedModifiersItem`. */
  userByUserId?: Maybe<User>;
  /** Reads a single `SessionsItem` that is related to this `SelectedModifiersItem`. */
  sessionsItemBySessionsItemId?: Maybe<SessionsItem>;
  /** Reads a single `SelectedModifiersGroup` that is related to this `SelectedModifiersItem`. */
  selectedModifiersGroupBySelectedModifiersGroupId?: Maybe<SelectedModifiersGroup>;
  /** Reads a single `ModifiersItem` that is related to this `SelectedModifiersItem`. */
  modifiersItemByModifiersItemId?: Maybe<ModifiersItem>;
  /** An edge for our `SelectedModifiersItem`. May be used by Relay 1. */
  selectedModifiersItemEdge?: Maybe<SelectedModifiersItemsEdge>;
};


/** The output of our create `SelectedModifiersItem` mutation. */
export type CreateSelectedModifiersItemPayloadSelectedModifiersItemEdgeArgs = {
  orderBy?: Maybe<Array<SelectedModifiersItemsOrderBy>>;
};

/** All input for the create `SelectedModifiersItem` mutation. */
export type CreateSelectedModifiersItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SelectedModifiersItem` to be created by this mutation. */
  selectedModifiersItem: SelectedModifiersItemInput;
};

/** An input for mutations affecting `SelectedModifiersItem` */
export type SelectedModifiersItemInput = {
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['UUID']>;
  sessionsItemId: Scalars['Int'];
  selectedModifiersGroupId: Scalars['Int'];
  modifiersItemId: Scalars['Int'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** The output of our create `SessionsItem` mutation. */
export type CreateSessionsItemPayload = {
  __typename?: 'CreateSessionsItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SessionsItem` that was created by this mutation. */
  sessionsItem?: Maybe<SessionsItem>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LocationsSession` that is related to this `SessionsItem`. */
  locationsSessionBySessionId?: Maybe<LocationsSession>;
  /** Reads a single `User` that is related to this `SessionsItem`. */
  userByUserId?: Maybe<User>;
  /** Reads a single `Item` that is related to this `SessionsItem`. */
  itemByItemId?: Maybe<Item>;
  /** An edge for our `SessionsItem`. May be used by Relay 1. */
  sessionsItemEdge?: Maybe<SessionsItemsEdge>;
};


/** The output of our create `SessionsItem` mutation. */
export type CreateSessionsItemPayloadSessionsItemEdgeArgs = {
  orderBy?: Maybe<Array<SessionsItemsOrderBy>>;
};

/** All input for the create `SessionsItem` mutation. */
export type CreateSessionsItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SessionsItem` to be created by this mutation. */
  sessionsItem: SessionsItemInput;
};

/** An input for mutations affecting `SessionsItem` */
export type SessionsItemInput = {
  id?: Maybe<Scalars['Int']>;
  sessionId: Scalars['Int'];
  userId: Scalars['UUID'];
  itemId: Scalars['Int'];
  quantity: Scalars['Int'];
  isOrdered?: Maybe<Scalars['Boolean']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** The output of our create `UserEmail` mutation. */
export type CreateUserEmailPayload = {
  __typename?: 'CreateUserEmailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserEmail` that was created by this mutation. */
  userEmail?: Maybe<UserEmail>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserEmail`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `UserEmail`. May be used by Relay 1. */
  userEmailEdge?: Maybe<UserEmailsEdge>;
};


/** The output of our create `UserEmail` mutation. */
export type CreateUserEmailPayloadUserEmailEdgeArgs = {
  orderBy?: Maybe<Array<UserEmailsOrderBy>>;
};

/** All input for the create `UserEmail` mutation. */
export type CreateUserEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserEmail` to be created by this mutation. */
  userEmail: UserEmailInput;
};

/** An input for mutations affecting `UserEmail` */
export type UserEmailInput = {
  /** The users email address, in `a@b.c` format. */
  email: Scalars['String'];
};

/** The output of our update `LocationTable` mutation. */
export type UpdateLocationTablePayload = {
  __typename?: 'UpdateLocationTablePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LocationTable` that was updated by this mutation. */
  locationTable?: Maybe<LocationTable>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Location` that is related to this `LocationTable`. */
  locationByLocationId?: Maybe<Location>;
  /** An edge for our `LocationTable`. May be used by Relay 1. */
  locationTableEdge?: Maybe<LocationTablesEdge>;
};


/** The output of our update `LocationTable` mutation. */
export type UpdateLocationTablePayloadLocationTableEdgeArgs = {
  orderBy?: Maybe<Array<LocationTablesOrderBy>>;
};

/** All input for the `updateLocationTable` mutation. */
export type UpdateLocationTableInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LocationTable` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LocationTable` being updated. */
  locationTablePatch: LocationTablePatch;
};

/** Represents an update to a `LocationTable`. Fields that are set will be updated. */
export type LocationTablePatch = {
  tableNumber?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

/** All input for the `updateLocationTableById` mutation. */
export type UpdateLocationTableByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LocationTable` being updated. */
  locationTablePatch: LocationTablePatch;
  id: Scalars['Int'];
};

/** The output of our update `SessionsItem` mutation. */
export type UpdateSessionsItemPayload = {
  __typename?: 'UpdateSessionsItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SessionsItem` that was updated by this mutation. */
  sessionsItem?: Maybe<SessionsItem>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LocationsSession` that is related to this `SessionsItem`. */
  locationsSessionBySessionId?: Maybe<LocationsSession>;
  /** Reads a single `User` that is related to this `SessionsItem`. */
  userByUserId?: Maybe<User>;
  /** Reads a single `Item` that is related to this `SessionsItem`. */
  itemByItemId?: Maybe<Item>;
  /** An edge for our `SessionsItem`. May be used by Relay 1. */
  sessionsItemEdge?: Maybe<SessionsItemsEdge>;
};


/** The output of our update `SessionsItem` mutation. */
export type UpdateSessionsItemPayloadSessionsItemEdgeArgs = {
  orderBy?: Maybe<Array<SessionsItemsOrderBy>>;
};

/** All input for the `updateSessionsItem` mutation. */
export type UpdateSessionsItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SessionsItem` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SessionsItem` being updated. */
  sessionsItemPatch: SessionsItemPatch;
};

/** Represents an update to a `SessionsItem`. Fields that are set will be updated. */
export type SessionsItemPatch = {
  id?: Maybe<Scalars['Int']>;
  sessionId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['UUID']>;
  itemId?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  isOrdered?: Maybe<Scalars['Boolean']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** All input for the `updateSessionsItemById` mutation. */
export type UpdateSessionsItemByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `SessionsItem` being updated. */
  sessionsItemPatch: SessionsItemPatch;
  id: Scalars['Int'];
};

/** The output of our update `User` mutation. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `User` that was updated by this mutation. */
  user?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our update `User` mutation. */
export type UpdateUserPayloadUserEdgeArgs = {
  orderBy?: Maybe<Array<UsersOrderBy>>;
};

/** All input for the `updateUser` mutation. */
export type UpdateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `User` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `User` being updated. */
  userPatch: UserPatch;
};

/** Represents an update to a `User`. Fields that are set will be updated. */
export type UserPatch = {
  /** Public-facing username of the user. */
  username?: Maybe<Scalars['String']>;
};

/** All input for the `updateUserById` mutation. */
export type UpdateUserByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `User` being updated. */
  userPatch: UserPatch;
  /** Unique identifier for the user. */
  id: Scalars['UUID'];
};

/** The output of our delete `LocationTable` mutation. */
export type DeleteLocationTablePayload = {
  __typename?: 'DeleteLocationTablePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LocationTable` that was deleted by this mutation. */
  locationTable?: Maybe<LocationTable>;
  deletedLocationTableId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Location` that is related to this `LocationTable`. */
  locationByLocationId?: Maybe<Location>;
  /** An edge for our `LocationTable`. May be used by Relay 1. */
  locationTableEdge?: Maybe<LocationTablesEdge>;
};


/** The output of our delete `LocationTable` mutation. */
export type DeleteLocationTablePayloadLocationTableEdgeArgs = {
  orderBy?: Maybe<Array<LocationTablesOrderBy>>;
};

/** All input for the `deleteLocationTable` mutation. */
export type DeleteLocationTableInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LocationTable` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLocationTableById` mutation. */
export type DeleteLocationTableByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `UserEmail` mutation. */
export type DeleteUserEmailPayload = {
  __typename?: 'DeleteUserEmailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserEmail` that was deleted by this mutation. */
  userEmail?: Maybe<UserEmail>;
  deletedUserEmailId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserEmail`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `UserEmail`. May be used by Relay 1. */
  userEmailEdge?: Maybe<UserEmailsEdge>;
};


/** The output of our delete `UserEmail` mutation. */
export type DeleteUserEmailPayloadUserEmailEdgeArgs = {
  orderBy?: Maybe<Array<UserEmailsOrderBy>>;
};

/** All input for the `deleteUserEmail` mutation. */
export type DeleteUserEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserEmail` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUserEmailById` mutation. */
export type DeleteUserEmailByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteUserEmailByUserIdAndEmail` mutation. */
export type DeleteUserEmailByUserIdAndEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  userId: Scalars['UUID'];
  /** The users email address, in `a@b.c` format. */
  email: Scalars['String'];
};

/** The output of our `addLocationTable` mutation. */
export type AddLocationTablePayload = {
  __typename?: 'AddLocationTablePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  locationTable?: Maybe<LocationTable>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Location` that is related to this `LocationTable`. */
  locationByLocationId?: Maybe<Location>;
  /** An edge for our `LocationTable`. May be used by Relay 1. */
  locationTableEdge?: Maybe<LocationTablesEdge>;
};


/** The output of our `addLocationTable` mutation. */
export type AddLocationTablePayloadLocationTableEdgeArgs = {
  orderBy?: Maybe<Array<LocationTablesOrderBy>>;
};

/** All input for the `addLocationTable` mutation. */
export type AddLocationTableInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  locationId: Scalars['Int'];
  tableNumber?: Maybe<Scalars['Int']>;
};

/** The output of our `addSessionItem` mutation. */
export type AddSessionItemPayload = {
  __typename?: 'AddSessionItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  sessionItem?: Maybe<SessionsItem>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LocationsSession` that is related to this `SessionsItem`. */
  locationsSessionBySessionId?: Maybe<LocationsSession>;
  /** Reads a single `User` that is related to this `SessionsItem`. */
  userByUserId?: Maybe<User>;
  /** Reads a single `Item` that is related to this `SessionsItem`. */
  itemByItemId?: Maybe<Item>;
  /** An edge for our `SessionsItem`. May be used by Relay 1. */
  sessionsItemEdge?: Maybe<SessionsItemsEdge>;
};


/** The output of our `addSessionItem` mutation. */
export type AddSessionItemPayloadSessionsItemEdgeArgs = {
  orderBy?: Maybe<Array<SessionsItemsOrderBy>>;
};

/** All input for the `addSessionItem` mutation. */
export type AddSessionItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  sessionId: Scalars['Int'];
  itemId: Scalars['Int'];
  modifiers: Scalars['JSON'];
  quantity: Scalars['Int'];
};


/** The output of our `bindItemToGroup` mutation. */
export type BindItemToGroupPayload = {
  __typename?: 'BindItemToGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `bindItemToGroup` mutation. */
export type BindItemToGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  sessionsItemId: Scalars['Int'];
  groupId: Scalars['Int'];
  itemIds: Array<Maybe<Scalars['Int']>>;
};

/** The output of our `joinSession` mutation. */
export type JoinSessionPayload = {
  __typename?: 'JoinSessionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  sessionsUser?: Maybe<SessionsUser>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LocationsSession` that is related to this `SessionsUser`. */
  locationsSessionBySessionId?: Maybe<LocationsSession>;
  /** Reads a single `User` that is related to this `SessionsUser`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `SessionsUser`. May be used by Relay 1. */
  sessionsUserEdge?: Maybe<SessionsUsersEdge>;
};


/** The output of our `joinSession` mutation. */
export type JoinSessionPayloadSessionsUserEdgeArgs = {
  orderBy?: Maybe<Array<SessionsUsersOrderBy>>;
};

/** All input for the `joinSession` mutation. */
export type JoinSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  sessionCode: Scalars['Int'];
};

/** The output of our `leaveSession` mutation. */
export type LeaveSessionPayload = {
  __typename?: 'LeaveSessionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  sessionsUser?: Maybe<SessionsUser>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LocationsSession` that is related to this `SessionsUser`. */
  locationsSessionBySessionId?: Maybe<LocationsSession>;
  /** Reads a single `User` that is related to this `SessionsUser`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `SessionsUser`. May be used by Relay 1. */
  sessionsUserEdge?: Maybe<SessionsUsersEdge>;
};


/** The output of our `leaveSession` mutation. */
export type LeaveSessionPayloadSessionsUserEdgeArgs = {
  orderBy?: Maybe<Array<SessionsUsersOrderBy>>;
};

/** All input for the `leaveSession` mutation. */
export type LeaveSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The output of our `placeOrder` mutation. */
export type PlaceOrderPayload = {
  __typename?: 'PlaceOrderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  sessionsUser?: Maybe<SessionsUser>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LocationsSession` that is related to this `SessionsUser`. */
  locationsSessionBySessionId?: Maybe<LocationsSession>;
  /** Reads a single `User` that is related to this `SessionsUser`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `SessionsUser`. May be used by Relay 1. */
  sessionsUserEdge?: Maybe<SessionsUsersEdge>;
};


/** The output of our `placeOrder` mutation. */
export type PlaceOrderPayloadSessionsUserEdgeArgs = {
  orderBy?: Maybe<Array<SessionsUsersOrderBy>>;
};

/** All input for the `placeOrder` mutation. */
export type PlaceOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  sessionId: Scalars['Int'];
};

/** The output of our `regenerateTableCode` mutation. */
export type RegenerateTableCodePayload = {
  __typename?: 'RegenerateTableCodePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  locationTable?: Maybe<LocationTable>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Location` that is related to this `LocationTable`. */
  locationByLocationId?: Maybe<Location>;
  /** An edge for our `LocationTable`. May be used by Relay 1. */
  locationTableEdge?: Maybe<LocationTablesEdge>;
};


/** The output of our `regenerateTableCode` mutation. */
export type RegenerateTableCodePayloadLocationTableEdgeArgs = {
  orderBy?: Maybe<Array<LocationTablesOrderBy>>;
};

/** All input for the `regenerateTableCode` mutation. */
export type RegenerateTableCodeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  tableId: Scalars['Int'];
};

/** The output of our `removeLocationTable` mutation. */
export type RemoveLocationTablePayload = {
  __typename?: 'RemoveLocationTablePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  locationTable?: Maybe<LocationTable>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Location` that is related to this `LocationTable`. */
  locationByLocationId?: Maybe<Location>;
  /** An edge for our `LocationTable`. May be used by Relay 1. */
  locationTableEdge?: Maybe<LocationTablesEdge>;
};


/** The output of our `removeLocationTable` mutation. */
export type RemoveLocationTablePayloadLocationTableEdgeArgs = {
  orderBy?: Maybe<Array<LocationTablesOrderBy>>;
};

/** All input for the `removeLocationTable` mutation. */
export type RemoveLocationTableInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  tableId: Scalars['Int'];
};

/** The output of our `startSession` mutation. */
export type StartSessionPayload = {
  __typename?: 'StartSessionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  sessionsUser?: Maybe<SessionsUser>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LocationsSession` that is related to this `SessionsUser`. */
  locationsSessionBySessionId?: Maybe<LocationsSession>;
  /** Reads a single `User` that is related to this `SessionsUser`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `SessionsUser`. May be used by Relay 1. */
  sessionsUserEdge?: Maybe<SessionsUsersEdge>;
};


/** The output of our `startSession` mutation. */
export type StartSessionPayloadSessionsUserEdgeArgs = {
  orderBy?: Maybe<Array<SessionsUsersOrderBy>>;
};

/** All input for the `startSession` mutation. */
export type StartSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  locationCode: Scalars['Int'];
  locationTableId: Scalars['Int'];
  locationTableCode: Scalars['String'];
};

/** The output of our `verifyEmail` mutation. */
export type VerifyEmailPayload = {
  __typename?: 'VerifyEmailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  boolean?: Maybe<Scalars['Boolean']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `verifyEmail` mutation. */
export type VerifyEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  userEmailId: Scalars['UUID'];
  token: Scalars['String'];
};

export type SessionPayPayload = {
  __typename?: 'SessionPayPayload';
  sessionsUser?: Maybe<SessionsUser>;
  query?: Maybe<Query>;
};

export type SessionPayInput = {
  sessionId: Scalars['Int'];
  paymentToken: Scalars['String'];
};

export type RegisterMerchantPayload = {
  __typename?: 'RegisterMerchantPayload';
  credentials: Credentials;
};

export type Credentials = {
  __typename?: 'Credentials';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type RegisterMerchantInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type RegisterCustomerPayload = {
  __typename?: 'RegisterCustomerPayload';
  credentials: Credentials;
};

export type RegisterCustomerInput = {
  email: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
};

export type RegisterCustomerQuickPayload = {
  __typename?: 'RegisterCustomerQuickPayload';
  credentials: Credentials;
};

export type LoginByEmailAndPasswordPayload = {
  __typename?: 'LoginByEmailAndPasswordPayload';
  credentials: Credentials;
};

export type LoginByEmailAndPasswordInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginByRefreshTokenPayload = {
  __typename?: 'LoginByRefreshTokenPayload';
  credentials: Credentials;
};

export type LoginByRefreshTokenInput = {
  refreshToken: Scalars['String'];
};

export type LogoutPayload = {
  __typename?: 'LogoutPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type Subscription = {
  __typename?: 'Subscription';
  /**
   * Triggered when the current user's data changes:
   *
   * - direct modifications to the user
   * - when their organization membership changes
   */
  sessionUpdated?: Maybe<SessionSubscriptionPayload>;
  LocationUpdated?: Maybe<LocationSubscriptionPayload>;
  listen: ListenPayload;
};


/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionSessionUpdatedArgs = {
  input: SessionUpdateInput;
};


/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionLocationUpdatedArgs = {
  input: LocationSubscriptionInput;
};


/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionListenArgs = {
  topic: Scalars['String'];
};

export type SessionSubscriptionPayload = {
  __typename?: 'SessionSubscriptionPayload';
  sessionsUser?: Maybe<SessionsUser>;
  event?: Maybe<Scalars['String']>;
};

export type SessionUpdateInput = {
  sessionId: Scalars['Int'];
};

export type LocationSubscriptionPayload = {
  __typename?: 'LocationSubscriptionPayload';
  location?: Maybe<Location>;
  event?: Maybe<Scalars['String']>;
};

export type LocationSubscriptionInput = {
  locationId: Scalars['Int'];
};

export type ListenPayload = {
  __typename?: 'ListenPayload';
  /** Our root query field type. Allows us to run any query from our subscription payload. */
  query?: Maybe<Query>;
  relatedNode?: Maybe<Node>;
  relatedNodeId?: Maybe<Scalars['ID']>;
};

export type Auth = {
  __typename?: 'Auth';
  accessToken?: Maybe<Scalars['String']>;
  isInitialized: Scalars['Boolean'];
  query?: Maybe<Query>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type GetAuthQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAuthQuery = (
  { __typename?: 'Query' }
  & { auth?: Maybe<(
    { __typename?: 'Auth' }
    & Pick<Auth, 'isInitialized' | 'refreshToken' | 'accessToken'>
  )> }
);

export type CredentialsFragment = (
  { __typename?: 'Credentials' }
  & Pick<Credentials, 'accessToken' | 'refreshToken'>
);

export type RegisterMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type RegisterMutation = (
  { __typename?: 'Mutation' }
  & { registerMerchant?: Maybe<(
    { __typename?: 'RegisterMerchantPayload' }
    & { credentials: (
      { __typename?: 'Credentials' }
      & CredentialsFragment
    ) }
  )> }
);

export type LoginByEmailAndPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginByEmailAndPasswordMutation = (
  { __typename?: 'Mutation' }
  & { loginByEmailAndPassword?: Maybe<(
    { __typename?: 'LoginByEmailAndPasswordPayload' }
    & { credentials: (
      { __typename?: 'Credentials' }
      & CredentialsFragment
    ) }
  )> }
);

export type LoginByRefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type LoginByRefreshTokenMutation = (
  { __typename?: 'Mutation' }
  & { loginByRefreshToken?: Maybe<(
    { __typename?: 'LoginByRefreshTokenPayload' }
    & { credentials: (
      { __typename?: 'Credentials' }
      & CredentialsFragment
    ) }
  )> }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & { logout?: Maybe<(
    { __typename?: 'LogoutPayload' }
    & Pick<LogoutPayload, 'success'>
  )> }
);

export type GetCurrentLocationIdQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentLocationIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'currentLocationId'>
);

export type LocationEntryFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'id' | 'name'>
  & { cloverMerchantsByLocationId: (
    { __typename?: 'CloverMerchantsConnection' }
    & { nodes: Array<(
      { __typename?: 'CloverMerchant' }
      & CloverMerchantEntryFragment
    )> }
  ) }
);

export type LocationTableEntryFragment = (
  { __typename?: 'LocationTable' }
  & Pick<LocationTable, 'id' | 'code' | 'tableNumber' | 'locationId' | 'isActive'>
);

export type LocationTablesQueryVariables = Exact<{
  locationId?: Maybe<Scalars['Int']>;
}>;


export type LocationTablesQuery = (
  { __typename?: 'Query' }
  & { allLocationTables?: Maybe<(
    { __typename?: 'LocationTablesConnection' }
    & { nodes: Array<(
      { __typename?: 'LocationTable' }
      & LocationTableEntryFragment
    )> }
  )> }
);

export type AddLocationTableMutationVariables = Exact<{
  locationId: Scalars['Int'];
  tableNumber?: Maybe<Scalars['Int']>;
}>;


export type AddLocationTableMutation = (
  { __typename?: 'Mutation' }
  & { addLocationTable?: Maybe<(
    { __typename?: 'AddLocationTablePayload' }
    & { locationTable?: Maybe<(
      { __typename?: 'LocationTable' }
      & LocationTableEntryFragment
    )> }
  )> }
);

export type RemoveLocationTableMutationVariables = Exact<{
  tableId: Scalars['Int'];
}>;


export type RemoveLocationTableMutation = (
  { __typename?: 'Mutation' }
  & { removeLocationTable?: Maybe<(
    { __typename?: 'RemoveLocationTablePayload' }
    & { locationTable?: Maybe<(
      { __typename?: 'LocationTable' }
      & LocationTableEntryFragment
    )> }
  )> }
);

export type RegenerateTableCodeMutationVariables = Exact<{
  tableId: Scalars['Int'];
}>;


export type RegenerateTableCodeMutation = (
  { __typename?: 'Mutation' }
  & { regenerateTableCode?: Maybe<(
    { __typename?: 'RegenerateTableCodePayload' }
    & { locationTable?: Maybe<(
      { __typename?: 'LocationTable' }
      & LocationTableEntryFragment
    )> }
  )> }
);

export type AvailableLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type AvailableLocationsQuery = (
  { __typename?: 'Query' }
  & { allLocations?: Maybe<(
    { __typename?: 'LocationsConnection' }
    & { nodes: Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name'>
    )> }
  )> }
);

export type GetCurrentLocationQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetCurrentLocationQuery = (
  { __typename?: 'Query' }
  & { currentLocation?: Maybe<(
    { __typename?: 'Location' }
    & LocationEntryFragment
  )> }
);

export type LocationUpdatedSubscriptionVariables = Exact<{
  locationId: Scalars['Int'];
}>;


export type LocationUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { LocationUpdated?: Maybe<(
    { __typename?: 'LocationSubscriptionPayload' }
    & { location?: Maybe<(
      { __typename?: 'Location' }
      & LocationEntryFragment
    )> }
  )> }
);

export type CloverMerchantEntryFragment = (
  { __typename?: 'CloverMerchant' }
  & Pick<CloverMerchant, 'id' | 'userId' | 'isInitialized' | 'isSyncedMerchant' | 'isSyncingMerchant' | 'isSyncingInventoryItems' | 'isSyncedInventoryItems' | 'isSyncedModifiers' | 'isSyncingModifiers' | 'isSyncedOrderTypes' | 'isSyncingOrderTypes' | 'isSyncedPaymentKey' | 'isSyncingPaymentKey' | 'error' | 'updatedAt'>
  & { locationByLocationId?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name'>
  )> }
);

export type CurrentMerchantQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentMerchantQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'User' }
    & { cloverMerchantsByUserId: (
      { __typename?: 'CloverMerchantsConnection' }
      & { nodes: Array<(
        { __typename?: 'CloverMerchant' }
        & CloverMerchantEntryFragment
      )> }
    ) }
    & MerchantEntryFragment
  )> }
);

export type UserEntryFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'username'>
);

export type MerchantEntryFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id'>
  & { userEmailsByUserId: (
    { __typename?: 'UserEmailsConnection' }
    & { nodes: Array<(
      { __typename?: 'UserEmail' }
      & Pick<UserEmail, 'email'>
    )> }
  ) }
);

export const CredentialsFragmentDoc = gql`
    fragment Credentials on Credentials {
  accessToken
  refreshToken
}
    `;
export const CloverMerchantEntryFragmentDoc = gql`
    fragment CloverMerchantEntry on CloverMerchant {
  id
  userId
  isInitialized
  isSyncedMerchant
  isSyncingMerchant
  isSyncingInventoryItems
  isSyncedInventoryItems
  isSyncedModifiers
  isSyncingModifiers
  isSyncedOrderTypes
  isSyncingOrderTypes
  isSyncedPaymentKey
  isSyncingPaymentKey
  error
  updatedAt
  locationByLocationId {
    id
    name
  }
}
    `;
export const LocationEntryFragmentDoc = gql`
    fragment LocationEntry on Location {
  id
  name
  cloverMerchantsByLocationId {
    nodes {
      ...CloverMerchantEntry
    }
  }
}
    ${CloverMerchantEntryFragmentDoc}`;
export const LocationTableEntryFragmentDoc = gql`
    fragment LocationTableEntry on LocationTable {
  id
  code
  tableNumber
  locationId
  isActive
}
    `;
export const UserEntryFragmentDoc = gql`
    fragment UserEntry on User {
  id
  username
}
    `;
export const MerchantEntryFragmentDoc = gql`
    fragment MerchantEntry on User {
  id
  userEmailsByUserId(orderBy: [IS_VERIFIED_DESC, CREATED_AT_DESC]) {
    nodes {
      email
    }
  }
}
    `;
export const GetAuthDocument = gql`
    query GetAuth {
  auth @client {
    isInitialized
    refreshToken
    accessToken
  }
}
    `;

/**
 * __useGetAuthQuery__
 *
 * To run a query within a React component, call `useGetAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuthQuery(baseOptions?: Apollo.QueryHookOptions<GetAuthQuery, GetAuthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuthQuery, GetAuthQueryVariables>(GetAuthDocument, options);
      }
export function useGetAuthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuthQuery, GetAuthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuthQuery, GetAuthQueryVariables>(GetAuthDocument, options);
        }
export type GetAuthQueryHookResult = ReturnType<typeof useGetAuthQuery>;
export type GetAuthLazyQueryHookResult = ReturnType<typeof useGetAuthLazyQuery>;
export type GetAuthQueryResult = Apollo.QueryResult<GetAuthQuery, GetAuthQueryVariables>;
export const RegisterDocument = gql`
    mutation Register($email: String!, $password: String!) {
  registerMerchant(input: {email: $email, password: $password}) {
    credentials {
      ...Credentials
    }
  }
}
    ${CredentialsFragmentDoc}`;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const LoginByEmailAndPasswordDocument = gql`
    mutation LoginByEmailAndPassword($email: String!, $password: String!) {
  loginByEmailAndPassword(input: {email: $email, password: $password}) {
    credentials {
      ...Credentials
    }
  }
}
    ${CredentialsFragmentDoc}`;
export type LoginByEmailAndPasswordMutationFn = Apollo.MutationFunction<LoginByEmailAndPasswordMutation, LoginByEmailAndPasswordMutationVariables>;

/**
 * __useLoginByEmailAndPasswordMutation__
 *
 * To run a mutation, you first call `useLoginByEmailAndPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginByEmailAndPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginByEmailAndPasswordMutation, { data, loading, error }] = useLoginByEmailAndPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginByEmailAndPasswordMutation(baseOptions?: Apollo.MutationHookOptions<LoginByEmailAndPasswordMutation, LoginByEmailAndPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginByEmailAndPasswordMutation, LoginByEmailAndPasswordMutationVariables>(LoginByEmailAndPasswordDocument, options);
      }
export type LoginByEmailAndPasswordMutationHookResult = ReturnType<typeof useLoginByEmailAndPasswordMutation>;
export type LoginByEmailAndPasswordMutationResult = Apollo.MutationResult<LoginByEmailAndPasswordMutation>;
export type LoginByEmailAndPasswordMutationOptions = Apollo.BaseMutationOptions<LoginByEmailAndPasswordMutation, LoginByEmailAndPasswordMutationVariables>;
export const LoginByRefreshTokenDocument = gql`
    mutation LoginByRefreshToken($refreshToken: String!) {
  loginByRefreshToken(input: {refreshToken: $refreshToken}) {
    credentials {
      ...Credentials
    }
  }
}
    ${CredentialsFragmentDoc}`;
export type LoginByRefreshTokenMutationFn = Apollo.MutationFunction<LoginByRefreshTokenMutation, LoginByRefreshTokenMutationVariables>;

/**
 * __useLoginByRefreshTokenMutation__
 *
 * To run a mutation, you first call `useLoginByRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginByRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginByRefreshTokenMutation, { data, loading, error }] = useLoginByRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useLoginByRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<LoginByRefreshTokenMutation, LoginByRefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginByRefreshTokenMutation, LoginByRefreshTokenMutationVariables>(LoginByRefreshTokenDocument, options);
      }
export type LoginByRefreshTokenMutationHookResult = ReturnType<typeof useLoginByRefreshTokenMutation>;
export type LoginByRefreshTokenMutationResult = Apollo.MutationResult<LoginByRefreshTokenMutation>;
export type LoginByRefreshTokenMutationOptions = Apollo.BaseMutationOptions<LoginByRefreshTokenMutation, LoginByRefreshTokenMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    success
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const GetCurrentLocationIdDocument = gql`
    query GetCurrentLocationId {
  currentLocationId @client
}
    `;

/**
 * __useGetCurrentLocationIdQuery__
 *
 * To run a query within a React component, call `useGetCurrentLocationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentLocationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentLocationIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentLocationIdQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentLocationIdQuery, GetCurrentLocationIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentLocationIdQuery, GetCurrentLocationIdQueryVariables>(GetCurrentLocationIdDocument, options);
      }
export function useGetCurrentLocationIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentLocationIdQuery, GetCurrentLocationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentLocationIdQuery, GetCurrentLocationIdQueryVariables>(GetCurrentLocationIdDocument, options);
        }
export type GetCurrentLocationIdQueryHookResult = ReturnType<typeof useGetCurrentLocationIdQuery>;
export type GetCurrentLocationIdLazyQueryHookResult = ReturnType<typeof useGetCurrentLocationIdLazyQuery>;
export type GetCurrentLocationIdQueryResult = Apollo.QueryResult<GetCurrentLocationIdQuery, GetCurrentLocationIdQueryVariables>;
export const LocationTablesDocument = gql`
    query LocationTables($locationId: Int) {
  allLocationTables(
    condition: {locationId: $locationId}
    filter: {isActive: {equalTo: true}}
    orderBy: [TABLE_NUMBER_ASC]
  ) {
    nodes {
      ...LocationTableEntry
    }
  }
}
    ${LocationTableEntryFragmentDoc}`;

/**
 * __useLocationTablesQuery__
 *
 * To run a query within a React component, call `useLocationTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationTablesQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useLocationTablesQuery(baseOptions?: Apollo.QueryHookOptions<LocationTablesQuery, LocationTablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationTablesQuery, LocationTablesQueryVariables>(LocationTablesDocument, options);
      }
export function useLocationTablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationTablesQuery, LocationTablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationTablesQuery, LocationTablesQueryVariables>(LocationTablesDocument, options);
        }
export type LocationTablesQueryHookResult = ReturnType<typeof useLocationTablesQuery>;
export type LocationTablesLazyQueryHookResult = ReturnType<typeof useLocationTablesLazyQuery>;
export type LocationTablesQueryResult = Apollo.QueryResult<LocationTablesQuery, LocationTablesQueryVariables>;
export const AddLocationTableDocument = gql`
    mutation AddLocationTable($locationId: Int!, $tableNumber: Int) {
  addLocationTable(input: {locationId: $locationId, tableNumber: $tableNumber}) {
    locationTable {
      ...LocationTableEntry
    }
  }
}
    ${LocationTableEntryFragmentDoc}`;
export type AddLocationTableMutationFn = Apollo.MutationFunction<AddLocationTableMutation, AddLocationTableMutationVariables>;

/**
 * __useAddLocationTableMutation__
 *
 * To run a mutation, you first call `useAddLocationTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLocationTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLocationTableMutation, { data, loading, error }] = useAddLocationTableMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      tableNumber: // value for 'tableNumber'
 *   },
 * });
 */
export function useAddLocationTableMutation(baseOptions?: Apollo.MutationHookOptions<AddLocationTableMutation, AddLocationTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLocationTableMutation, AddLocationTableMutationVariables>(AddLocationTableDocument, options);
      }
export type AddLocationTableMutationHookResult = ReturnType<typeof useAddLocationTableMutation>;
export type AddLocationTableMutationResult = Apollo.MutationResult<AddLocationTableMutation>;
export type AddLocationTableMutationOptions = Apollo.BaseMutationOptions<AddLocationTableMutation, AddLocationTableMutationVariables>;
export const RemoveLocationTableDocument = gql`
    mutation RemoveLocationTable($tableId: Int!) {
  removeLocationTable(input: {tableId: $tableId}) {
    locationTable {
      ...LocationTableEntry
    }
  }
}
    ${LocationTableEntryFragmentDoc}`;
export type RemoveLocationTableMutationFn = Apollo.MutationFunction<RemoveLocationTableMutation, RemoveLocationTableMutationVariables>;

/**
 * __useRemoveLocationTableMutation__
 *
 * To run a mutation, you first call `useRemoveLocationTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLocationTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLocationTableMutation, { data, loading, error }] = useRemoveLocationTableMutation({
 *   variables: {
 *      tableId: // value for 'tableId'
 *   },
 * });
 */
export function useRemoveLocationTableMutation(baseOptions?: Apollo.MutationHookOptions<RemoveLocationTableMutation, RemoveLocationTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveLocationTableMutation, RemoveLocationTableMutationVariables>(RemoveLocationTableDocument, options);
      }
export type RemoveLocationTableMutationHookResult = ReturnType<typeof useRemoveLocationTableMutation>;
export type RemoveLocationTableMutationResult = Apollo.MutationResult<RemoveLocationTableMutation>;
export type RemoveLocationTableMutationOptions = Apollo.BaseMutationOptions<RemoveLocationTableMutation, RemoveLocationTableMutationVariables>;
export const RegenerateTableCodeDocument = gql`
    mutation RegenerateTableCode($tableId: Int!) {
  regenerateTableCode(input: {tableId: $tableId}) {
    locationTable {
      ...LocationTableEntry
    }
  }
}
    ${LocationTableEntryFragmentDoc}`;
export type RegenerateTableCodeMutationFn = Apollo.MutationFunction<RegenerateTableCodeMutation, RegenerateTableCodeMutationVariables>;

/**
 * __useRegenerateTableCodeMutation__
 *
 * To run a mutation, you first call `useRegenerateTableCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateTableCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateTableCodeMutation, { data, loading, error }] = useRegenerateTableCodeMutation({
 *   variables: {
 *      tableId: // value for 'tableId'
 *   },
 * });
 */
export function useRegenerateTableCodeMutation(baseOptions?: Apollo.MutationHookOptions<RegenerateTableCodeMutation, RegenerateTableCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegenerateTableCodeMutation, RegenerateTableCodeMutationVariables>(RegenerateTableCodeDocument, options);
      }
export type RegenerateTableCodeMutationHookResult = ReturnType<typeof useRegenerateTableCodeMutation>;
export type RegenerateTableCodeMutationResult = Apollo.MutationResult<RegenerateTableCodeMutation>;
export type RegenerateTableCodeMutationOptions = Apollo.BaseMutationOptions<RegenerateTableCodeMutation, RegenerateTableCodeMutationVariables>;
export const AvailableLocationsDocument = gql`
    query AvailableLocations {
  allLocations {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useAvailableLocationsQuery__
 *
 * To run a query within a React component, call `useAvailableLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableLocationsQuery(baseOptions?: Apollo.QueryHookOptions<AvailableLocationsQuery, AvailableLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableLocationsQuery, AvailableLocationsQueryVariables>(AvailableLocationsDocument, options);
      }
export function useAvailableLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableLocationsQuery, AvailableLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableLocationsQuery, AvailableLocationsQueryVariables>(AvailableLocationsDocument, options);
        }
export type AvailableLocationsQueryHookResult = ReturnType<typeof useAvailableLocationsQuery>;
export type AvailableLocationsLazyQueryHookResult = ReturnType<typeof useAvailableLocationsLazyQuery>;
export type AvailableLocationsQueryResult = Apollo.QueryResult<AvailableLocationsQuery, AvailableLocationsQueryVariables>;
export const GetCurrentLocationDocument = gql`
    query GetCurrentLocation($id: Int!) {
  currentLocation(id: $id) {
    ...LocationEntry
  }
}
    ${LocationEntryFragmentDoc}`;

/**
 * __useGetCurrentLocationQuery__
 *
 * To run a query within a React component, call `useGetCurrentLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCurrentLocationQuery(baseOptions: Apollo.QueryHookOptions<GetCurrentLocationQuery, GetCurrentLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentLocationQuery, GetCurrentLocationQueryVariables>(GetCurrentLocationDocument, options);
      }
export function useGetCurrentLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentLocationQuery, GetCurrentLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentLocationQuery, GetCurrentLocationQueryVariables>(GetCurrentLocationDocument, options);
        }
export type GetCurrentLocationQueryHookResult = ReturnType<typeof useGetCurrentLocationQuery>;
export type GetCurrentLocationLazyQueryHookResult = ReturnType<typeof useGetCurrentLocationLazyQuery>;
export type GetCurrentLocationQueryResult = Apollo.QueryResult<GetCurrentLocationQuery, GetCurrentLocationQueryVariables>;
export const LocationUpdatedDocument = gql`
    subscription LocationUpdated($locationId: Int!) {
  LocationUpdated(input: {locationId: $locationId}) {
    location {
      ...LocationEntry
    }
  }
}
    ${LocationEntryFragmentDoc}`;

/**
 * __useLocationUpdatedSubscription__
 *
 * To run a query within a React component, call `useLocationUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLocationUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationUpdatedSubscription({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useLocationUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<LocationUpdatedSubscription, LocationUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<LocationUpdatedSubscription, LocationUpdatedSubscriptionVariables>(LocationUpdatedDocument, options);
      }
export type LocationUpdatedSubscriptionHookResult = ReturnType<typeof useLocationUpdatedSubscription>;
export type LocationUpdatedSubscriptionResult = Apollo.SubscriptionResult<LocationUpdatedSubscription>;
export const CurrentMerchantDocument = gql`
    query CurrentMerchant {
  currentUser {
    ...MerchantEntry
    cloverMerchantsByUserId {
      nodes {
        ...CloverMerchantEntry
      }
    }
  }
}
    ${MerchantEntryFragmentDoc}
${CloverMerchantEntryFragmentDoc}`;

/**
 * __useCurrentMerchantQuery__
 *
 * To run a query within a React component, call `useCurrentMerchantQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentMerchantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentMerchantQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentMerchantQuery(baseOptions?: Apollo.QueryHookOptions<CurrentMerchantQuery, CurrentMerchantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentMerchantQuery, CurrentMerchantQueryVariables>(CurrentMerchantDocument, options);
      }
export function useCurrentMerchantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentMerchantQuery, CurrentMerchantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentMerchantQuery, CurrentMerchantQueryVariables>(CurrentMerchantDocument, options);
        }
export type CurrentMerchantQueryHookResult = ReturnType<typeof useCurrentMerchantQuery>;
export type CurrentMerchantLazyQueryHookResult = ReturnType<typeof useCurrentMerchantLazyQuery>;
export type CurrentMerchantQueryResult = Apollo.QueryResult<CurrentMerchantQuery, CurrentMerchantQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Node": [
      "Query",
      "CloverMerchant",
      "User",
      "UserEmail",
      "Location",
      "LocationTable",
      "Menu",
      "MenuSection",
      "SectionsItem",
      "Item",
      "Modifier",
      "ModifiersGroup",
      "ModifiersItem",
      "SelectedModifiersItem",
      "SessionsItem",
      "LocationsSession",
      "SessionsUser",
      "SelectedModifiersGroup"
    ]
  }
};
      export default result;
    