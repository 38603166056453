import React, { useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { setCurrentLocation } from 'hooks/location';

type Params = {
  locationId: string;
};

const SetCurrentLocationScreen = () => {
  const { locationId } = useParams<Params>();
  useEffect(() => {
    if (locationId) {
      setCurrentLocation(Number(locationId));
    }
  }, [locationId]);
  return <Redirect to="/" />;
};

export default SetCurrentLocationScreen;
