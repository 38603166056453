import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import AppInitializing from 'components/App/AppInitializing';
import { useAuth } from 'hooks/auth';
import AppWrapper from 'components/App/AppWrapper';

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { isInitialized, isAuthorized } = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!isInitialized) {
          return <AppInitializing />;
        }
        if (isAuthorized && isInitialized) {
          return <AppWrapper>{children}</AppWrapper>;
        }

        return (
          <Redirect
            to={{
              pathname: '/signIn',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
