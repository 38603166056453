import { useCallback, useEffect } from 'react';
import { getClientId, setItem, getItem } from 'utils/storage';
import {
  GetAuthDocument,
  GetAuthQuery,
  useGetAuthQuery,
  useRegisterMutation,
  useLoginByEmailAndPasswordMutation,
  useLoginByRefreshTokenMutation,
  useLogoutMutation,
  LoginByEmailAndPasswordMutation,
  LoginByRefreshTokenMutation,
  RegisterMutation,
} from 'graphql/graphql-types';
import {
  ApolloCache,
  useApolloClient,
  NormalizedCacheObject,
} from '@apollo/client';
import { RootCache } from 'config/ApolloClient';
import config from 'config';

export const updateAuthorizeCache = (
  cache: ApolloCache<
    | LoginByEmailAndPasswordMutation
    | LoginByRefreshTokenMutation
    | RegisterMutation
    | NormalizedCacheObject
  >,
  refreshToken: string | null | undefined,
  accessToken: string | null | undefined,
) => {
  cache.writeQuery<GetAuthQuery>({
    query: GetAuthDocument,
    data: {
      auth: {
        isInitialized: true,
        accessToken,
        refreshToken,
      },
    },
  });
  setItem('auth', { refreshToken });
};

export const useInitialAuth = () => {
  const [authorizeByRefreshToken] = useLoginByRefreshTokenMutation({
    update(cache, result) {
      const { refreshToken, accessToken } =
        result.data?.loginByRefreshToken?.credentials ?? {};
      updateAuthorizeCache(cache, refreshToken, accessToken);
    },
  });

  useEffect(() => {
    async function tryAuth() {
      try {
        const credentials = await getItem('auth');
        if (!credentials?.refreshToken) {
          // No refreshToken in storage
          throw Error('Incorrect refreshToken');
        }
        await authorizeByRefreshToken({
          variables: { refreshToken: credentials.refreshToken },
        });
      } catch (e) {
        if (config.isDev) {
          console.log(e);
        }
        if (e.message === 'Incorrect refreshToken') {
          // No refreshToken in storage or refreshToken is stale
          updateAuthorizeCache(RootCache, '', '');
        }
        // TODO handle this error
      }
    }
    tryAuth();
  }, []);
};

export const useRegisterByEmailAndPassword = () => {
  const [registerByEmailAndPasswordQuery, data] = useRegisterMutation({
    update(cache, result) {
      const { refreshToken, accessToken } =
        result.data?.registerMerchant?.credentials ?? {};
      updateAuthorizeCache(cache, refreshToken, accessToken);
    },
  });

  const registerByEmailAndPassword = useCallback(
    async (email: string, password: string) => {
      try {
        const clientId = await getClientId();
        if (clientId) {
          await registerByEmailAndPasswordQuery({
            variables: { email, password },
          });
          return { error: null };
        }
        return { error: 'Sorry some error happened' };
      } catch (e) {
        // TODO log error
        if (config.isDev) {
          console.log(e);
        }
        return { error: 'Email or password is incorrect' };
      }
    },
    [],
  );

  return { registerByEmailAndPassword, data };
};

export const useAuthorizeByEmailAndPassword = () => {
  const [
    authorizeByEmailAndPasswordQuery,
    data,
  ] = useLoginByEmailAndPasswordMutation({
    update(cache, result) {
      const { refreshToken, accessToken } =
        result.data?.loginByEmailAndPassword?.credentials ?? {};
      updateAuthorizeCache(cache, refreshToken, accessToken);
    },
  });

  const authorizeByEmailAndPassword = useCallback(
    async (email: string, password: string) => {
      try {
        const clientId = await getClientId();
        if (clientId) {
          await authorizeByEmailAndPasswordQuery({
            variables: { email, password },
          });
          return { error: null };
        }
        return { error: 'Sorry some error happened' };
      } catch (e) {
        // TODO log error
        if (config.isDev) {
          console.log(e);
        }
        return { error: 'Email or password is incorrect' };
      }
    },
    [],
  );

  return { authorizeByEmailAndPassword, data };
};

export const useAuth = () => {
  const { data } = useGetAuthQuery();
  const { accessToken, isInitialized } = data?.auth ?? {};
  return { isInitialized, isAuthorized: !!accessToken };
};

export const useLogout = () => {
  const [logout] = useLogoutMutation({
    update(cache, result) {
      const { success } = result.data?.logout ?? {};
      if (success) {
        updateAuthorizeCache(cache, '', '');
      }
    },
  });
  const client = useApolloClient();
  return useCallback(async () => {
    const { data } = await logout();
    if (!data?.logout?.success) {
      if (config.isDev) {
        console.log(data);
      }
      // TODO log this case
      return;
    }
    await client.resetStore();
  }, []);
};
