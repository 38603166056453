import React, { useCallback, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';
import { gql } from '@apollo/client';
import CircleLoading from 'components/CircleLoading';
import {
  useAddLocationTableMutation,
  LocationTablesDocument,
  LocationTablesQuery,
  LocationTablesQueryVariables,
} from 'graphql/graphql-types';
import config from 'config';
import { useCurrentLocation } from 'hooks/location';

type Props = {
  open: boolean;
  handleClose(isOpen: boolean): void;
};

const useStyles = makeStyles(() => ({
  root: {
    // '& div + .save-btn': {
    //   marginLeft: '5px',
    // },
  },
  buttonContainer: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
}));

export default function ScreenDialog({ open, handleClose }: Props) {
  const [tableNumber, setTableNum] = useState<undefined | number>();
  const classes = useStyles();
  const { currentLocation } = useCurrentLocation();
  const locationId = currentLocation?.id;
  const [
    addLocationTable,
    { loading: addLocationTableLoading, error: addLocationTableError },
  ] = useAddLocationTableMutation({
    update: (cache, { data }) => {
      const locationTable = data?.addLocationTable?.locationTable;
      if (locationTable) {
        const existingAllLocationTables = cache.readQuery<
          LocationTablesQuery,
          LocationTablesQueryVariables
        >({
          query: LocationTablesDocument,
          variables: { locationId },
        });
        cache.writeQuery<LocationTablesQuery, LocationTablesQueryVariables>({
          query: LocationTablesDocument,
          variables: { locationId },
          data: {
            ...existingAllLocationTables,
            allLocationTables: {
              nodes: [
                ...(existingAllLocationTables?.allLocationTables?.nodes ?? []),
                locationTable,
              ],
            },
          },
        });
      }
      setTableNum(undefined);
    },
  });

  const handleAddTable = useCallback(async () => {
    try {
      if (locationId) {
        await addLocationTable({ variables: { locationId, tableNumber } });
        handleClose(false);
      }
    } catch (e) {
      if (config.isDev) {
        console.log(e);
      }
      // TODO log error
    }
  }, [locationId, tableNumber]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Define table number</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter a number or leave blank (auto-increment)
          </DialogContentText>
          {!addLocationTableError ? (
            <TextField
              autoFocus
              margin="dense"
              id="table"
              label="Table number"
              type="number"
              onChange={(e) => setTableNum(parseInt(e.target.value, 10))}
              fullWidth
            />
          ) : (
            <TextField
              error
              autoFocus
              margin="dense"
              id="standard-error-helper-text"
              label="Error"
              type="number"
              onChange={(e) => setTableNum(parseInt(e.target.value, 10))}
              fullWidth
              helperText={addLocationTableError.message}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Box display="flex" flexGrow={1} className={classes.buttonContainer}>
            <Button onClick={() => handleClose(false)} color="default">
              Cancel
            </Button>
            <Box flexGrow={1} />
            <Button
              className={classes.root}
              onClick={handleAddTable}
              color="primary"
            >
              {addLocationTableLoading && <CircleLoading />}
              <span className="save-btn">Create table</span>
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
