import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
// import green from '@material-ui/core/colors/green';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: blue.A400,
    },
    background: {
      default: '#f6f9fc',
    },
  },
  props: {
    MuiTextField: {
      margin: 'normal',
    },
    MuiFormControl: {
      margin: 'normal',
    },
  },
});

export default responsiveFontSizes(theme);
