import SignIn from 'screens/SignIn';
import SignUp from 'screens/SignUp';
import HomeScreen from 'screens/Home/HomeScreen';
import LocationTables from 'screens/LocationTables/LocationTables';
import SetCurrentLocationScreen from 'screens/SetCurrentLocationScreen';

export const publicRoutes = [
  { path: '/signIn', component: SignIn },
  { path: '/signUp', component: SignUp },
];

export const privateRoutes = [
  { path: '/', component: HomeScreen },
  { path: '/tables', component: LocationTables },
  {
    path: '/current-location/:locationId',
    component: SetCurrentLocationScreen,
  },
];
