import React, { FunctionComponent } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Form } from 'react-final-form';
import { TextField, makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import text from 'constants/text';
import { useRegisterByEmailAndPassword } from 'hooks/auth';
import brandLogo from '../assets/brandLogo.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
  },
  formWrap: {
    margin: '0 auto',
    maxWidth: '460px',
  },
  rootPaper: {
    paddingRight: theme.spacing(7),
    paddingLeft: theme.spacing(7),
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  brandLogo: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  titleContainer: { marginBottom: theme.spacing(1) },
  titleHeading: { marginBottom: theme.spacing(1) },
  buttonContainer: { marginTop: theme.spacing(3) },
  buttonPrimary: { marginTop: theme.spacing(1) },
  buttonSecondary: {
    marginTop: theme.spacing(0.5),
    left: '-.5rem',
    textTransform: 'inherit',
  },
}));

const schema = Yup.object().shape({
  email: Yup.string().required(),
  password: Yup.string().required(),
});

const validate = makeValidate(schema);

export const formFields: any[] = [
  <TextField
    required
    label="Email"
    id="email"
    type="email"
    name="email"
    autoComplete="email"
  />,
  <TextField
    required
    label="Password"
    id="password"
    type="password"
    name="password"
    autoComplete="current-password"
  />,
];

const SignUp: FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    registerByEmailAndPassword,
    data: { loading },
  } = useRegisterByEmailAndPassword();

  const onSubmit = async ({ email, password }: Yup.TypeOf<typeof schema>) => {
    if (email && password) {
      const { error } = await registerByEmailAndPassword(email, password);
      if (error) {
        return { password: text.passwordIncorrect };
      }
      history.push('/');
    }
    return {};
  };

  const handleOpenSignIn = () => {
    history.push('/signIn');
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexGrow={1}
      className={classes.root}
    >
      <Container fixed>
        <Box className={classes.formWrap}>
          <Paper className={classes.rootPaper} elevation={1} variant="outlined">
            <Box
              display="flex"
              justifyContent="center"
              className={classes.brandLogo}
            >
              <img src={brandLogo} alt="Roux" width="80" />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              className={classes.titleContainer}
            >
              <Typography variant="h5" className={classes.titleHeading}>
                Create your account
              </Typography>
              <Typography variant="body1" gutterBottom>
                to continue to Roux
              </Typography>
            </Box>
            <Form
              onSubmit={onSubmit}
              validate={validate}
              render={({ handleSubmit, submitting, pristine }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Box flexDirection="column">
                    {formFields.map((item, idx) => (
                      <Box key={idx}>{item}</Box>
                    ))}
                    <Box display="flex" className={classes.buttonContainer}>
                      <Button
                        color="primary"
                        onClick={handleOpenSignIn}
                        className={classes.buttonSecondary}
                      >
                        Sign in Instead
                      </Button>
                      <Box display="flex" flexGrow={1} />
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={submitting || pristine || loading}
                        className={classes.buttonPrimary}
                        disableElevation
                      >
                        Create account
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            />
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

export default SignUp;
