import FingerprintJS from '@fingerprintjs/fingerprintjs';

export const getItem = async (key: string) => {
  try {
    const jsonValue = await localStorage.getItem(key);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // TODO log error
    return null;
  }
};

export const setItem = async (key: string, value: object) => {
  try {
    const jsonValue = JSON.stringify(value);
    await localStorage.setItem(key, jsonValue);
    return true;
  } catch (e) {
    // TODO log error
    return false;
  }
};

type Client = {
  id?: string;
};

export const getClientId = async () => {
  try {
    const jsonValue = await localStorage.getItem('client');
    const client: Client | null =
      jsonValue != null ? JSON.parse(jsonValue) : null;
    if (client && client.id) {
      return client.id;
    }
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const clientId = result.visitorId;
    await setItem('client', { id: clientId });
    return clientId;
  } catch (e) {
    // TODO log error
    return null;
  }
};
