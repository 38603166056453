import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useCurrentLocation } from 'hooks/location';
import {
  MainListItems,
  SecondaryListItems,
  MainListItemsNoLocation,
} from './drawerItems';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
}));

interface Props {
  handleDrawerClose(): void;
  isOpenDrawer: boolean;
}

const AppDrawer = ({ isOpenDrawer, handleDrawerClose }: Props) => {
  const classes = useStyles();
  const { currentLocation } = useCurrentLocation();
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(
          classes.drawerPaper,
          !isOpenDrawer && classes.drawerPaperClose,
        ),
      }}
      open={isOpenDrawer}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        {currentLocation ? <MainListItems /> : <MainListItemsNoLocation />}
      </List>
      <Divider />
      <List>{!!currentLocation && <SecondaryListItems />}</List>
    </Drawer>
  );
};

export default AppDrawer;
