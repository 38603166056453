import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useInitialAuth } from 'hooks/auth';
import { privateRoutes, publicRoutes } from './routes';
import PrivateRoute from './PrivateRoute';

const RootRoute = () => {
  useInitialAuth();
  return (
    <Switch>
      {publicRoutes.map(({ path, component: Component }) => (
        <Route key={path} exact path={path}>
          <Component />
        </Route>
      ))}
      {privateRoutes.map(({ path, component: Component }) => (
        <PrivateRoute key={path} exact path={path}>
          <Component />
        </PrivateRoute>
      ))}
    </Switch>
  );
};

export default RootRoute;
