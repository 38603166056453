const CLOVER_API_SERVER_URL = 'http://daf0613ad5c3.ngrok.io';
const CLOVER_REDIRECT_URI = `${CLOVER_API_SERVER_URL}/clover-webhooks/authorize`;
const CLOVER_CLIENT_ID = 'CQJGPJJYJFQ6R';

export default {
  graphqlServerUrl: 'http://127.0.0.1:3050/graphql',
  graphqlServerUrlWS: 'ws://127.0.0.1:3050/graphql',
  cloverAuthorizeUrl: (state: string) =>
    `https://sandbox.dev.clover.com/oauth/authorize?client_id=${CLOVER_CLIENT_ID}&state=${state}&redirect_uri=${CLOVER_REDIRECT_URI}`,
};
