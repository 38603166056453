import React, { useCallback } from 'react';
import config from 'config';
import { useCurrentMerchantQuery } from 'graphql/graphql-types';

export const useCloverAuthorizeUrl = () => {
  const { data } = useCurrentMerchantQuery();
  const currentUserId = data?.currentUser?.id;
  return useCallback((): string => config.cloverAuthorizeUrl(currentUserId), [
    currentUserId,
  ]);
};
