let localConfig = {};
try {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  localConfig = require('./config.local').default;
} catch (e) {
  console.log('config.local not found');
}

const { REACT_APP_ENV } = process.env;
const commonConfig = {};
const development = {};

const PRODUCTION_CLOVER_API_SERVER_URL =
  'https://clover-api-dot-rouxapp-9feab.ue.r.appspot.com';
const PRODUCTION_CLOVER_REDIRECT_URI = `${PRODUCTION_CLOVER_API_SERVER_URL}/clover-webhooks/authorize`;
const PRODUCTION_CLOVER_CLIENT_ID = 'QKV7YKYKH9V4G';
const production = {
  graphqlServerUrl: 'https://server-dot-rouxapp-9feab.ue.r.appspot.com/graphql',
  graphqlServerUrlWS: 'wss://server-dot-rouxapp-9feab.ue.r.appspot.com/graphql',
  cloverAuthorizeUrl: (state: string) =>
    `https://sandbox.dev.clover.com/oauth/authorize?client_id=${PRODUCTION_CLOVER_CLIENT_ID}&state=${state}&redirect_uri=${PRODUCTION_CLOVER_REDIRECT_URI}`,
};

const STAGING_CLOVER_API_SERVER_URL =
  'https://clover-api-dot-roux-staging.ue.r.appspot.com';
const STAGING_CLOVER_REDIRECT_URI = `${STAGING_CLOVER_API_SERVER_URL}/clover-webhooks/authorize`;
const STAGING_CLOVER_CLIENT_ID = 'QKV7YKYKH9V4G';
const staging = {
  graphqlServerUrl: 'https://server-dot-roux-staging.ue.r.appspot.com/graphql',
  graphqlServerUrlWS: 'wss://server-dot-roux-staging.ue.r.appspot.com/graphql',
  cloverAuthorizeUrl: (state: string) =>
    `https://sandbox.dev.clover.com/oauth/authorize?client_id=${STAGING_CLOVER_CLIENT_ID}&state=${state}&redirect_uri=${STAGING_CLOVER_REDIRECT_URI}`,
};

let config; // eslint-disable-line

if (REACT_APP_ENV === 'production') {
  config = production;
} else if (REACT_APP_ENV === 'staging') {
  config = staging;
} else {
  config = development;
}

export default {
  ...commonConfig,
  ...localConfig,
  ...config,
  isDev: REACT_APP_ENV !== 'production' && REACT_APP_ENV !== 'staging',
} as any;
