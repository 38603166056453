import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import IconDone from '@material-ui/icons/Done';
import IconClear from '@material-ui/icons/Clear';
import SyncIcon from '@material-ui/icons/Sync';
import theme from 'theme';
import Red from '@material-ui/core/colors/red';
import { useCloverAuthorizeUrl } from 'hooks/clover';
import { useCurrentLocation } from 'hooks/location';
import { formatDate } from 'utils/date';
import ConnectNewMerchant from './ConnectNewMerchant';

const useStyles = makeStyles(() => ({
  root: {
    padding: theme.spacing(6),
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  type: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  merchants: {
    padding: theme.spacing(3),
    maxWidth: '30rem',
    width: '30rem',
  },
  locationName: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    paddingBottom: theme.spacing(3),
  },
  typesWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: { marginTop: theme.spacing(3) },
  buttonDefault: { backgroundColor: '#ffffff', textTransform: 'inherit' },
  errorMessage: {
    backgroundColor: Red[600],
    borderRadius: '4px',
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  errorText: {
    color: '#ffffff',
  },
}));

const HomeScreen = () => {
  const classes = useStyles();
  const getUrl = useCloverAuthorizeUrl();

  const { currentLocation } = useCurrentLocation();
  const {
    isInitialized,
    isSyncedMerchant,
    isSyncingMerchant,
    isSyncedInventoryItems,
    isSyncingInventoryItems,
    isSyncingModifiers,
    isSyncedModifiers,
    isSyncedOrderTypes,
    isSyncingOrderTypes,
    isSyncedPaymentKey,
    isSyncingPaymentKey,
    locationByLocationId,
    error,
    updatedAt,
  } = currentLocation?.cloverMerchantsByLocationId?.nodes[0] ?? {};
  return !currentLocation ? (
    <ConnectNewMerchant />
  ) : (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexGrow={1}
      flexDirection="column"
      className={classes.root}
    >
      <Paper variant="outlined" className={classes.merchants}>
        <Box key={locationByLocationId?.id} className={classes.item}>
          <Box className={classes.locationName}>
            <Typography variant="overline">Merchant Sync Overview</Typography>
            <Typography variant="h6">
              {isInitialized
                ? locationByLocationId?.name
                : 'New merchant syncing'}
              {` locationId: ${locationByLocationId?.id}`}
            </Typography>
            <Typography variant="caption">
              Latest sync: {formatDate(updatedAt)}
            </Typography>
          </Box>

          <Box className={classes.typesWrapper}>
            <Box className={classes.type}>
              <Typography variant="body2">Merchant</Typography>

              {isSyncingMerchant && <CircularProgress size={16} />}
              {isSyncedMerchant && !isSyncingMerchant && <IconDone />}
              {!isSyncedMerchant && !isSyncingMerchant && (
                <IconClear color="secondary" />
              )}
            </Box>
            <Box className={classes.type}>
              <Typography variant="body2">Inventory items </Typography>

              {isSyncingInventoryItems && <CircularProgress size={16} />}
              {isSyncedInventoryItems && !isSyncingInventoryItems && (
                <IconDone />
              )}
              {!isSyncedInventoryItems && !isSyncingInventoryItems && (
                <IconClear color="secondary" />
              )}
            </Box>
            <Box className={classes.type}>
              <Typography variant="body2">Items modifiers </Typography>

              {isSyncingModifiers && <CircularProgress size={16} />}
              {isSyncedModifiers && !isSyncingModifiers && <IconDone />}
              {!isSyncedModifiers && !isSyncingModifiers && (
                <IconClear color="secondary" />
              )}
            </Box>
            <Box className={classes.type}>
              <Typography variant="body2">Order types </Typography>

              {isSyncingOrderTypes && <CircularProgress size={16} />}
              {isSyncedOrderTypes && !isSyncingOrderTypes && <IconDone />}
              {!isSyncedOrderTypes && !isSyncingOrderTypes && (
                <IconClear color="secondary" />
              )}
            </Box>
            <Box className={classes.type}>
              <Typography variant="body2">Payment Key</Typography>

              {isSyncingPaymentKey && <CircularProgress size={16} />}
              {isSyncedPaymentKey && !isSyncingPaymentKey && <IconDone />}
              {!isSyncedPaymentKey && !isSyncingPaymentKey && (
                <IconClear color="secondary" />
              )}
            </Box>
          </Box>
          {error && (
            <Box className={classes.errorMessage}>
              <Typography variant="body2" className={classes.errorText}>
                {error}
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
      <Box className={classes.buttonContainer}>
        <Button
          variant="outlined"
          color="default"
          href={getUrl()}
          startIcon={<SyncIcon />}
          className={classes.buttonDefault}
        >
          Resync/Connect New Merchant
        </Button>
      </Box>
    </Box>
  );
};

export default HomeScreen;
