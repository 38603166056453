import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from 'theme';
import client from 'config/ApolloClient';
import RootRoute from 'routes/RootRoute';

function App() {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <RootRoute />
        </Router>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
