import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import {
  useRemoveLocationTableMutation,
  useRegenerateTableCodeMutation,
  LocationTableEntryFragment,
} from 'graphql/graphql-types';
import config from 'config';

const useStyles = makeStyles(() => ({
  root: {
    padding: theme.spacing(6),
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  type: {
    display: 'flex',
    flexDirection: 'row',
  },
  cardBody: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  tableCardTitle: { marginBottom: theme.spacing(2) },
  cardButtons: { width: '100%' },
  tableCard: {
    paddingTop: theme.spacing(3),
    textAlign: 'center',
  },
}));

type Props = {
  locationTable: LocationTableEntryFragment;
  locationId?: number;
};

const LocationTableItem = ({ locationId, locationTable }: Props) => {
  const classes = useStyles();

  const [removeLocationTable] = useRemoveLocationTableMutation({
    update: (cache, { data: result }) => {
      const newLocationTable = result?.removeLocationTable?.locationTable;
      if (newLocationTable) {
        cache.modify({
          fields: {
            allLocationTables: (existingFieldData, { readField }) => ({
              ...existingFieldData,
              nodes: [
                ...existingFieldData.nodes.filter(
                  (node: any) => readField('id', node) !== newLocationTable.id,
                ),
              ],
            }),
          },
        });
      }
    },
  });
  const [regenerateTableCode] = useRegenerateTableCodeMutation();

  const handleDeleteTable = useCallback(async () => {
    try {
      await removeLocationTable({ variables: { tableId: locationTable.id } });
    } catch (err) {
      if (config.isDev) {
        console.log(err);
      }
    }
  }, [locationTable.id]);

  const handleRegenerateCode = useCallback(async () => {
    try {
      await regenerateTableCode({ variables: { tableId: locationTable.id } });
    } catch (err) {
      console.log(err);
    }
  }, [locationTable.id]);

  const downloadRefCallback = useCallback(
    async (node) => {
      if (node) {
        const canvas: any = document.querySelector(
          `#locationTable-${locationTable.id}  canvas`,
        );
        if (canvas) {
          // eslint-disable-next-line no-param-reassign
          node.href = canvas.toDataURL();
          // eslint-disable-next-line no-param-reassign
          node.download = `QR-locationId-${locationId}-table-${locationTable.tableNumber}.png`;
        }
      }
    },
    [locationTable.id],
  );

  return (
    <Paper
      variant="outlined"
      id={`locationTable-${locationTable.id}`}
      className={classes.tableCard}
    >
      <Box className={classes.cardBody}>
        <Typography variant="overline" className={classes.tableCardTitle}>
          Table: {locationTable.tableNumber}
        </Typography>
        <Typography variant="subtitle1" className={classes.tableCardTitle}>
          code: {locationTable.code}
        </Typography>
        <QRCode
          className="code"
          size={100}
          value={JSON.stringify({
            locationId,
            tableNumber: locationTable.tableNumber,
            code: locationTable.code,
          })}
        />
      </Box>
      <Box>
        <ButtonGroup
          orientation="vertical"
          variant="text"
          className={classes.cardButtons}
        >
          <Button
            component={Link}
            to="/"
            target="_blank"
            innerRef={downloadRefCallback}
          >
            Download
          </Button>

          <Button onClick={handleRegenerateCode}>Regenerate Code</Button>
          <Button onClick={handleDeleteTable}>Remove</Button>
        </ButtonGroup>
      </Box>
    </Paper>
  );
};
export default LocationTableItem;
