import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from './AppBar';
import AppDrawer from './AppDrawer';
import AppContent from './AppContent';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}));

interface Props {
  children: React.ReactNode;
}

const AppWrapper = ({ children }: Props) => {
  const classes = useStyles();

  const [isOpenDrawer, setOpenDrawer] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };
  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
    <div className={classes.root}>
      <AppBar isOpenDrawer={isOpenDrawer} handleDrawerOpen={handleDrawerOpen} />
      <AppDrawer
        isOpenDrawer={isOpenDrawer}
        handleDrawerClose={handleDrawerClose}
      />
      <AppContent>{children}</AppContent>
    </div>
  );
};

export default AppWrapper;
